#create-edit-study {
  @include ulStyleStrip(0,0,none);
  width: 100%;
  max-width: 100%;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  transition: max-width 0.5s;

  &.shrink {
    max-width: 58%;
  }

  > li {
    position: relative;
    width: inherit;
    border-radius: 2px;
    border: 1px solid $mainLine;
    margin-bottom: 8px;
    box-sizing: border-box;
    cursor: pointer;
    max-height: 56px;
    box-shadow:none;
    transition: max-height 0.5s, box-shadow 0.5s;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    section {
      &.head-row {
        // position: fixed;
        width: inherit;
        height: 56px;
        display: flex;
        align-items: center;
        @include text($fs16px,'',700,$mainText);
        padding: 0 24px;
        background-color: $white;
        flex: 0 0 auto;

        &:hover {
          background-color: $sectionHover;
        }

        span {
          pointer-events: none;
          &:first-child {
            margin-right: 24px;
          }

          &:last-child {
            margin-left: auto;
          }
        }
      } // end .head-row
    } // end section

    &.active{
      box-shadow: $raisedLayer;
      border-color: $mainGreen;
      max-height: 600px;
      cursor: default;

      &.overflow-toggle {
        overflow: visible;
      }

      .head-row {
        color: $white;
        background-color: $mainGreen;

        &:hover {
          background-color: $mainGreen;
        }

        button {
          cursor: pointer;
          pointer-events:auto;
        }
      }
    } // end &.active
  } // end li

  .note-takers-list, .moderated-participants-list {
    @include ulStyleStrip(0,0,none);
    width:600px;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .form-group {
        width: auto !important;
        margin: 0 8px 0 0;
      }

      svg {
        color: $mainGreen;

        &:hover, &:active {
          color: $mainText;
          cursor: pointer;
        }
      }
    } // end li
  } // end .note-takers-list
} // end #create-edit-study

.settings-container {
  width: 100%;
  min-height: 300px;
  max-height: 800px;
  padding: 24px 24px 150px 24px;
  height: auto;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  &.questions-dropdown-open {
    overflow-y: visible;
  }

  > div {
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .dropdown-container {
    width:600px;
    .scroll-wrap {
      width:inherit;
      //position: fixed;
    }
  }


  &.s-tasks-questions {
    justify-content: center;

    p.explainer {
      max-width: 600px;
    }

    #t-q-list {
      @include ulStyleStrip(0,0,none);
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;

      li {
        @include text($fs14px,'',700,$mainText);
        border-radius: 2px;
        border: 1px solid $mainLine;
        margin-bottom: 8px;
        box-sizing: border-box;
        cursor: pointer;
        min-height: 56px;
        // overflow-y: hidden;
        // overflow-x: hidden;
        display: flex;
        align-items: center;
        padding: 0 24px;
        position: relative;

        > div:not(:first-child) { // this is to that clicks on text still trigger "selectLi()" SettingsTasksQuestions.js
          pointer-events: none;
        } // NOTEBen: The icon in the first div is not clickable, this needs to be done. The above pointer-event wasn't working

        &.selected {
          background-color: $successBg;
          border: 1px solid $successBorder;
        }

        > .form-group {
          margin: 0;

          .toggle-wrap {
            margin-bottom: 0;

            label {
              span {
                margin: 0 10px 0 0;
              }
            }
          }
        } //  end > .form-group

        &.task, &.question {
          display: flex;
          align-items: flex-start;
          padding: 24px;
          > div {
            &:first-child {
              width: 30px;
              i {
                color: $mainBlue;
                &.fa-file {color: $mainGreen;}
              }
            }

            &:nth-child(2){
              p {
                margin: 0 0 12px 0;
                font-weight: 700;
                color: $text03;

                &:first-child{
                  color: $mainText;
                }
              }
            }// end &:nth-child(2)

            &:nth-child(3){
              margin-left: 48px;
              max-width: 430px;

              p {
                margin: 0 0 12px 0;
                color: $text03;

                &:first-child {
                  color: $mainText;
                  margin-bottom: 0;
                }

                &:nth-child(2){
                  font-size: $fs12px;
                }

              }
            }// end &:nth-child(2)
          }
          aside {display: none;}
          &:hover {
            aside {
              position: absolute;
              left: -41px;
              top:-1px;
              background-color: $white;
              box-shadow: $raisedLayer;
              display: flex;
              max-width: 40px;
              flex-wrap: wrap;

              span {
                min-width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $mainLine;

                &:nth-child(2){
                  border-top: none;
                  border-bottom: none;
                }

                svg {
                  color: $text03;
                  pointer-events: none;
                }

                &:hover {
                  svg {
                    color: $mainGreen;
                  }
                }
              }
            } // end aside
          } // end &:hover


        } // end &.task, &.question
      } // end li
    } // end #t-q-list

    aside#add-new {
      width:100%;
      max-width: 600px;
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;

      span {
        margin-left:32px;
        box-sizing: border-box;
        border-bottom: 1px solid $white;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 30px;

        &:first-child {
          color: $mainGreen;
            &:hover, &:focus {
              background-color: $mainGreen;
              color: $white;
            }
        }

        &:nth-child(2) {
          color: $mainBlue;
          position: relative;
          &:hover, &:focus {
            background-color: $mainBlue;
            color: $white;
          }

          ul.question-types {
            @include ulStyleStrip(0,0,none);
            position: absolute;
            width: 528px;
            display: flex;
            flex-wrap: wrap;
            box-shadow: $overlayLayer;
            box-sizing: border-box;
            background-color: $white;
            bottom: 38px;
            right:0;
            z-index: 1;

            li {
              width: 50%;
              padding: 8px 0px 8px 16px;
              color: $mainText;
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                width: 90%;
                margin: 0;
                pointer-events: none;
              }

              svg, img {
                margin-right: 8px;
                pointer-events: none;
              }

              &:hover {
                background-color: $dropdownSelectBd;
                cursor: pointer;
              }
            }
          } // end ul.question-types
        }

        svg {
          margin-right: 5px;
        }
      }
    } // end aside#add-new

  } // end .s-tasks-questions

  .format-settings {
    width:100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    .ck-editor {
      width: 100%;margin-top: 10px;
    }
    .ck-editor__editable {
        min-height: 150px;
        max-height: 350px;

        h1 {  @include text($fs20px, '32px', 700, $mainText);}
        h2 {  @include text($fs18px, '28px', 700, $mainText);}
        h3 {  @include text($fs16px, '25px', 700, $mainText);}
        h4 {  @include text($fs14px, '22px', 700, $mainText);}
    }

    .form-group, .ip-ranges {
      width: 600px !important;
    }

    #ip-range-label {
      margin-bottom:4px;
    }
    .ip-ranges {
      flex-wrap: wrap;

      ul {
        @include ulStyleStrip(0,0,none);
        width: 600px;
        li {
          display: flex;
          align-items: center;

          .form-group {
            width: 170px !important;
            margin: 0;
          }

          svg {
            color: $mainGreen;

            &:hover, &:active {
              color: $mainText;
              cursor: pointer;
            }
          }
        }
      } // end ul

      button {
        margin-top:8px;
      }
    } // end .ip-ranges

    .full-width {
      width: 100%;
      margin: 20px 0  0 0;
    }

    pre {
      font-size: $fs12px;
      width: 600px;
    }
  } // end .format-settings

  &.study-launch {
    #launch-msg, #pop-up-js-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 640px;
      background-color: $modaleContentBg;
      border: 1px solid $mainLine;
      padding: 24px 120px;
      margin-bottom: 24px;

      &.expand-padding {
        padding: 10px 20px;
      }
      img { margin: 24px 0 0 0;}
      h4 {
        margin-bottom: 0;
        font-weight: 700;
      }
    } // end #launch-msg, #pop-up-js-msg
    #launch-button {
      width:600px;
      margin: 0 auto;
      justify-content: center;
      flex-wrap: wrap;
      padding: 20px 140px;

      img {
        margin-bottom: 16px;
      }
    }
  }// end .study-launch
} //  end .settings-container

.image-buttons {
  > div {


    &.selected-1 {
      > span {
        &:nth-child(2){
          border-left: none;
        }
      }
    } // end &.selected-1

    &.selected-2 {
      > span {
        &:nth-child(1){
          border-right: none;
        }
      }
    } // end &.selected-2

    &.selected-3 {
      > span {
        &:nth-child(1){
          border-right: none;
        }
      }
    } // end &.selected-2

    span.selected-img-bt {
      background-color: $successBg;
      border: 1px solid $successBorder !important;
    }


    > span {
      width: 300px;
      min-height: 160px;
      padding:32px 0 24px 0;
      border: 1px solid $mainLine;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      cursor: pointer;

        &:hover {
          background-color: $successBg;
        }

        &:nth-child(2),&:nth-child(3) {
          border-left: none;
        }

        span { // second span is used to normalise svg heights
          height: 62px;
        }

        p {
          width: 100%;
          text-align: center;
          margin: 0px;

          &:nth-child(3){
            color:$text03;
            margin: 16px 16px 0 16px;
          }
        }

        p, img, span {
          pointer-events: none;
        }
      } // end > span

      &.sml {
        > span {
          width:200px;
          min-height: 140px;
        }
      }

      &.med {
        > span {
          width:233px;
          min-height: 140px;
        }
      }

      &.img-file-upload {
        width:100%;
        max-width:418px;
        border: dashed 1px $mainLine;
        background-color: $white;
        display: flex;
        justify-content: center;

        span {
          border: none;
          background: none;
        }

        button {
          margin-top: 10px;
        }
      }
  } // end > div
} // end &#image-buttons

#sidebar-editor {
  height: auto;
  min-height: 0vh;
  width: 40%;
  max-width: 0%;
  transition: max-width 0.5s, min-height 0.5s;
  border: 1px solid $mainLine;
  position: relative;

  &.open {
    max-width: 40%;
    min-height: 100vh;
  }

  .sidebar-head {
    height: 56px;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $mainLine;
    padding: 0 24px;
    @include text($fs20px, '', 400, $mainText);

    #sidebar-title {
      width: 62%;
    }

    .head-icons {
      svg {
        font-size: $fs18px;
        &:hover {
          color: $mainGreen;
          cursor: pointer;
        }
      }
    }

    #sidebar-close {
      &:hover {
        color: $mainGreen;
        cursor: pointer;
      }
    }
  }

  .sidebar-body {
    padding: 24px 24px 70px 24px;
    overflow-y: auto;
    height: calc(100vh - 58px);

    .inpt-lrg {
      width: 100%;
    }

    #question-type-string {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 4px;

      span {
        margin-left: 8px;
      }
    }

    .ck-editor {
      width: 100%;margin-top: 10px;
    }
    .ck-editor__editable {
        min-height: 200px;
        max-height: 400px;

        h1 {  @include text($fs20px, '32px', 700, $mainText);}
        h2 {  @include text($fs18px, '28px', 700, $mainText);}
        h3 {  @include text($fs16px, '25px', 700, $mainText);}
        h4 {  @include text($fs14px, '22px', 700, $mainText);}
    }

    .question-field-container {
      width:100%;

      button {
        margin: 8px 0;
      }
    }

    .answer-lines {
      @include ulStyleStrip(0,0,none);

      li {
        display: flex;

        .inpt-lrg {
          width: 340px;
        }

        .form-group {
          margin: 0;
        }
      } // end li

      &.draggable {
        li{
          .inpt-lrg {
            width: 315px;
          }
        }
      }

      .drag-handle {
        display: flex;
        justify-content: space-around;
        width: 20px;
        margin-right: 8px;
        cursor: grabbing;

        svg {
          font-size: $fs40px;
          color: $mainLine !important;

        }
      }

      &:not(.inherit-styles){
        li {
          align-items: center;

          &:first-child {
            margin-top: -12px;
          }
        }
        svg {
          color: $mainGreen;

          &:hover, &:active {
            color: $mainText;
            cursor: pointer;
          }
        }
      }

      &.inherit-styles {
        li {
          &:last-child {
            input {
              margin: -10px 0 24px 0;
            }
          }
        }
      }

    }
  } // end .sidebar-body

  #sidebar-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;
    padding: 16px;
    border-top: 1px solid $grey50;
    z-index: 16;
  }
} // end #sidebar-editor

#preview-carousel {
  width: 100%;
  height: 400px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .carousel-nav {
    position: absolute;
    height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    cursor: pointer;

    svg {
      margin: 0 4px;
      color: $mainText50;

      &:hover, &.VISIBLE {
        color: $mainGreen;
      }
    }

  }
  section.first {

    width: 80%;

    .preview-modal {
      min-height: 340px !important;
      max-height: 340px !important;
      box-shadow: $overlayLayer;
      padding-top: 0;

      .logo-exit-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        min-height: 40px;

        span {
          font-size: $fs12px;
          padding: 0 16px;
        }

        img {
          padding: 0 16px;
          margin: 8px 0;
        }
      } // end .logo-exit-container

      h2 {
        font-size: $fs14px;
        font-weight: 700;
      }

      .preview-warning {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin: 0 20%;
        height: 30px;
        bottom: 66px;
        border-radius: 20px;
        font-size: $fs12px;

        span {
          &:first-child {
            color: $white;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 4px;
          }
          &:nth-child(2) {
            padding-right: 20px;
          }
        }
      }

      .preview-buttons {
        span {
          height: 36px;
        }
      }
    } // end .preview-modal

  } // section.first end

  section.second {

    .show-bubble {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: $overlayLayer;
    }
  } // end section.second


} // end #preview-carousel

.preview-modal {

  padding: 24px 0;
  min-height: 400px;
  position: relative;

  H1, h2, h3, h4, h5, p, ul, ol{
    margin: 0 24px;
  }

  .question-container, .task-container {
    margin-bottom: 20px;

    H1, h2, h3, h4, h5, p, ul, ol{
      margin-top: 8px;
      margin-bottom: 8px;
    }

    H1, h2, h3, h4, h5{
      line-height: 1.5 !important;
      padding: 0 !important;
      max-width: 352px !important;
    }
  }

  .preview-lists {
    @include ulStyleStrip(12px 24px 0 24px,0,none);
    max-height: 400px;
    overflow: scroll;
    padding-bottom: 55px;

    li {
      line-height: $fs30px;
      svg { margin-right: 10px;}

      p {
        margin: 0 0 5px 0;
      }
    }

    .matrix-list {
      @include ulStyleStrip(0,0,none);
      display: flex;
      justify-content: space-between;


      li {
        max-width: 20%;
        text-align: center;
        line-height: 1.3rem;
        margin: 5px 0 15px 0;

        svg { margin-right: 0px;}
      }
    } //end .matrix-list

    select {
      width: 100%;
      height: 30px;
      padding: 5px 8px;
      border-radius: 0;
      background: none;
      font-size: 0.875rem;
      box-sizing: border-box;
      margin-bottom: 15px;
    }

  } // end .preview-lists

  .preview-input-text, .preview-textarea {
    margin: 12px 24px;
    width:352px;
    font-size: 0.875rem;
    border: 1px solid $mainLine;
    padding: 3px 5px;
  }

  .grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    margin-bottom: 20px;
    text-align: right;

    &:first-child div{
      padding-right: 14px;
    }

    &:not(:first-child) div {
      text-align: left;
    }

    div {
      line-height: 1rem;
    }

  }

  .preview-buttons {
    width: 100%;
    display: flex;
    justify-content:space-between;
    padding: 0 24px;
    position: absolute;
    bottom: 24px;
    background-color: $white;

    span {
      width: 49%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fs14px;
      background-color: rgb(226, 226, 226);
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    }
  } // emd .preview-buttons


} // .preview-modal

.criteria-list {
  @include ulStyleStrip(0,0,none);
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > li {
    border: 1px solid $mainGreen;
    width:100%;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;

    .accordion-control{
      width: 100%;
      max-height: 0px;
      transition: max-height 0.5s, box-shadow 0.5s;
      overflow-y: hidden;
      overflow-x: hidden;

      &.remove-overflow {
        overflow: visible;
      }
    }

    .type-container {
      width: 100%;
      background-color: $successBg;
      cursor: pointer;

      > .form-group {
        width: 568px !important;
        margin: 16px 16px 16px 15px;

        .inpt-xlrg {
          width:568px;
        }

        .scroll-wrap {
          width: 568px;
        }
      }

    } // end .type-container

    &.active {
      .accordion-control{
        min-height: 100px;
        max-height: 1000px;
      }
      .type-container {
        border-bottom: 1px solid $mainGreen;
        cursor: auto;
      }
    } // End &.active

    .options-container {
      width: 100%;
      padding: 24px 16px 16px 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .cb-wrap {
        margin-right: 24px;
        margin-bottom: 0px;
      }

      .range-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .form-group {
          max-width: 100px;
          margin-top: -8px;
          margin-bottom: 0;

          &:nth-child(2) {
            margin-left: 20px;
          }

          .inpt-xsml {
            width: 80px;
          }
        } // end .form-group
      } // end .range-wrapper


      .currency-option {
        .form-group:first-child{
          width: 180px !important;
          margin-top: -8px;

          .scroll-wrap {
            width: 180px;
          }
        }

        .range-wrapper {

          .form-group {
            max-width: 140px;

            &:first-child {
              margin-right: 20px;
            }

            &:nth-child(2) {
              margin-left: 20px;
            }
          } // end .form-group
        } // end .range-wrapper
      }// end .currency-option

      .select-rows {
        @include ulStyleStrip(0,0,none);
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        li {
          width:100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          > .form-group {
            width: 520px !important;
            margin: 0px 16px 16px 0px;

            .inpt-xlrg {
              width:520px;
            }

            .scroll-wrap {
              width: 520px;
            }
          }

          svg {
            cursor: pointer;
            color: $mainGreen;

            &:hover {
              color: $mainText;
            }
          }

          .sub-option-container {
            width:100%;
            display: flex;
            justify-content: flex-end;
            min-height: 61px;

            > ul {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              max-width: 480px;
              @include ulStyleStrip(0,0,none);

              .form-group {
                width: 400px !important;
                margin: 0px 16px 16px 0px;

                .inpt-lrg {
                  width:400px;
                }

                .scroll-wrap {
                  width: 400px;
                }
              } // end .form-group

              svg {
                margin: 0 2px;
              }
            } // end > section
          } // end .device-os-container
        } // end li
      } // end .select-rows

      .warning-msg {
        width:600px;
        font-size: $fs12px;
        color: $negativeBorder;
      }
    } // end .options-container
  }

  .criteria-controls {
    width: 100%;
    padding: 16px;
    border-top: 1px solid $mainLine;
    display: flex;
    justify-content: flex-start;

    .delete-bt {
      margin-left: auto;
      color: $negativeBorder;
    }
  }
} // end .criteria-list

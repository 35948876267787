/* Colors Start */
.color-squares {
  margin-left: 0;
  padding: 0;
  list-style: none;
  float: left;
  width:100%;
  max-width: 800px;
}

.color-squares li {
  width: 137px;
  overflow: hidden;
  margin: 0 15px 25px 0;
  float: left;
}

.color-squares li div {
  width: 137px;
  height: 137px;
}

.color-squares pre {
  margin:0;
}

.color-squares li span {
  display: block;
  font-size: 1em;
}

.color-squares li > span {
    margin-left: 7px;
}
/* Colors End */

/* Layer Component Styles Start  */

.layer-types {
  margin:0 0 30px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  width: 700px;
  height: 320px;
}

.layer-types li {
  flex: 1 150px;
  height: 150px;
  max-width: 150px;
  background-color: $white;
  margin: 0 25px 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flat {
  z-index: 1;
}

.floating {
  z-index: 4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.raised {
  z-index: 8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.overlay {
  z-index: 16;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}

.sticky-nav {
  z-index: 16;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pop-out {
  z-index: 24;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
}
/* Layer Component Styles End  */

/* Grid Component Styles Start  */

.grid-tables {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width:700px;
}

.grid-tables li {
  display: flex;
}

.grid-tables li span {
  margin:auto 0;
}
/* Grid Component Styles End  */

#modal-container {
  display: none;
  position: fixed;
  width:100%;
  height: 100%;
  background-color: $modalContainerBg;
  top: 0;
  left: 0;
  opacity:0;
  transition: opacity 0.5s;

  &.show-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
  }

  #modal-body {
    width: 400px;
    height: auto;
    min-height: 359px;
    background-color: $modaleContentBg;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    opacity:1;
    transition: opacity 0.5s;

    &.flat-display {
      min-height: 239px;

      #modal-controls {
        // this instance is used to override below declaration
        background-color: $bodyBg;
        border-top: none;
        box-shadow: none;
      }
    }

    &.extra-width {
      width: 650px;
    }

    h1 {
      font-size: 1.25rem;
      line-height: 67px;
      padding: 0 24px;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color:$white;
      width: 100%;

      svg {
        margin-left: auto;
        cursor: pointer;
        font-size: 1.25rem;

        &:hover {
          color: $mainGreen;
        }

        path {
          pointer-events: none;
        }
      }
    }

    #modal-content {
      width: 100%;
      height: auto;
      background-color: $white;

      &.flat-display {
        padding: 16px 24px;
        background-color: $bodyBg;
      }

      .form-group {
        margin: 0;

      }
    }

    #modal-controls {
      width: 100%;
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 24px;
      background-color: $white;
      border-top: 1px solid $mainLine;
      box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1);

      .form-group {
        width: auto;
        align-items: center;
        margin: 0;
        padding: 24px 0;
      }

      #left-links {
        flex-grow: 2;
      }
    }
  }
}

// Tag Manage  Start
.tag-manage-container {
  position: relative;
  min-height: 40px;
  width: 100%;
  border-top: 1px solid $mainLine;
  border-bottom: 1px solid $mainLine;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 24px;
  box-sizing: border-box;

  #tag-search-container{
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 5px 0;

    .selected-tag{
      margin: 4px 4px 0px 0;
      border-radius: 21px;
      width: auto;
      line-height: 1rem;
      @include text(0.875rem, 1.5rem, normal, $subTagText);
      border: 1px solid $subTagBg;
      padding: 2px 5px 2px 10px;
      background-color: $subTagBg !important;
      color: $mainText;
      border-color: $subTagBg;

      svg {
        margin-left:5px;
        color: $tagClose;
        font-size: 0.75rem;
        margin-bottom: -0.5px;

        &:hover {
          color: $mainText;
          cursor: pointer;
        }
      }
    }
  }

  svg {
    margin: auto 5px auto 0;
    color: $greyActive;
  }

  input[type='text']{

    height: 1.5rem;
    border-radius: 2px;
    border: none !important;
    box-sizing: border-box;
    padding: 17px 16px;
    font-family: Lato;
    font-size: 14px;
    line-height: 40px;
    margin:3px 0 0 0;
    min-width:100px;
    width: auto;

    &:active, &:focus {
      border: none;
      box-shadow: none;
    }
  }

}

.tag-scroll-wrap {
  width: 100%;
  max-height: 246px;
  padding: 0;
  animation: fadeIn .2s ease-out 0s 1 forwards;
  z-index: 16;

  .tag-list-container {
    overflow: auto;
    max-height: inherit;

    ul {
      @include ulStyleStrip(0,0,none);

      li {
        padding: 13px 0 13px 26px;

        &#create-new-tag {
          background-color: $dropdownSelectBd;
          cursor: pointer;
          display: none;

          svg {
            margin-right: 8px;
          }
        }

        &:hover {
          background-color: $dropdownSelectBd;
          cursor: pointer;
        }

        &.hide-option {
          display: none;
        }
      }
    }
  }
}
// Tag Manage  End

.sidebar-nav{
  background-color: $sidebarNavbg;
  width: 200px;
  min-height: 100vh;
  height: auto;

  ul {
    @include ulStyleStrip(0,0,none);

    li {
      a {
        @include text($fs14px, 50px, normal, $color: $sidebarText);
        text-decoration: none;
        display: block;
        box-sizing: border-box;
        padding: 0 0 0 24px;
        font-weight: bold;

        span {
          width: 32px;
          margin-right: 8px;
          display: inline-block;
          text-align: center;
        }

        svg {
          margin: 0 0 -2px 0;
          color: $sidebarIcon;
          font-size: $fs18px;

          &:last-child {
            display: none;
          }
        }
      }

      a:hover, a:focus,  a.active {
        border-left: 4px solid $mainGreen;
        background-color: $sidebarNavbgHover;
        padding-left: 20px;
        color: $white;

        svg {
          color: $secondaryGreen;

          &:first-child {
            display: none;
          }

          &:last-child {
            display: inline-block;
          }
        }
      }

      &.line-break {
        border-top:1px solid $sidebarNavbgHover;
        margin: 8px auto;
        width: 80%;
      }

    }

  }
}

.top-nav {
  min-width:calc(100vw - 200px);
  height: 60px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.1);

  .align-right {
    min-width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .search-field {
    width:300px;
    border-radius: 18px;
    border: 1px solid $mainLine;
    margin-left: 45px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &:hover, &:focus, &:active {
      border-color: $mainGreen;
    }
    svg {
      margin-left: 16px;
    }

    label {
      display: none;
    }

    input[type='text']{
      border:none;
      background: none;
      margin: 0;

      &.inpt-med {
        width:270px;
      }

      &:hover, &:focus, &:active {
        box-shadow: none !important;
      }
    }

    .scroll-wrap {
      position: absolute;
      width: calc(100%);
      max-height: $listHeight;
      padding: 0;
      background-color: #fff;
      box-shadow: 0 5px 16px 0 rgba(0,0,0,0.2);
      animation: fadeIn .2s ease-out 0s 1 forwards;
      z-index: 16;
      top:34px;

      .list-container {
        overflow: auto;
        max-height: $listHeight;

        ul {
          @include ulStyleStrip(0,0,none);

          li {
            padding: 13px 0 13px 16px;

            span:first-child {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc(100% - 16px);
              display: block;
            }

            span:last-child {
              font-size: $fs12px;
              color: $text03;
              text-transform: capitalize;
            }

            &:hover {
              background-color: $dropdownSelectBd;
              cursor: pointer;

              span:last-child {
                color: $text04;
              }
            }
          }
        }
      }
    }
  }

  .account-container {
    height: 32px;
    border-left: 1px solid $mainLine;
    margin-left: 16px;
    padding-left: 10px;
    display: flex;
    align-items: stretch;
    position: relative;

    > span {
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 16px;

      &:nth-child(2){
        margin-left:8px;
      }

      &:hover {
        cursor: pointer;
        background-color: $iconBtBg;
      }

      span {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $notificationBg;
        @include text($fs9px, '', bold, $color: $white);
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid $white;
      }
    }

    .account-wrapper {
      width: 250px;
      min-height: 300px;
      height: auto;
      background-color: $white;
      position: absolute;
      top: 38px;
      right: 0;
      box-shadow: 0 0 16px 0 rgba(0,0,0,0.2);
      animation: fadeIn .2s ease-out 0s 1 forwards;
      z-index: 16;

      &:after {
      	bottom: 100%;
      	right: 5px;
      	border: solid transparent;
      	content: " ";
      	height: 0;
      	width: 0;
      	position: absolute;
      	pointer-events: none;
      	border-bottom-color: #ffffff;
      	border-width: 10px;
      	margin-left: -10px;
      }

      ul {
        @include ulStyleStrip(0,0,none);

        li {

        }

        li:first-child {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $mainLine;
          padding: 16px;

          .user-icon {

          }

          div {
            display: flex;
            flex-direction: column;
            width: 170px;

            span:first-child, span:nth-child(2n){
              width: 170px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            span:nth-child(2n){
              @include text($fs12px, '', normal, $color: $text03);
            }

            span:last-child{
              @include text($fs12px, '', normal, $color: $mainGreen);
            }
          }
        }

        li:not(:first-child){

          a {
            @include text($fs14px, 40px, normal, $color: $mainText);
            width: 100%;
            display: block;
            padding: 0 16px;

            &:hover {
              background-color: $dropdownSelectBd;
            }
          }

          svg {
            margin-right: 8px;
          }
        }

        .account-section {
          a {
            padding-left: 40px !important;
          }
        }

        .stand-alone-section {
          border-top: 1px solid $mainLine;
        }

      }
    }
  }

  .notification-wrapper {
    width: 250px;
    min-height: 300px;
    height: auto;
    background-color: $white;
    position: absolute;
    top: 38px;
    right: 42px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.2);
    animation: fadeIn .2s ease-out 0s 1 forwards;
    z-index: 16;

    &:after {
      bottom: 100%;
      right: 5px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: -10px;
    }

    > div {
      max-height: 300px;
      overflow: auto;
    }

    ul {
      @include ulStyleStrip(0,0,none);

      li{
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 16px;

        a {
          @include text($fs14px, '', normal, $color: $mainText);
          width: 100%;
          display: block;
          padding: 0px 16px 0 16px;

          &:hover {
            color: $linkTxt;
          }
        }

        span {
          @include text($fs12px, '', normal, $color: $text03);
          width: 100%;
          padding: 0 16px 16px 16px;

          svg {
            margin: 0 5px 2px 0;
            color: $linkTxt;
          }
        }
      }

      li:not(:first-child) {
        border-top: 1px solid $mainLine;
      }

    }
  }
}

.secondary-nav {
  display: flex;

  .grey-txt {
    color: $mainText;
    margin-right: 40px;
    padding: 8px 0 16px 0;
    background: none !important;

    &:last-child {
      margin-right: 0;
    }
  }

  .txt-btn:hover {
    color: $text03;
  }

  .txt-btn:focus, .txt-btn:active, .active {
    border-bottom: 2px solid $mainText;
  }
}

.context-head {
  width:100%;

  > div {
    padding:
    0 47px;
  }
  .title-row {

    display: flex;
    justify-content: space-between;

    h3 {
      display: flex;
      align-items: center;
      max-width: 50%;
      margin: 0;

      svg {
        margin-right: 8px;
      }

      span {
        width:100%;
        display: flex;
        align-items: center;

        svg {
          &:last-child{
            font-size: $fs14px;
            color: $iconGrey;
            margin-left: 8px;

            &:hover {
              cursor: pointer;
              color: $mainGreen;
            }
          }
        } // end of SVG

        .form-group {
          margin: 0;
          max-width: 350px;

          label {
            display: none;
          }

          input {
            margin: 0;
          }
        }
      }// End of span
    } // end of H3

    ul {
      @include ulStyleStrip(0,0,none);
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
      max-width: 50%;

      li {
        margin-left: 4px;

        &:last-child {
          color: $spinnerGrey;
          svg {
            animation: spin 2s linear infinite;
          }
        }
      }
    }
  } // end of .title-row

  .meta-detail-row {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    margin-top: $spacing-xs;

    > :first-child{
      width: 60% !important;
    }

    > :last-child{
      width: 40% !important;
      display: flex;
      justify-content: flex-end;

      li {
        &:last-child{
          margin-right: 2px;
        }
      }
    }
  } // end .meta-detail-row

  .report-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: $spacing-lg;

    > ul {
      @include ulStyleStrip(0,0,none);
      @include text($fs20px, '', normal, $color: $mainText);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      li {

        button {
          margin-left: 8px;
        }
        > span {
          width: 46px;
          height: 46px;
          display: flex !important;
          justify-content: center;
          align-items: center;

          &.show {
            box-shadow: $overlayLayer;
            background-color: $white !important;
          }

          &:hover {
            background-color: $mainButton;
            cursor: pointer;
          }

          svg {
            pointer-events: none;
          }
        }
      }
    } // end UL

    main {
      position: relative;
      width: 100%;
      z-index: 1;
      section {
        position: absolute;
        top:-4px;
        width: 100%;
        min-height: 500px;
        height: auto;
        background-color: $white;
        border-radius: 2px;
        box-shadow: $overlayLayer;

        > div {
          padding: 24px;

          &:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $mainLine;
            > span {
              width: 18%;
              display: flex;
              justify-content: space-between;

              svg {
                &:hover {
                  cursor: pointer;
                  color: $mainGreen;
                }
              }
            } // end span
            h4 {
              margin: 0;
            }

            .form-group {
              margin: 0;

              .toggle-wrap {
                margin: 0;
              }
            }
          } // &:first-child

          &:not(:first-child) {
            h5 {
              margin-bottom: 0;
            }
            > ul {
              @include ulStyleStrip(0,0,none);
              display: flex;
              justify-content: flex-start;
              align-items: center;

              &:not(:nth-last-child(2)) {
                margin-bottom: -20px;
              }

              > li {
                margin-right: 16px;

                &:nth-child(3) {
                  .form-group {
                    margin-bottom: 8px;
                  }
                }

                &:last-child {
                  //padding-top: 13px;

                  svg {
                    color: $mainGreen;

                    &:hover {
                      cursor: pointer;
                      color: $mainText;
                    }
                  }
                } // end &:last-child
              }
            }
          } // end &:not(:first-child)

          &:nth-child(3) {
            padding-top: 0;
          }

          &:nth-child(4) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 0;

            button{
              margin-top: 22px;
              margin-bottom: 22px;

              &.outline {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &:focus, &:active {
                  background-color: $mainGreen !important;
                }
              }
            }

            .form-group {
              width: auto;
              margin-top: 12px;
              margin-right: -2px;

              input[type="text"]{
                line-height: 47px;
                border-color: $mainGreen;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }

              &.error {
                .error-val{
                  position: absolute;
                  right: 528px;
                  width: 350px;
                  text-align: right;
                  line-height: 4em;
                  padding-top: 5px;
                }
              }
            }
          } // &:nth-child(4) {
        }// end > div
      } // end section
    } // end main
  } // end .report-nav
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Descriptive colour variables
$mainGreen      : #00a9a7;
$mainGreen50    : #65C7AB;
$mainGreenHover : #148f8d;
$secondaryGreen : #2FC8AD;
$mainGreenClick : #0d6959;
$mainBlue       : #146aff;
$secondaryBlue  : #3e4e67;
$graph02        : #ff9a9a;
$graph03        : #e6f3f0;
$mainText       : #333;
$mainText50     : rgba(51, 51, 51,0.5);
$white          : #FFF;
$white50        : rgba(255, 255, 255,0.5);
$black          : #000;
$linkTxt        : #4274fd;
$sidebarNavbg   : #4d5c74;
$sidebarNavbgHover : #3e4e67;
$sidebarIcon    : #d6d6d6;
$sidebarText    : #d6d6d6;
$notificationBg : #f0242c;
$iconBtBg       : #f3f5f5;


// Alternate Buttons
$greyHover      : #9b9b9b;
$greyActive     : #666666;
$greyDisabled   : #f1f1f1;
$blackDisabled  : #999999;
$outlineBgActive: #CCCCCC;
$grey50         : #e6e6e6;
$grey50Txt      : #7f7f7f;

//
$bodyBg         : #f7f7f7;
$mainButton     : #e2e2e2;

$text01         : #222222;
$text03         : #9b9b9b;
$text04         : #777777;

$mainLine       : #dddddd;
$mainLine50     : rgba(221,221,221,0.5);


// Support color variables
$negativeBorder : #f63d39;
$negativeBg     : #fff1f3;

$successBorder  : #00a9a7;
$successBg      : #f5fffd;

$infoBorder     : #146aff;
$infoBg         : #eaf1ff;

$warningBorder  : #f5a623;
$warningBg      : #fffbe9;

$mutedBorder    : #9b9b9b;
$mutedBg        : #e2e2e2;

// icons
$iconGrey       : #e2e2e2;
$playBtBg       : #f3f5f5;
$iconBlue       : #146aff;
$iconYellow     : #ffde66;
$spinnerGrey    : #9e9e9e;

// Font stacks
$stack-sans-serif   : 'Lato', sans-serif;
$fs9px          : 0.563em;
$fs10px         : 0.65rem;
$fs12px         : 0.75rem;
$fs14px         : 0.875rem;
$fs16px         : 1rem;
$fs18px         : 1.125rem;
$fs20px         : 1.25rem;
$fs24px         : 1.5rem;
$fs30px         : 1.85rem;
$fs40px         : 2.5rem;

// Spacing
$spacing-2xs  :	0.25rem;
$spacing-xs   :	0.5rem;
$spacing-sm   :	1rem;
$spacing-lg   :	1.5rem;
$spacing-xl   :	2rem;
$spacing-2xl  :	2.5rem;
$spacing-3xl  :	3rem;

$layout-2xs   :	1rem;
$layout-xs    :	1.5rem;
$layout-sm    :	2rem;
$layout-lg    :	2.5rem;
$layout-xl    :	3rem;
$layout-2xl   :	3.5rem;
$layout-3xl   :	5rem;

$br : 10px;

// forms and Fields
$listHeight       : 235px;
$dropdownSelectBd : #f3f5f5;
$chevron          : #ababab;
$placeholder      : #ababab;
$arrow            : #333;
$checkRadioDimensions : 20px;
$elipWidthSml     : 125px;
$elipWidthLrg     : 215px;
$elipMarginSmlCntr : -44px;
$elipMarginLrgCntr : -42px;

$tooltip-color: rgba(255,255,255,1);
$tooltip-text-color: $mainText;

// Tags
$tagBgLight     : #f0f0f0;
$tagBgDark      : #e2e2e2;
$tagBorder      : #8d8d8d;
$subTagText     : #9b9b9b;
$subTagBg       : #f0f0f0;

$tagClose       : #8d8d8d;

// Modal
$modalContainerBg    : rgba(0, 0, 0,0.5);
$modaleContentBg      : #f7f7f7;

// Table
$tableThBg      : #f3f5f5;

// Accordion
$accordionBg    : rgba(247, 247, 247,0.2);

// Asset paths
$path-img   : '../img';
$path-font  : '../fonts';

// Media query break points
$break-narrow : '(max-width: 768px)';
$break-wide   : '(max-width: 1024px)';

// Clickstream
$pathbg        : #f2fffd;
$failR         : #ff9a9a;
$abandonR      : #59a2de;

// Layers
$floatingLayer     : 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$raisedLayer       : 0 2px 8px 0 rgba(0, 0, 0, 0.2);
$overlayLayer      : 0 4px 16px 0 rgba(0, 0, 0, 0.2);
$stickyLayer       : 0 8px 16px 0 rgba(0, 0, 0, 0.2);
$popOutLayer     : 0 12px 24px 0 rgba(0, 0, 0, 0.2);

// study create/edit
$sectionHover : #e6e6e6;

.tableContainer {
  width: 100%;
  height: auto;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white;
  padding: 24px 0;

  &.half-width {
    width: 49.5%;
    padding: 24px;
    margin-bottom: 1%;
  }

  h5 {
    margin-top: 0;
    margin-bottom: 24px;

    button {
      margin: 0 0 0 8px;
    }
  }

  table {

    margin-bottom: 0;

    @include text($fs14px, normal, normal);

    th {
      color:$mainText;
      padding-bottom: 16px;
      line-height: unset;
    }

    td {
      padding: 16px 0;
    }

    &.minAvgMax, &.twoCol, &.batch {
      width: 100%;

      td span.tooltip {
        white-space: nowrap;
      }

      // div.tooltip-bubble {
      //   word-break: break-all;
      //   white-space: normal;
      // }
    }

    &.minAvgMax {

      th {
        width:15%;
      }

      th:first-child {
        width: 40%;
      }

      td:first-child {
        font-weight: 700;
      }
    }

    &.twoCol {
      margin-top: -8px;
      border-top: 1px solid $mainLine;

      th {
        width:50%;
      }

      td:last-child {
        text-align: right;
      }

    }

    &.batch {

      th:first-child, td:first-child {
        width: 68%;
      }

      th:nth-child(2), td:nth-child(2){
        width: 22%
      }

      th:nth-child(2), > th:not(:first-child), td:not(:first-child) {
        text-align: right;
      }

      th:last-child {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 0;

        > div {
          margin: -7px 0 0 0;

          .dropdown-container {
            margin-bottom: 0;
            height: auto;

            .scroll-wrap {
              width: 220px;
            }
          }
        }
      }

      td:last-child {
        > span {
            margin: 6px 0 0 0;

            label {
              display: none;
            }
        }
      }
    }
  }

  .simple-answers {

    margin-top:-24px !important;
    margin-bottom:-24px !important;
    border-top: none !important;
    td{
      padding: 16px 24px;
      &:first-child {
        width: 25%;

        span {
          color: $text03;
          font-size: $fs12px;
        }
      }
    }
  }// end .simple-answers

  &.base-margin {
    margin-bottom: 24px;
  }
} // end .tableContainer

.matrix-container {
  width: 100%;
}
.matrix-wrapper {
    position: relative;

    .matrix-scroller {
        margin-left: 250px;
        overflow-x: scroll;
        overflow-y: visible;
        padding-bottom: 5px;
        width: 300px;
    }

    .matrix-table {
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        margin-bottom: 0;
        font-size: $fs14px;
        width: inherit;
        min-width: inherit;

        thead th {
            border: none;
            padding: 12px;
            text-align: left;
            background-color: $tableThBg;
            color: $mainText;
            line-height: 1.3rem;
            min-height: 67px;
            border-top: 1px solid $mainLine;
            border-bottom: 1px solid $mainLine;

            &:first-child {
              min-height: auto;
            }
        }
        tbody td {
            border-bottom: solid 1px $mainLine;
            background-color: $white;
            padding: 12px;
            min-height: 67px;
            min-width: 94px;

            &.sticky-col {
              background-color: transparent;
              display: flex;
              align-items: center;
            }

            &:last-child {
              text-align: left;
            }
        }

        .sticky-col {
            left: 0;
            position: absolute;
            top: auto;
            width: 254px;
            font-weight: 700;
        }
    }

}

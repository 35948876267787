.report-summary {
  width: 100%;
  // width: calc(100% - 47px);
  padding-bottom: 24px;
  border-top: 1px solid $mainLine;
  border-bottom: 1px solid $mainLine;
  margin: 24px 0;

  &.QUESTION {
    border-bottom:none;
    padding-bottom: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 24px;

      section {
        min-width: 50%;
        max-width: 50%;

        h4 {
          display: flex;
          line-height: inherit;

          .form-group {
            width: 110px;
            margin-top: -10px;
            margin-left: 10px;
          }

          .tooltip {
            height: 30px;
            margin-top: -2px !important;
          }
        } // end of h4

        p {
          font-size: $fs16px;
        }

        button {
          margin-bottom: 24px;
          margin-left: 24px;
        }

        &:nth-child(2){
          .form-group {
            float: right;
          }
        }
      } // end of section

      &.PARTICIPANT { // only for the participant summary
        h4 {
          margin-bottom: 0;
          max-height: 30px;
        }

        p {
          @include text($fs12px, normal, normal, $color: $text03);
          margin-top: 0;
        }
      }// end of .PARTICIPANT

      &.statistics-container, &:not(.PARTICIPANT) section:last-child {

        &.TASK, &.QUESTION {
          border-top: 1px solid $mainLine;
          padding-top: 24px;
        }

        &:not(.DASHBOARD) {

          ul {
            &:last-child{
              min-width: 30%;
              max-width: 400px;
              justify-content: flex-end;
            }
          }
        }
        ul {
          @include ulStyleStrip(0,0,none);
          min-width: 70%;
          display: flex;
          justify-content: flex-start;

          &:last-child{
            // min-width: 30%;
            // max-width: 400px;
            // justify-content: flex-end;
          }

          li {
            font-size: $fs14px;
            min-width: 77px;
            margin-right: 10px;

            &:last-child{
              margin-right: 0;
            }

            &:nth-last-child(-n+3){
              // margin-right: 0;
            }

            span {
              font-size: $fs24px;
              padding-top: 16px;
              display: block;
            }
          } // end li

          &:first-child {
            li {
              &:first-child {
                span {color: $mainGreen;}
              }

              &:nth-child(2) {
                span {color: $failR;}
              }
            }
          }
          // end first child
        } // end ul
      } // end statistics-container

      &.statistics-container{
        &.PARTICIPANT {

          border-top: 1px solid $mainLine;
          padding-top: 24px;
          margin-top: 8px;

          ul {
            max-width:100%;
            width: 100%;
            display: flex;
            justify-content: space-between;

            li {
              min-width: 150px;

              &:nth-child(4) {
                margin-left: 200px;
              }

              &:last-child{
                margin-left: 20px;
              }

              &:nth-last-child(-n+2){
                margin-right: 0;

                span {
                  font-size: $fs18px;
                }
              }
            }
          }
        }
      }

      &:not(.PARTICIPANT) section:last-child {
        display: flex;
        justify-content: flex-end;

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 18px;

          li {
            &:first-child {
              span {color: $mainText;}
            }

            &:nth-child(2) {
              span {color: $mainGreen;}
            }

            &:nth-child(3) {
              span {color: $text03;}
            }

            &:last-child {
              width: 370px;
              background-color: $mutedBg;
              height: 8px;
              border-radius: 4px;
              margin: 24px 0;

              aside {
                background-color: $mainGreen;
                display: block;
                height: 100%;
                border-radius: 4px;
              } // end asside
            } // end last child
          } // end li
        } // end ul
      } // end &:not(.PARTICIPANT) section:last-child

      &.PARTICIPANT {
        section:last-child{
          display: flex;
          width: 100%;
          max-width: 100%;
          margin-top: 10px;

          ul {
            @include ulStyleStrip(0,0,none);
            width:30%;
            margin-right: 6%;

            li {
              display: flex;
              margin-bottom: 16px;

              span {
                &:first-child {
                  width: 150px;

                  svg {
                    margin-right: 5px;
                  }
                }
                &:last-child {
                  color:$text03;
                  width: 170px;
                }
              }
            } // end li

            &:last-child {
              width: 28%;
              margin-right: 0;

              li {
                a {
                  margin-left: 10px;
                }
              }
            }// end last-child
          }// end ul
        }// end section
      }
  }

}

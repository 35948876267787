
.btn-tabs {
  display: flex;
}

button {
  padding: 16px 32px;
  background-color: $mainGreen;
  color: $white;
  border-radius: 2px;
  border: none;
  font-size: 0.875rem;
  font-weight: regular;
  margin: 2px;
  box-sizing: border-box;

  &:hover,&:focus {
    background-color: $mainGreenHover;
    cursor: pointer;
  }

  &:active {
    background-color: $mainGreenClick;
  }

  &:disabled {
    background-color: $mainGreen50;
  }

  svg {
    margin-right: 5px;
  }
}


.tab-info-container {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-info-container li {
  display: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.tab-info-container li.show {
  opacity: 1;
}



/* Button Variations */
.grey-btn {
  background-color:$tagBgDark;
  color: $mainText;

  &:active {
    background-color: $greyActive;
    color: $white;
  }

  &:hover,&:focus {
    background-color: $greyHover;
    color: $white;
  }

  &:active {
    background-color: $greyActive;
    color: $white;
  }

  &:disabled {
    background-color: $greyDisabled;
    color: $white;
  }
}

.blue-btn {
  background-color:$sidebarNavbg;
  color: $white;

  &:active {
    background-color: $sidebarNavbgHover;
  }

  &:hover,&:focus {
    background-color: $sidebarNavbgHover;
  }

  &:active {
    background-color: $mainText;
  }

  &:disabled {
    background-color: $sidebarNavbg;
    opacity:0.5;
  }
}

.slim {
  padding: 8px 16px !important;
}

.tiny {
  padding: 4px 8px;
  font-size: 0.75rem;
}


.rnd-cnr {
  border-radius: 32px;
}

.txt-btn {
  background: none;
  font-weight: bold;
  color: $mainGreen;
  padding: 8px;

  &:hover {
    background: $mainGreen;
    color: $white;
  }

  &:focus, &:active {
    background: $mainGreenClick;
    color: $white;
  }

  &:disabled {
    background: $mainGreen50;
  }

}

.btn-tabs .txt-btn:hover, .btn-tabs .txt-btn:focus, .btn-tabs .txt-btn:active, .btn-tabs .txt-btn.active {
  border-bottom: 2px solid $mainGreen;
  background: none;
  color: $mainGreen;
}

.outline {
  background: none;
  border: 1px solid $mainGreen;
  color: $mainGreen;
  font-weight: bold;
  padding: 15px 32px;

    &:hover {
      background-color: $mainGreen;
      color: $white;

      svg {
        color: $white !important;
      }
    }

    &:focus, &:active {
      background-color: $mainGreenClick;
      color: $white;
    }

    &:disabled {
      border-color: $mainGreen50;
      color: $mainGreen50;
      background-color: $successBg;
    }

  &.grey-btn {
    border-color: $text03;
    color: $text03;

    &:hover {
      background: $mainButton;
      border-color:$mainButton;
    }

    &:focus, &:active {
      background: $outlineBgActive;
      border-color:$outlineBgActive;
    }

    &:disabled {
      border-color: $text03;
      color: $text03;
    }
  } // end &.grey-btn

  &.white-btn {
    border-color: $white;
    color: $white;

    &:hover, &:focus, &:active {
      background: $white;
      border-color:$white;
      color: $mainGreen;
    }

    &:disabled {
      border-color: $text03;
      color: $text03;
    }
  } // end &.white-btn
} // end .outline

.grey-txt {
  color: $mainText;

  &:hover {
    background: $mainButton;
    color: $mainText;
  }

  &:focus, &:active {
    background: $greyActive;
    color: $mainText;
  }

  &:disabled {
    background: $greyDisabled;
  }
}

.fw-btn {
  width:100%;
}

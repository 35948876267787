/* Base Styles -------------------- */

* {
  box-sizing: border-box;
  outline: none;
}

html, body, #root {
  @include text(100%, 1.5, $color: $mainText);
  margin: 0;
  padding: 0;
  font-family: $stack-sans-serif;
  height:100%;
  background-color: $bodyBg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.disable-scroll {
  overflow: hidden;
}

h1 {
 @include text($fs30px, normal, normal);
 font-stretch: normal;
 margin-top: 0;
}

h2 {
  @include text($fs30px, normal, normal);
  font-stretch: normal;
  letter-spacing: normal;
}

h3 {
  @include text($fs24px, normal, normal);
  font-stretch: normal;
  letter-spacing: normal;
}

h4 {
  @include text($fs20px, normal, normal);
  font-stretch: normal;
  letter-spacing: normal;
}

h5 {
  @include text($fs16px, normal, bold);
  font-stretch: normal;
  letter-spacing: normal;
}

.mainGreen {
  color: $mainGreen;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 700 !important;
}

/* Font Sizes Start */
.x-large {
  @include text(2.5rem, normal, normal);
}

.large {
  @include text(1.85rem, normal, normal);
}

.medium {
  @include text(1.5rem, normal, normal);
}

.heading {
  @include text(1.25rem, normal, normal);
}

.small {
  @include text(1rem, normal, normal);
}

p, .default-txt, ul, ol {
  @include text(0.875rem, 1.5, normal);
  max-width: 700px;
}

p code {
  font-size: 1.1em;
  padding: 0 5px;
  color: $mainGreenClick;
}

.caption {
  @include text(0.8125rem, normal, normal);
}

.caption-sub {
  @include text(0.75rem, normal, normal);
}

/* Font Sizes End */

/* Pseudo-classes ------------------ */

a {
  &:link {
    color: $mainBlue;
    text-decoration: none;
  }

  &:visited {
    //color: $color-link-visited;
  }

  &:hover,&:focus {
    text-decoration: underline;
  }
}


.ds-app {
  width:100%;
  min-height: 100%;
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
}

.l11-app {
  width:100%;
  min-height: 100%;
  position: relative;
  display: flex;
  height: auto;
  overflow: hidden;

  #container {
    max-width: calc(100% - 200px);
  }
}

.main-nav {
  flex: 1 200px;
  min-height: 100%;
  background-color: $white;
  border-right: 1px solid $mainLine;
  overflow:auto;
}

#id-main-content{
  overflow: auto;
}

.Loop11-logo {
  border: none !important;
}
.Loop11-logo img {
  width: 83px;
  height: 37px;
  object-fit: contain;
  margin: 20px 0 18px 54px;
}

.main-nav ul {
  @include ulStyleStrip(0,0,none);
}

.main-nav ul li {
  padding: 0 0 0 0px;
}

.nav-category {
  padding: 0 0 0 24px;
  font-weight: bold;
}

.nav-child {
  padding: 0 0 0 40px;
}

ul ul ul .nav-child {
  padding-left: 60px;
}

.main-nav a {
  @include text(0.875rem, 34px, normal, $color: $mainText);
  text-decoration: none;
  display: block;
  box-sizing: border-box;
}

.main-nav a:hover, .main-nav a:focus, .main-nav a.active {
  border-left: 4px solid $mainGreen;
}

.nav-child:hover, .nav-child:focus, .nav-child.active {
  color: $mainGreen;
}

.container {
  padding: 50px 0px 0 0;
  min-width:calc(100% - 200px);
  flex:1;
  box-sizing: border-box;;
}

.container > div {
  padding: 30px 0 50px 50px;
  display: flex;
  flex-direction: column;
}

.white-bg {
  background-color: $white;
}

.component-loc {
  font-size:1em;
  width: 200px;
  overflow: hidden;
  margin: -15px 0 0 0;
}

.row {
  float: left;
}

.row a {
  color: $linkTxt;
}

.wrap {
  flex-wrap: wrap;
}

table {
  text-align: left;
  width: 600px;
  border-collapse: collapse;
  margin-bottom: 40px;
}

table tr {
  border-bottom: 1px solid $mainLine;
}

table tr.bd-top {
  border-top: 1px solid $mainLine;
}

table th {
  color: $mainGreen;
  line-height: 2.5em;
}

table tr h3 {
  letter-spacing: 3px;
}
table td {
  padding: 10px 0;

  &.v-top {
    vertical-align: top;
  }
}

.add-hover {
  cursor: pointer;
}

.code-block {
  width:750px;
  overflow: hidden;
}

.example-block {
  width: 600px;
  min-height: 150px;
  background-color: $bodyBg;
  border: 1px solid $mainLine;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.example-block.align-left {
  justify-content: flex-start;
  align-items: flex-start;
}

.content-block {
  width: auto;
  min-height: 150px;
  background-color: $bodyBg;
  // border: 1px solid $mainLine;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin:0 47px;
}

.evenly-space {
  display: flex;
  justify-content: flex-start;

    &.successUrlWrapper {
      margin-top: -18px;
      flex-wrap: wrap;

      .form-group {
        margin: 4px 0 -10px 0;
      }

      button {
        margin-top: 16px;
      }
    }

    > span {
      margin-right: 24px;
    }

    .form-group {
      width: auto;
    }
}

.loading-msg {
  padding: 24px 0 0 0;
  color: $mainText50;
}

button {
  text-transform: capitalize;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.user-icon {
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  color: $white;
  @include text($fs12px, '', bold, $color: $white);

  &.med {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  &.lrg {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    @include text($fs18px, '', bold, $color: $white);
  }
}

.stacked-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  height: 24px;
  width: 40px;

  li {
    display: flex;
    position: absolute;

    &.icon-menu {
      border: 1px solid $mainLine;
      background-color: $white;
      color: $mainText;
      cursor: pointer;

      &:hover {
        border-color: $mainGreen;
      }
    }
  }

  .user-icon-wrapper {
    width: 171px;
    min-height: 46px;
    height: auto;
    background-color: $white;
    position: absolute;
    top: 36px;
    right: 0;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.2);
    animation: fadeIn .2s ease-out 0s 1 forwards;
    z-index: 16;

    &:after {
      bottom: 100%;
      right: 5px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: -10px;
    }

    ul {
      @include ulStyleStrip(0,0,none);

      li {
        position: relative;
        line-height: 46px;
        align-items: center;
        padding-left: 16px;

        span {
          margin-right: 8px;
        }
      }
    }
  }
}

/* Question & Task Icons */
svg{
  &.MULTICHOICE1, &.MULTICHOICEMULTI, &.RANKING, &.RATINGSCALE1, &.SUS, &.SYSTEMUSABILITYSCORE, &.NPS, &.NETPROMOTERSCORE {color: $iconBlue;}
}

#loadingContainer {
  text-align: center;
  justify-content: center;
  min-height: 100vh;

  h1 {
    color: $mainLine
  }

  h3 {
    font-style: italic;
  }
}

.full-width {
  width: 100% !important;
}

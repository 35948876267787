/* Overflow UI Start */
.overflow-span {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: $arrow;

  &:hover, &.open {
    background-color: $mainButton;
    color: $mainGreen !important;
  }

  .ellipsis {
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    font-size: 1rem;
  }

  .e24 {
    font-size: 1.5rem;
  }

}

.overflow-span + label {
  font-size: 0.75rem !important;
  font-weight: normal !important;
  width: 100%;
  display: block;
  text-align: center;
  /* The margin bottom ensure the dropdown is flush against the button*/
  margin-bottom: -18px;
}

.large .overflow-span{
  width: 40px;
  height: 40px;
  color: $iconGrey;
}
.large .overflow-span svg {
  font-size: 1.5rem;
}

.overflow-span ~ .scroll-wrap {
  width:$elipWidthSml;

  .list-container ul li {
    padding-left:16px;

    &:hover, &.open {
      color: $mainGreen;
    }
  }
}

.scroll-wrap.extraWidth {
  width:$elipWidthLrg;
}

.dropdown-container .center-dropdown {
  left:$elipMarginSmlCntr;
  margin-top: 10px;
}

.large .center-dropdown {
  left:$elipMarginLrgCntr;
  margin-top: 12px;
}

.center-dropdown:after {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: #ffffff;
	border-width: 10px;
	margin-left: -10px;
}



.scroll-wrap.right-dropdown {
  right:0;
}
/* Overflow UI End */

/* Tooltip Start */
.tooltip {
  position: relative;
  margin: 0 !important;

  &.lower {
    margin-top: 5px !important;
  }

  span {
    margin: 0 !important;
  }
}

.tooltip-trigger {
  display: inline-block;
  border-bottom: 1px dashed $mainText50;
  cursor: pointer;

  svg {
    font-size: 0.875rem;
  }
}

.hide-underline {
  border-bottom:none;
}

.tooltip-trigger > svg:hover {
  color: $mainGreen;
}

.tooltip-bubble {
  position: absolute;
  z-index: 16;
  &::after {
    content: '';
    position: absolute;
  }
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $tooltip-color;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid $tooltip-color;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);

  &::after {
    border-left: 9px solid $tooltip-color;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);

  &::after {
    border-right: 9px solid $tooltip-color;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltip-message {
  background: $tooltip-color;
  border-radius: 3px;
  color: $tooltip-text-color;
  font-size: .75rem;
  line-height: 1.4;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  word-break: break-word;

  a {
    text-decoration: none;

    &:hover,:active {
      text-decoration: underline;
      color: $mainGreen;
    }

    svg {
      margin-left: 2px;
    }
  }

  span {
    text-align: left !important;
  }

  table {
    width:100%;
    margin: 0;
  }

  table tbody tr {
    border-bottom: none;
  }

  table td:last-child {
    text-align: right;
  }
}

.tt-xs {width: 70px; padding: .5em;}
.tt-sml {width: 122px;}
.tt-med {width: 180px;}
.tt-lrg {width: 250px;}
.tt-xl {width: 350px;}

.tooltip-icon {
  margin: 0 4px;
}
/* Tooltip End */

/* Tag Start */
.tag-list-container {
  width:100%;
}

.tag-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: unset;
}

.study-url {
  @include text($fs12px, '', normal, $subTagText);
}

.tag-item {
  margin: 0 4px 4px 0;
  padding: 4px 10px;
  background-color: $mainButton;
  border-radius: 21px;
  transition: width 1s;
  width: auto;
  line-height: initial;
  text-transform: capitalize;

  &:not(.sub-level):hover,
  &.sub-level.custom-tag:hover {
    cursor: pointer;
    background-color: $greyHover;
    color: $white;
  }

  &.add-tag {
    background-color: $mainGreen;
    color: $white;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background-color: $mainGreenHover;
    }
  }

  &.sub-level {
    @include text($fs12px, 1, bold, $subTagText);
    border: 1px solid $subTagBg;
    padding: 2px 10px;

    &.status-tag {
      background-color: $subTagBg;
    }

    &.custom-tag {
      background: none !important;
      border: 1px solid $tagBorder;
      font-weight: normal;;

      &:hover {
        background-color: $subTagBg !important;
        color: $mainText;
        border-color: $subTagBg;
      }
    }

  }

  &.sub-level.status-tag[data-type="LAUNCHED"]{
    background-color: $mainGreen;
    color: $white;

    &:hover {
      cursor: default;
    }
  }

  &.active-tag {
    background-color: $mainGreen;
    color: $white;

    &:hover {
      background-color: $mainGreenHover;
    }
  }
}

tr.tag-list-container {

  td {
    padding: 6px 0 2px 0;

    ul.tag-list {
      margin-left: 24px;
    }
  }

}


.tag-item .tooltip {
  margin-left: 5px !important;
  display: inline-block;
  opacity: 0.5;

  .tooltip-trigger {
    border-bottom: none;
  }
}

.tag-item .tooltip {

  svg {
    font-size: $fs10px;
    margin-bottom: -0.5px;

    &:hover {
      color: inherit;
    }
  }

}
.tag-item:hover .tooltip {
  opacity: 1;

}
/* Tag End */

/* Icons Start */
.icon-grey {
  color:$iconGrey;
}

.list-icons {
  font-size: $fs12px;

  a {
    display: flex;
    width: 70px;
    flex-wrap: wrap;
    justify-content: center;
    color: $mainText;

    &:hover .icon-grey  {
      color:$mainGreen;
    }

    svg {
      font-size:$fs24px;
    }

    span {
      margin-top: 8px;
      min-width: 100%;
      text-align: center;
      color: $mainText !important;
    }
  }

}
/* Icons End */

/* Accordion Start  */
.accordion-wrapper {
  width:100%;
  margin:20px 0 -20px 0;

  &.hide {
    display: none;
  }
}

.acc-trigger {
  text-align: center;
  border-top: 1px solid $mainLine;

    svg {
      pointer-events:none;
      transition: transform .6s ease-in-out;
    }

    &:hover {
      cursor: pointer;
      background-color: $accordionBg;
    }

    &.open svg {
      transform: rotate(180deg);
    }
}
.accordion {
  display:block;
  overflow:hidden;
  transition:max-height 1s ease-in-out;
  height:auto;
  max-height:0;
  background-color: $accordionBg;
  box-shadow:
          inset 0px 11px 10px -10px rgba(0, 0, 0, 0.2),
          inset 0px -10px 10px -10px rgba(0, 0, 0, 0.2);
}

.open {
  max-height:600px;
}
/* Accordion End  */

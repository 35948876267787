form.ds-form {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  align-items: center;
  justify-content: space-around;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

input[type='text'], input[type=email], textarea {
  z-index: 10;
  color: $mainText;

  &:disabled {
    background-color: $grey50;
    &::placeholder {color: $mainText50;}
  }
}

input[type='text']::placeholder,
input[type=email]::placeholder,
input[type=number]::placeholder,
textarea::placeholder
{
  color: $placeholder;
}

// input and select widths
.inpt-xsml {
  width: 110px;
}

.inpt-smler {
  width: 155px;
}

.inpt-sml {
  width: 180px;

    &.extend {
      width: 218px;
      margin-left: 4px !important;
    }
}

.inpt-med {
  width: 250px;
}

.inpt-lrg {
  width: 350px;
}

.inpt-xlrg {
  width: 600px;
}

.code-block {
  overflow: unset;
  .inpt-xlrg {
    width: 550px;
    font-family: monospace;
    font-size: 1rem !important;
  }

  button {
    max-height: 42px;
    margin-top:8px;
    padding: 0 0 0 4px;
    width: 40px;
  }
}

.inpt-step {
  width: 75px;
  text-align: center;
}

// Checkbox styles start
.cb-wrap {
  display: inline-flex;
  margin: 0 0 16px 0;
  cursor: pointer;
  height: 21px;
}

.cb-wrap label {
  margin: -2px 0 0 6px;
  pointer-events: none;
  font-size: $fs14px;
}

.cb-wrap input[type="checkbox"] {
  display: none;
}
.cb-wrap input[type="checkbox"] + span {
  display: flex;
  position: relative;
  top: -1px;
  width: $checkRadioDimensions;
  height: $checkRadioDimensions;
  min-width: $checkRadioDimensions;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: $white left top no-repeat;
  border: 1px solid $mainLine;
  cursor: pointer;
  color: $white;
  justify-content: center;
  align-content: center;
}
.cb-wrap input[type="checkbox"]:checked + span {
  background: $mainGreen -19px top no-repeat;
}

.cb-wrap input[type="checkbox"] + span {
  margin-right: 4px;
}

.cb-wrap input[type="checkbox"] + span svg {
  margin: auto;
}
// Checkbox styles end

// Radio styles Start
.cb-wrap input[type="radio"] {
  display: none;
}

.cb-wrap input[type="radio"] + span {
  display: flex;
  position: relative;
  top: -1px;
  width: $checkRadioDimensions;
  height: $checkRadioDimensions;
  min-width: $checkRadioDimensions !important;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: $white left top no-repeat;
  border: 1px solid $mainLine;
  cursor: pointer;
  color: $white;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
}
.cb-wrap input[type="radio"]:checked + span {
  border: ($checkRadioDimensions / 4) solid $mainGreen ;
}

.cb-wrap input[type="radio"] + span {
  margin-right: 4px;
}

.cb-wrap input[type="radio"] + span svg {
  margin: auto;
}
// Radio styles end

// Dropdown styles Start
.dropdown-container {
  position: relative;
  height: 40px;
  margin-bottom: 5px;

  input {

    height: 42px;
    border-radius: 2px;
    border: solid 1px $mainLine;
    box-sizing: border-box;
    padding: 14px 28px 14px 16px !important;
    margin-bottom:0 !important;
    font-family: Lato;
    font-size: $fs14px;
    line-height: 40px;
    cursor: pointer;
  }

  .slim {
    height: 22px;
    padding: 4px 8px;
  }

  .slim + svg {
    top:unset;
    bottom: 8px;
  }

  .invisible {
    border: 1px solid rgba(0,0,0,0) !important;
    background-color: inherit;

    &:hover {
      background-color: white;
      border: solid 1px $mainLine !important;
    }
  }

  input:active, input:focus {
    border: solid 1px $mainGreen;
  }

  .arrow-down {
    position: absolute;
    top: 23px;
    right: 12px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: none;
    color: $arrow;
  }

  .arrow-up {
    transform: rotate(180deg);
  }

  .scroll-wrap {
    position: absolute;
    width: calc(100%);
    max-height: $listHeight;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 5px 16px 0 rgba(0,0,0,0.2);
    animation: fadeIn .2s ease-out 0s 1 forwards;
    z-index: 16;

    .list-container {
      overflow: auto;
      max-height: $listHeight;

      ul {
        @include ulStyleStrip(0,0,none);

        .create-button {
          background-color:$dropdownSelectBd;
        }
        .opt-group {
          padding: 12px 0 0px 15px;
          font-weight: bold;

          &:hover {
            background-color: $white;
            cursor: default;
          }
        }

        li {
          padding: 13px 13px 13px 26px;

          &:hover {
            background-color: $dropdownSelectBd;
            cursor: pointer;
          }
        }
      }
    }
  }
}
// Dropdown styles End

// Toggle styles Start
.toggle-wrap {
  display: inline-flex;
  margin: 0 0 16px 0;
}

.toggle-wrap p {
  margin: -1px 0 0 8px;
  pointer-events: none;
  font-weight: 700;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: $greyHover;
  -webkit-transition: .3s;
  transition: .3s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s;
}

input:checked + .slider {
  background-color: $mainGreen;
}

input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

.toggled-on p {
  color: $mainGreen;
}
// Toggle styles End

// Text Input start

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  width:100%;

  &.underline {
    border-bottom: 1px solid $mainLine;
    padding-bottom: 8px;
  }

  &.no-wrap {
    flex-wrap: nowrap;
    margin-bottom: 5px;

    .form-group {
      margin: 0;

      .toggle-wrap {
        margin-bottom: 0;
        p {
          font-size: 0.75rem;
          line-height: 1.5rem;
        }
      }
    }
  }

  label:not(.switch) {
    width:100%;
    font-size: $fs14px;
    font-weight: 700;
  }

  label span {
    font-weight: normal;
    font-size: $fs12px;
    margin: 0 5px;
  }

  input[type=text], input[type=email], input[type=number] {
    line-height: 40px;
    padding: 0 16px;
    margin: 8px 0 4px 0;
    font-size: $fs14px;
    border: $mainLine 1px solid;
    border-radius: 2px;
  }

  textarea {
    padding: 16px;
    margin: 8px 0 4px 0;
    font-size: $fs14px;
    border: $mainLine 1px solid;
    border-radius: 2px;
  }

  input[type=text]:active, input[type=email]:active, input[type=number]:active, textarea:active {
    border-color: $mainGreen;
  }

  input[type=text]:focus, input[type=email]:focus, input[type=number]:focus, textarea:focus {
    box-shadow: 0 0 10px 0 $mainGreen50;
  }

  .error-val {
    width:100%;
    line-height: 1;
    margin: 4px 0 0 0;
    font-size: $fs12px;
    color: $negativeBorder;
    display: none;
  }

}

.fg-auto-width {
  width: auto;
}

.error input[type=text], .error input[type=email], .error input[type=number], .error textarea{
  border-color: $negativeBorder;
}

.error .error-val {
  display: block;
}

.react-datepicker-popper {
    z-index: 11 !important;
}
// Text Input end
.color-picker-rows {
  @include ulStyleStrip(0,0,none);
  width:100%;
  margin-bottom: 50px;

  li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $mainLine;
    padding: 16px 0;
    position: relative;

    &:first-child {
      padding-top: 0;
    }
  }
}
.color-picker-container {
  display: flex;
  justify-content:space-around;
  align-items: center;
  width: 40px;
  height: 22px;
  border-radius: 2px;
  border: solid 1px $mainLine;
  background-color: $white;
  color: $mainText;

  svg:last-child {
    align-self:flex-start;
  }
} // end .color-picker-container

.sketch-picker {
  position: absolute;
  bottom:0;
  right: 0;
  z-index: 1000;
} // end .sketch-picker

@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-abacus:before {
  content: "\f640"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acorn:before {
  content: "\f6ae"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alicorn:before {
  content: "\f6b0"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-analytics:before {
  content: "\f643"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angel:before {
  content: "\f779"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-crate:before {
  content: "\f6b1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-atom-alt:before {
  content: "\f5d3"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-axe:before {
  content: "\f6b2"; }

.fa-axe-battle:before {
  content: "\f6b3"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backpack:before {
  content: "\f5d4"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-badge-dollar:before {
  content: "\f645"; }

.fa-badge-percent:before {
  content: "\f646"; }

.fa-badger-honey:before {
  content: "\f6b4"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ball-pile:before {
  content: "\f77e"; }

.fa-ballot:before {
  content: "\f732"; }

.fa-ballot-check:before {
  content: "\f733"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bat:before {
  content: "\f6b5"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-school:before {
  content: "\f5d5"; }

.fa-bell-school-slash:before {
  content: "\f5d6"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bells:before {
  content: "\f77f"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bone-break:before {
  content: "\f5d8"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-alt:before {
  content: "\f5d9"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-book-spells:before {
  content: "\f6b8"; }

.fa-book-user:before {
  content: "\f7e7"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-books:before {
  content: "\f5db"; }

.fa-books-medical:before {
  content: "\f7e8"; }

.fa-boot:before {
  content: "\f782"; }

.fa-booth-curtain:before {
  content: "\f734"; }

.fa-bow-arrow:before {
  content: "\f6b9"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-ballot:before {
  content: "\f735"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-brackets:before {
  content: "\f7e9"; }

.fa-brackets-curly:before {
  content: "\f7ea"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-loaf:before {
  content: "\f7eb"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-bullseye-arrow:before {
  content: "\f648"; }

.fa-bullseye-pointer:before {
  content: "\f649"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-burrito:before {
  content: "\f7ed"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-bus-school:before {
  content: "\f5dd"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-cabinet-filing:before {
  content: "\f64b"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calculator-alt:before {
  content: "\f64c"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-star:before {
  content: "\f736"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campfire:before {
  content: "\f6ba"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candle-holder:before {
  content: "\f6bc"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-candy-corn:before {
  content: "\f6bd"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-bump:before {
  content: "\f5e0"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-garage:before {
  content: "\f5e2"; }

.fa-car-mechanic:before {
  content: "\f5e3"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-car-tilt:before {
  content: "\f5e5"; }

.fa-car-wash:before {
  content: "\f5e6"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cauldron:before {
  content: "\f6bf"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chair-office:before {
  content: "\f6c1"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-line-down:before {
  content: "\f64d"; }

.fa-chart-network:before {
  content: "\f78a"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-chart-pie-alt:before {
  content: "\f64e"; }

.fa-chart-scatter:before {
  content: "\f7ee"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-cheese-swiss:before {
  content: "\f7f0"; }

.fa-cheeseburger:before {
  content: "\f7f1"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chimney:before {
  content: "\f78b"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-claw-marks:before {
  content: "\f6c2"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clipboard-list-check:before {
  content: "\f737"; }

.fa-clipboard-prescription:before {
  content: "\f5e8"; }

.fa-clipboard-user:before {
  content: "\f7f3"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-drizzle:before {
  content: "\f738"; }

.fa-cloud-hail:before {
  content: "\f739"; }

.fa-cloud-hail-mixed:before {
  content: "\f73a"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-rainbow:before {
  content: "\f73e"; }

.fa-cloud-showers:before {
  content: "\f73f"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sleet:before {
  content: "\f741"; }

.fa-cloud-snow:before {
  content: "\f742"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-clouds:before {
  content: "\f744"; }

.fa-clouds-moon:before {
  content: "\f745"; }

.fa-clouds-sun:before {
  content: "\f746"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-coffee-togo:before {
  content: "\f6c5"; }

.fa-coffin:before {
  content: "\f6c6"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dollar:before {
  content: "\f650"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-medical:before {
  content: "\f7f4"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-comments-alt-dollar:before {
  content: "\f652"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compass-slash:before {
  content: "\f5e9"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-corn:before {
  content: "\f6c7"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cow:before {
  content: "\f6c8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-croissant:before {
  content: "\f7f6"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-crutches:before {
  content: "\f7f8"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dagger:before {
  content: "\f6cb"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-debug:before {
  content: "\f7f9"; }

.fa-deer:before {
  content: "\f78e"; }

.fa-deer-rudolph:before {
  content: "\f78f"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dewpoint:before {
  content: "\f748"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d10:before {
  content: "\f6cd"; }

.fa-dice-d12:before {
  content: "\f6ce"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d4:before {
  content: "\f6d0"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-d8:before {
  content: "\f6d2"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-diploma:before {
  content: "\f5ea"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-disease:before {
  content: "\f7fa"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-do-not-enter:before {
  content: "\f5ec"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dog-leashed:before {
  content: "\f6d4"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-circle:before {
  content: "\f5ed"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-draw-square:before {
  content: "\f5ef"; }

.fa-dreidel:before {
  content: "\f792"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick:before {
  content: "\f6d6"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-duck:before {
  content: "\f6d8"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-ear:before {
  content: "\f5f0"; }

.fa-ear-muffs:before {
  content: "\f795"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-eclipse:before {
  content: "\f749"; }

.fa-eclipse-alt:before {
  content: "\f74a"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-egg-fried:before {
  content: "\f7fc"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-elephant:before {
  content: "\f6da"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-empty-set:before {
  content: "\f656"; }

.fa-engine-warning:before {
  content: "\f5f2"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-dollar:before {
  content: "\f657"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-evil:before {
  content: "\f6db"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-certificate:before {
  content: "\f5f3"; }

.fa-file-chart-line:before {
  content: "\f659"; }

.fa-file-chart-pie:before {
  content: "\f65a"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-spreadsheet:before {
  content: "\f65b"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-user:before {
  content: "\f65c"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-files-medical:before {
  content: "\f7fd"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-fire-smoke:before {
  content: "\f74b"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-fireplace:before {
  content: "\f79a"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fish-cooked:before {
  content: "\f7fe"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-alt:before {
  content: "\f74c"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flame:before {
  content: "\f6df"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flask-poison:before {
  content: "\f6e0"; }

.fa-flask-potion:before {
  content: "\f6e1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flower:before {
  content: "\f7ff"; }

.fa-flower-daffodil:before {
  content: "\f800"; }

.fa-flower-tulip:before {
  content: "\f801"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-fog:before {
  content: "\f74e"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-folder-times:before {
  content: "\f65f"; }

.fa-folder-tree:before {
  content: "\f802"; }

.fa-folders:before {
  content: "\f660"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-french-fries:before {
  content: "\f803"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frosty-head:before {
  content: "\f79b"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-function:before {
  content: "\f661"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gas-pump-slash:before {
  content: "\f5f4"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gift-card:before {
  content: "\f663"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-gingerbread-man:before {
  content: "\f79d"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass:before {
  content: "\f804"; }

.fa-glass-champagne:before {
  content: "\f79e"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glass-whiskey-rocks:before {
  content: "\f7a1"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glasses-alt:before {
  content: "\f5f5"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-globe-snow:before {
  content: "\f7a3"; }

.fa-globe-stand:before {
  content: "\f5f6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hammer-war:before {
  content: "\f6e4"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-magic:before {
  content: "\f6e5"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-santa:before {
  content: "\f7a7"; }

.fa-hat-winter:before {
  content: "\f7a8"; }

.fa-hat-witch:before {
  content: "\f6e7"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-head-side:before {
  content: "\f6e9"; }

.fa-head-side-brain:before {
  content: "\f808"; }

.fa-head-side-medical:before {
  content: "\f809"; }

.fa-head-vr:before {
  content: "\f6ea"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-rate:before {
  content: "\f5f8"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-helmet-battle:before {
  content: "\f6eb"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-mask:before {
  content: "\f6ee"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-alt:before {
  content: "\f80a"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-home-lg:before {
  content: "\f80b"; }

.fa-home-lg-alt:before {
  content: "\f80c"; }

.fa-hood-cloak:before {
  content: "\f6ef"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hospital-user:before {
  content: "\f80d"; }

.fa-hospitals:before {
  content: "\f80e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-house-flood:before {
  content: "\f74f"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-humidity:before {
  content: "\f750"; }

.fa-hurricane:before {
  content: "\f751"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-ice-skate:before {
  content: "\f7ac"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-inhaler:before {
  content: "\f5f9"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-integral:before {
  content: "\f667"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-intersection:before {
  content: "\f668"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-island-tropical:before {
  content: "\f811"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-key:before {
  content: "\f084"; }

.fa-key-skeleton:before {
  content: "\f6f3"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-keynote:before {
  content: "\f66c"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kidneys:before {
  content: "\f5fb"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kite:before {
  content: "\f6f4"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-knife-kitchen:before {
  content: "\f6f5"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lambda:before {
  content: "\f66e"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-landmark-alt:before {
  content: "\f752"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-layer-minus:before {
  content: "\f5fe"; }

.fa-layer-plus:before {
  content: "\f5ff"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leaf-maple:before {
  content: "\f6f6"; }

.fa-leaf-oak:before {
  content: "\f6f7"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-lightbulb-dollar:before {
  content: "\f670"; }

.fa-lightbulb-exclamation:before {
  content: "\f671"; }

.fa-lightbulb-on:before {
  content: "\f672"; }

.fa-lightbulb-slash:before {
  content: "\f673"; }

.fa-lights-holiday:before {
  content: "\f7b2"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lips:before {
  content: "\f600"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location:before {
  content: "\f601"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-location-circle:before {
  content: "\f602"; }

.fa-location-slash:before {
  content: "\f603"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lungs:before {
  content: "\f604"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-mace:before {
  content: "\f6f8"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailbox:before {
  content: "\f813"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-mandolin:before {
  content: "\f6f9"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-marker-alt-slash:before {
  content: "\f605"; }

.fa-map-marker-check:before {
  content: "\f606"; }

.fa-map-marker-edit:before {
  content: "\f607"; }

.fa-map-marker-exclamation:before {
  content: "\f608"; }

.fa-map-marker-minus:before {
  content: "\f609"; }

.fa-map-marker-plus:before {
  content: "\f60a"; }

.fa-map-marker-question:before {
  content: "\f60b"; }

.fa-map-marker-slash:before {
  content: "\f60c"; }

.fa-map-marker-smile:before {
  content: "\f60d"; }

.fa-map-marker-times:before {
  content: "\f60e"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-meat:before {
  content: "\f814"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaphone:before {
  content: "\f675"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mind-share:before {
  content: "\f677"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mistletoe:before {
  content: "\f7b4"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monitor-heart-rate:before {
  content: "\f611"; }

.fa-monkey:before {
  content: "\f6fb"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-moon-cloud:before {
  content: "\f754"; }

.fa-moon-stars:before {
  content: "\f755"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mountains:before {
  content: "\f6fd"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-mug-marshmallows:before {
  content: "\f7b7"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-narwhal:before {
  content: "\f6fe"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-oil-temp:before {
  content: "\f614"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-omega:before {
  content: "\f67a"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-ornament:before {
  content: "\f7b8"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-parking-circle:before {
  content: "\f615"; }

.fa-parking-circle-slash:before {
  content: "\f616"; }

.fa-parking-slash:before {
  content: "\f617"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paw-alt:before {
  content: "\f701"; }

.fa-paw-claws:before {
  content: "\f702"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pegasus:before {
  content: "\f703"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-paintbrush:before {
  content: "\f618"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-person-sign:before {
  content: "\f757"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-office:before {
  content: "\f67d"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pi:before {
  content: "\f67e"; }

.fa-pie:before {
  content: "\f705"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pig:before {
  content: "\f706"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza:before {
  content: "\f817"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-podium:before {
  content: "\f680"; }

.fa-podium-star:before {
  content: "\f758"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poll-people:before {
  content: "\f759"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-popcorn:before {
  content: "\f819"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-presentation:before {
  content: "\f685"; }

.fa-print:before {
  content: "\f02f"; }

.fa-print-search:before {
  content: "\f81a"; }

.fa-print-slash:before {
  content: "\f686"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pumpkin:before {
  content: "\f707"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-rabbit:before {
  content: "\f708"; }

.fa-rabbit-fast:before {
  content: "\f709"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-raindrops:before {
  content: "\f75c"; }

.fa-ram:before {
  content: "\f70a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-rings-wedding:before {
  content: "\f81b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-route-highway:before {
  content: "\f61a"; }

.fa-route-interstate:before {
  content: "\f61b"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-triangle:before {
  content: "\f61c"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-rv:before {
  content: "\f7be"; }

.fa-sack:before {
  content: "\f81c"; }

.fa-sack-dollar:before {
  content: "\f81d"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salad:before {
  content: "\f81e"; }

.fa-sandwich:before {
  content: "\f81f"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-sausage:before {
  content: "\f820"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scalpel:before {
  content: "\f61d"; }

.fa-scalpel-path:before {
  content: "\f61e"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-scarecrow:before {
  content: "\f70d"; }

.fa-scarf:before {
  content: "\f7c1"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-scroll-old:before {
  content: "\f70f"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-scythe:before {
  content: "\f710"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-sheep:before {
  content: "\f711"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-shield-cross:before {
  content: "\f712"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shish-kebab:before {
  content: "\f821"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shovel:before {
  content: "\f713"; }

.fa-shovel-snow:before {
  content: "\f7c3"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shredder:before {
  content: "\f68a"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sickle:before {
  content: "\f822"; }

.fa-sigma:before {
  content: "\f68b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signal-1:before {
  content: "\f68c"; }

.fa-signal-2:before {
  content: "\f68d"; }

.fa-signal-3:before {
  content: "\f68e"; }

.fa-signal-4:before {
  content: "\f68f"; }

.fa-signal-alt:before {
  content: "\f690"; }

.fa-signal-alt-1:before {
  content: "\f691"; }

.fa-signal-alt-2:before {
  content: "\f692"; }

.fa-signal-alt-3:before {
  content: "\f693"; }

.fa-signal-alt-slash:before {
  content: "\f694"; }

.fa-signal-slash:before {
  content: "\f695"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-skeleton:before {
  content: "\f620"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-ski-jump:before {
  content: "\f7c7"; }

.fa-ski-lift:before {
  content: "\f7c8"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sledding:before {
  content: "\f7cb"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoke:before {
  content: "\f760"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snake:before {
  content: "\f716"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snow-blowing:before {
  content: "\f761"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowflakes:before {
  content: "\f7cf"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowmobile:before {
  content: "\f7d1"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-soup:before {
  content: "\f823"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spider-black-widow:before {
  content: "\f718"; }

.fa-spider-web:before {
  content: "\f719"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root:before {
  content: "\f697"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-squirrel:before {
  content: "\f71a"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-staff:before {
  content: "\f71b"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-christmas:before {
  content: "\f7d4"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-stars:before {
  content: "\f762"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steak:before {
  content: "\f824"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-steering-wheel:before {
  content: "\f622"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stocking:before {
  content: "\f7d5"; }

.fa-stomach:before {
  content: "\f623"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-stretcher:before {
  content: "\f825"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-sun-cloud:before {
  content: "\f763"; }

.fa-sun-dust:before {
  content: "\f764"; }

.fa-sun-haze:before {
  content: "\f765"; }

.fa-sunrise:before {
  content: "\f766"; }

.fa-sunset:before {
  content: "\f767"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-sword:before {
  content: "\f71c"; }

.fa-swords:before {
  content: "\f71d"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tachometer-alt-average:before {
  content: "\f624"; }

.fa-tachometer-alt-fast:before {
  content: "\f625"; }

.fa-tachometer-alt-fastest:before {
  content: "\f626"; }

.fa-tachometer-alt-slow:before {
  content: "\f627"; }

.fa-tachometer-alt-slowest:before {
  content: "\f628"; }

.fa-tachometer-average:before {
  content: "\f629"; }

.fa-tachometer-fast:before {
  content: "\f62a"; }

.fa-tachometer-fastest:before {
  content: "\f62b"; }

.fa-tachometer-slow:before {
  content: "\f62c"; }

.fa-tachometer-slowest:before {
  content: "\f62d"; }

.fa-taco:before {
  content: "\f826"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tally:before {
  content: "\f69c"; }

.fa-tanakh:before {
  content: "\f827"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-tasks-alt:before {
  content: "\f828"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-frigid:before {
  content: "\f768"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-hot:before {
  content: "\f76a"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-theta:before {
  content: "\f69e"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-thunderstorm:before {
  content: "\f76c"; }

.fa-thunderstorm-moon:before {
  content: "\f76d"; }

.fa-thunderstorm-sun:before {
  content: "\f76e"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-tilde:before {
  content: "\f69f"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tire:before {
  content: "\f631"; }

.fa-tire-flat:before {
  content: "\f632"; }

.fa-tire-pressure-warning:before {
  content: "\f633"; }

.fa-tire-rugged:before {
  content: "\f634"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toilet-paper-alt:before {
  content: "\f71f"; }

.fa-tombstone:before {
  content: "\f720"; }

.fa-tombstone-alt:before {
  content: "\f721"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-toothbrush:before {
  content: "\f635"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tornado:before {
  content: "\f76f"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-cone:before {
  content: "\f636"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-traffic-light-go:before {
  content: "\f638"; }

.fa-traffic-light-slow:before {
  content: "\f639"; }

.fa-traffic-light-stop:before {
  content: "\f63a"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-treasure-chest:before {
  content: "\f723"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-tree-christmas:before {
  content: "\f7db"; }

.fa-tree-decorated:before {
  content: "\f7dc"; }

.fa-tree-large:before {
  content: "\f7dd"; }

.fa-tree-palm:before {
  content: "\f82b"; }

.fa-trees:before {
  content: "\f724"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-truck-plow:before {
  content: "\f7de"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-turkey:before {
  content: "\f725"; }

.fa-turtle:before {
  content: "\f726"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-unicorn:before {
  content: "\f727"; }

.fa-union:before {
  content: "\f6a2"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-chart:before {
  content: "\f6a3"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-crown:before {
  content: "\f6a4"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-hard-hat:before {
  content: "\f82c"; }

.fa-user-headset:before {
  content: "\f82d"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-md-chat:before {
  content: "\f82e"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-class:before {
  content: "\f63d"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-users-crown:before {
  content: "\f6a5"; }

.fa-users-medical:before {
  content: "\f830"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-value-absolute:before {
  content: "\f6a6"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volcano:before {
  content: "\f770"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume:before {
  content: "\f6a8"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-slash:before {
  content: "\f2e2"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-nay:before {
  content: "\f771"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walker:before {
  content: "\f831"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-wand:before {
  content: "\f72a"; }

.fa-wand-magic:before {
  content: "\f72b"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-watch-fitness:before {
  content: "\f63e"; }

.fa-water:before {
  content: "\f773"; }

.fa-water-lower:before {
  content: "\f774"; }

.fa-water-rise:before {
  content: "\f775"; }

.fa-webcam:before {
  content: "\f832"; }

.fa-webcam-slash:before {
  content: "\f833"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whale:before {
  content: "\f72c"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheat:before {
  content: "\f72d"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wifi-1:before {
  content: "\f6aa"; }

.fa-wifi-2:before {
  content: "\f6ab"; }

.fa-wifi-slash:before {
  content: "\f6ac"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-wind-warning:before {
  content: "\f776"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-windsock:before {
  content: "\f777"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wreath:before {
  content: "\f7e2"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("/webfonts/fa-solid-900.eot");
  src: url("/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/webfonts/fa-solid-900.woff2") format("woff2"), url("/webfonts/fa-solid-900.woff") format("woff"), url("/webfonts/fa-solid-900.ttf") format("truetype"), url("/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("/webfonts/fa-regular-400.eot");
  src: url("/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/webfonts/fa-regular-400.woff2") format("woff2"), url("/webfonts/fa-regular-400.woff") format("woff"), url("/webfonts/fa-regular-400.ttf") format("truetype"), url("/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }

/* Base Styles -------------------- */
* {
  box-sizing: border-box;
  outline: none; }

html, body, #root {
  font-size: 100%;
  line-height: 1.5;
  color: #333;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  height: 100%;
  background-color: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.disable-scroll {
  overflow: hidden; }

h1 {
  font-size: 1.85rem;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  margin-top: 0; }

h2 {
  font-size: 1.85rem;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal; }

h3 {
  font-size: 1.5rem;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal; }

h4 {
  font-size: 1.25rem;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal; }

h5 {
  font-size: 1rem;
  line-height: normal;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: normal; }

.mainGreen {
  color: #00a9a7; }

.italic {
  font-style: italic; }

.bold {
  font-weight: 700 !important; }

/* Font Sizes Start */
.x-large {
  font-size: 2.5rem;
  line-height: normal;
  font-weight: normal; }

.large {
  font-size: 1.85rem;
  line-height: normal;
  font-weight: normal; }

.medium {
  font-size: 1.5rem;
  line-height: normal;
  font-weight: normal; }

.heading {
  font-size: 1.25rem;
  line-height: normal;
  font-weight: normal; }

.small {
  font-size: 1rem;
  line-height: normal;
  font-weight: normal; }

p, .default-txt, ul, ol {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: normal;
  max-width: 700px; }

p code {
  font-size: 1.1em;
  padding: 0 5px;
  color: #0d6959; }

.caption {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: normal; }

.caption-sub {
  font-size: 0.75rem;
  line-height: normal;
  font-weight: normal; }

/* Font Sizes End */
/* Pseudo-classes ------------------ */
a:link {
  color: #146aff;
  text-decoration: none; }

a:hover, a:focus {
  text-decoration: underline; }

.ds-app {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden; }

.l11-app {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  height: auto;
  overflow: hidden; }
  .l11-app #container {
    max-width: calc(100% - 200px); }

.main-nav {
  flex: 1 1 200px;
  min-height: 100%;
  background-color: #FFF;
  border-right: 1px solid #dddddd;
  overflow: auto; }

#id-main-content {
  overflow: auto; }

.Loop11-logo {
  border: none !important; }

.Loop11-logo img {
  width: 83px;
  height: 37px;
  object-fit: contain;
  margin: 20px 0 18px 54px; }

.main-nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.main-nav ul li {
  padding: 0 0 0 0px; }

.nav-category {
  padding: 0 0 0 24px;
  font-weight: bold; }

.nav-child {
  padding: 0 0 0 40px; }

ul ul ul .nav-child {
  padding-left: 60px; }

.main-nav a {
  font-size: 0.875rem;
  line-height: 34px;
  font-weight: normal;
  color: #333;
  text-decoration: none;
  display: block;
  box-sizing: border-box; }

.main-nav a:hover, .main-nav a:focus, .main-nav a.active {
  border-left: 4px solid #00a9a7; }

.nav-child:hover, .nav-child:focus, .nav-child.active {
  color: #00a9a7; }

.container {
  padding: 50px 0px 0 0;
  min-width: calc(100% - 200px);
  flex: 1 1;
  box-sizing: border-box; }

.container > div {
  padding: 30px 0 50px 50px;
  display: flex;
  flex-direction: column; }

.white-bg {
  background-color: #FFF; }

.component-loc {
  font-size: 1em;
  width: 200px;
  overflow: hidden;
  margin: -15px 0 0 0; }

.row {
  float: left; }

.row a {
  color: #4274fd; }

.wrap {
  flex-wrap: wrap; }

table {
  text-align: left;
  width: 600px;
  border-collapse: collapse;
  margin-bottom: 40px; }

table tr {
  border-bottom: 1px solid #dddddd; }

table tr.bd-top {
  border-top: 1px solid #dddddd; }

table th {
  color: #00a9a7;
  line-height: 2.5em; }

table tr h3 {
  letter-spacing: 3px; }

table td {
  padding: 10px 0; }
  table td.v-top {
    vertical-align: top; }

.add-hover {
  cursor: pointer; }

.code-block {
  width: 750px;
  overflow: hidden; }

.example-block {
  width: 600px;
  min-height: 150px;
  background-color: #f7f7f7;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.example-block.align-left {
  justify-content: flex-start;
  align-items: flex-start; }

.content-block {
  width: auto;
  min-height: 150px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 47px; }

.evenly-space {
  display: flex;
  justify-content: flex-start; }
  .evenly-space.successUrlWrapper {
    margin-top: -18px;
    flex-wrap: wrap; }
    .evenly-space.successUrlWrapper .form-group {
      margin: 4px 0 -10px 0; }
    .evenly-space.successUrlWrapper button {
      margin-top: 16px; }
  .evenly-space > span {
    margin-right: 24px; }
  .evenly-space .form-group {
    width: auto; }

.loading-msg {
  padding: 24px 0 0 0;
  color: rgba(51, 51, 51, 0.5); }

button {
  text-transform: capitalize; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.user-icon {
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 0.75rem;
  line-height: "";
  font-weight: bold;
  color: #FFF; }
  .user-icon.med {
    width: 40px;
    height: 40px;
    border-radius: 20px; }
  .user-icon.lrg {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    font-size: 1.125rem;
    line-height: "";
    font-weight: bold;
    color: #FFF; }

.stacked-icons {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  height: 24px;
  width: 40px; }
  .stacked-icons li {
    display: flex;
    position: absolute; }
    .stacked-icons li.icon-menu {
      border: 1px solid #dddddd;
      background-color: #FFF;
      color: #333;
      cursor: pointer; }
      .stacked-icons li.icon-menu:hover {
        border-color: #00a9a7; }
  .stacked-icons .user-icon-wrapper {
    width: 171px;
    min-height: 46px;
    height: auto;
    background-color: #FFF;
    position: absolute;
    top: 36px;
    right: 0;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation: fadeIn .2s ease-out 0s 1 forwards;
            animation: fadeIn .2s ease-out 0s 1 forwards;
    z-index: 16; }
    .stacked-icons .user-icon-wrapper:after {
      bottom: 100%;
      right: 5px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: -10px; }
    .stacked-icons .user-icon-wrapper ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .stacked-icons .user-icon-wrapper ul li {
        position: relative;
        line-height: 46px;
        align-items: center;
        padding-left: 16px; }
        .stacked-icons .user-icon-wrapper ul li span {
          margin-right: 8px; }

/* Question & Task Icons */
svg.MULTICHOICE1, svg.MULTICHOICEMULTI, svg.RANKING, svg.RATINGSCALE1, svg.SUS, svg.SYSTEMUSABILITYSCORE, svg.NPS, svg.NETPROMOTERSCORE {
  color: #146aff; }

#loadingContainer {
  text-align: center;
  justify-content: center;
  min-height: 100vh; }
  #loadingContainer h1 {
    color: #dddddd; }
  #loadingContainer h3 {
    font-style: italic; }

.full-width {
  width: 100% !important; }

/* Colors Start */
.color-squares {
  margin-left: 0;
  padding: 0;
  list-style: none;
  float: left;
  width: 100%;
  max-width: 800px; }

.color-squares li {
  width: 137px;
  overflow: hidden;
  margin: 0 15px 25px 0;
  float: left; }

.color-squares li div {
  width: 137px;
  height: 137px; }

.color-squares pre {
  margin: 0; }

.color-squares li span {
  display: block;
  font-size: 1em; }

.color-squares li > span {
  margin-left: 7px; }

/* Colors End */
/* Layer Component Styles Start  */
.layer-types {
  margin: 0 0 30px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  width: 700px;
  height: 320px; }

.layer-types li {
  flex: 1 1 150px;
  height: 150px;
  max-width: 150px;
  background-color: #FFF;
  margin: 0 25px 25px 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.flat {
  z-index: 1; }

.floating {
  z-index: 4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.raised {
  z-index: 8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }

.overlay {
  z-index: 16;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2); }

.sticky-nav {
  z-index: 16;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); }

.pop-out {
  z-index: 24;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2); }

/* Layer Component Styles End  */
/* Grid Component Styles Start  */
.grid-tables {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 700px; }

.grid-tables li {
  display: flex; }

.grid-tables li span {
  margin: auto 0; }

/* Grid Component Styles End  */
.btn-tabs {
  display: flex; }

button {
  padding: 16px 32px;
  background-color: #00a9a7;
  color: #FFF;
  border-radius: 2px;
  border: none;
  font-size: 0.875rem;
  font-weight: regular;
  margin: 2px;
  box-sizing: border-box; }
  button:hover, button:focus {
    background-color: #148f8d;
    cursor: pointer; }
  button:active {
    background-color: #0d6959; }
  button:disabled {
    background-color: #65C7AB; }
  button svg {
    margin-right: 5px; }

.tab-info-container {
  margin: 0;
  padding: 0;
  list-style: none; }

.tab-info-container li {
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.tab-info-container li.show {
  opacity: 1; }

/* Button Variations */
.grey-btn {
  background-color: #e2e2e2;
  color: #333; }
  .grey-btn:active {
    background-color: #666666;
    color: #FFF; }
  .grey-btn:hover, .grey-btn:focus {
    background-color: #9b9b9b;
    color: #FFF; }
  .grey-btn:active {
    background-color: #666666;
    color: #FFF; }
  .grey-btn:disabled {
    background-color: #f1f1f1;
    color: #FFF; }

.blue-btn {
  background-color: #4d5c74;
  color: #FFF; }
  .blue-btn:active {
    background-color: #3e4e67; }
  .blue-btn:hover, .blue-btn:focus {
    background-color: #3e4e67; }
  .blue-btn:active {
    background-color: #333; }
  .blue-btn:disabled {
    background-color: #4d5c74;
    opacity: 0.5; }

.slim {
  padding: 8px 16px !important; }

.tiny {
  padding: 4px 8px;
  font-size: 0.75rem; }

.rnd-cnr {
  border-radius: 32px; }

.txt-btn {
  background: none;
  font-weight: bold;
  color: #00a9a7;
  padding: 8px; }
  .txt-btn:hover {
    background: #00a9a7;
    color: #FFF; }
  .txt-btn:focus, .txt-btn:active {
    background: #0d6959;
    color: #FFF; }
  .txt-btn:disabled {
    background: #65C7AB; }

.btn-tabs .txt-btn:hover, .btn-tabs .txt-btn:focus, .btn-tabs .txt-btn:active, .btn-tabs .txt-btn.active {
  border-bottom: 2px solid #00a9a7;
  background: none;
  color: #00a9a7; }

.outline {
  background: none;
  border: 1px solid #00a9a7;
  color: #00a9a7;
  font-weight: bold;
  padding: 15px 32px; }
  .outline:hover {
    background-color: #00a9a7;
    color: #FFF; }
    .outline:hover svg {
      color: #FFF !important; }
  .outline:focus, .outline:active {
    background-color: #0d6959;
    color: #FFF; }
  .outline:disabled {
    border-color: #65C7AB;
    color: #65C7AB;
    background-color: #f5fffd; }
  .outline.grey-btn {
    border-color: #9b9b9b;
    color: #9b9b9b; }
    .outline.grey-btn:hover {
      background: #e2e2e2;
      border-color: #e2e2e2; }
    .outline.grey-btn:focus, .outline.grey-btn:active {
      background: #CCCCCC;
      border-color: #CCCCCC; }
    .outline.grey-btn:disabled {
      border-color: #9b9b9b;
      color: #9b9b9b; }
  .outline.white-btn {
    border-color: #FFF;
    color: #FFF; }
    .outline.white-btn:hover, .outline.white-btn:focus, .outline.white-btn:active {
      background: #FFF;
      border-color: #FFF;
      color: #00a9a7; }
    .outline.white-btn:disabled {
      border-color: #9b9b9b;
      color: #9b9b9b; }

.grey-txt {
  color: #333; }
  .grey-txt:hover {
    background: #e2e2e2;
    color: #333; }
  .grey-txt:focus, .grey-txt:active {
    background: #666666;
    color: #333; }
  .grey-txt:disabled {
    background: #f1f1f1; }

.fw-btn {
  width: 100%; }

form.ds-form {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  align-items: center;
  justify-content: space-around; }

.disable {
  opacity: 0.5;
  pointer-events: none; }

input[type='text'], input[type=email], textarea {
  z-index: 10;
  color: #333; }
  input[type='text']:disabled, input[type=email]:disabled, textarea:disabled {
    background-color: #e6e6e6; }
    input[type='text']:disabled::-webkit-input-placeholder, input[type=email]:disabled::-webkit-input-placeholder, textarea:disabled::-webkit-input-placeholder {
      color: rgba(51, 51, 51, 0.5); }
    input[type='text']:disabled::-moz-placeholder, input[type=email]:disabled::-moz-placeholder, textarea:disabled::-moz-placeholder {
      color: rgba(51, 51, 51, 0.5); }
    input[type='text']:disabled::-ms-input-placeholder, input[type=email]:disabled::-ms-input-placeholder, textarea:disabled::-ms-input-placeholder {
      color: rgba(51, 51, 51, 0.5); }
    input[type='text']:disabled::placeholder, input[type=email]:disabled::placeholder, textarea:disabled::placeholder {
      color: rgba(51, 51, 51, 0.5); }

input[type='text']::-webkit-input-placeholder,
input[type=email]::-webkit-input-placeholder,
input[type=number]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ababab; }

input[type='text']::-moz-placeholder,
input[type=email]::-moz-placeholder,
input[type=number]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #ababab; }

input[type='text']::-ms-input-placeholder,
input[type=email]::-ms-input-placeholder,
input[type=number]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #ababab; }

input[type='text']::placeholder,
input[type=email]::placeholder,
input[type=number]::placeholder,
textarea::placeholder {
  color: #ababab; }

.inpt-xsml {
  width: 110px; }

.inpt-smler {
  width: 155px; }

.inpt-sml {
  width: 180px; }
  .inpt-sml.extend {
    width: 218px;
    margin-left: 4px !important; }

.inpt-med {
  width: 250px; }

.inpt-lrg {
  width: 350px; }

.inpt-xlrg {
  width: 600px; }

.code-block {
  overflow: unset; }
  .code-block .inpt-xlrg {
    width: 550px;
    font-family: monospace;
    font-size: 1rem !important; }
  .code-block button {
    max-height: 42px;
    margin-top: 8px;
    padding: 0 0 0 4px;
    width: 40px; }

.inpt-step {
  width: 75px;
  text-align: center; }

.cb-wrap {
  display: inline-flex;
  margin: 0 0 16px 0;
  cursor: pointer;
  height: 21px; }

.cb-wrap label {
  margin: -2px 0 0 6px;
  pointer-events: none;
  font-size: 0.875rem; }

.cb-wrap input[type="checkbox"] {
  display: none; }

.cb-wrap input[type="checkbox"] + span {
  display: flex;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: #FFF left top no-repeat;
  border: 1px solid #dddddd;
  cursor: pointer;
  color: #FFF;
  justify-content: center;
  align-content: center; }

.cb-wrap input[type="checkbox"]:checked + span {
  background: #00a9a7 -19px top no-repeat; }

.cb-wrap input[type="checkbox"] + span {
  margin-right: 4px; }

.cb-wrap input[type="checkbox"] + span svg {
  margin: auto; }

.cb-wrap input[type="radio"] {
  display: none; }

.cb-wrap input[type="radio"] + span {
  display: flex;
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  min-width: 20px !important;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: #FFF left top no-repeat;
  border: 1px solid #dddddd;
  cursor: pointer;
  color: #FFF;
  justify-content: center;
  align-content: center;
  border-radius: 50%; }

.cb-wrap input[type="radio"]:checked + span {
  border: 5px solid #00a9a7; }

.cb-wrap input[type="radio"] + span {
  margin-right: 4px; }

.cb-wrap input[type="radio"] + span svg {
  margin: auto; }

.dropdown-container {
  position: relative;
  height: 40px;
  margin-bottom: 5px; }
  .dropdown-container input {
    height: 42px;
    border-radius: 2px;
    border: solid 1px #dddddd;
    box-sizing: border-box;
    padding: 14px 28px 14px 16px !important;
    margin-bottom: 0 !important;
    font-family: Lato;
    font-size: 0.875rem;
    line-height: 40px;
    cursor: pointer; }
  .dropdown-container .slim {
    height: 22px;
    padding: 4px 8px; }
  .dropdown-container .slim + svg {
    top: unset;
    bottom: 8px; }
  .dropdown-container .invisible {
    border: 1px solid rgba(0, 0, 0, 0) !important;
    background-color: inherit; }
    .dropdown-container .invisible:hover {
      background-color: white;
      border: solid 1px #dddddd !important; }
  .dropdown-container input:active, .dropdown-container input:focus {
    border: solid 1px #00a9a7; }
  .dropdown-container .arrow-down {
    position: absolute;
    top: 23px;
    right: 12px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: none;
    color: #333; }
  .dropdown-container .arrow-up {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .dropdown-container .scroll-wrap {
    position: absolute;
    width: calc(100%);
    max-height: 235px;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation: fadeIn .2s ease-out 0s 1 forwards;
            animation: fadeIn .2s ease-out 0s 1 forwards;
    z-index: 16; }
    .dropdown-container .scroll-wrap .list-container {
      overflow: auto;
      max-height: 235px; }
      .dropdown-container .scroll-wrap .list-container ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        .dropdown-container .scroll-wrap .list-container ul .create-button {
          background-color: #f3f5f5; }
        .dropdown-container .scroll-wrap .list-container ul .opt-group {
          padding: 12px 0 0px 15px;
          font-weight: bold; }
          .dropdown-container .scroll-wrap .list-container ul .opt-group:hover {
            background-color: #FFF;
            cursor: default; }
        .dropdown-container .scroll-wrap .list-container ul li {
          padding: 13px 13px 13px 26px; }
          .dropdown-container .scroll-wrap .list-container ul li:hover {
            background-color: #f3f5f5;
            cursor: pointer; }

.toggle-wrap {
  display: inline-flex;
  margin: 0 0 16px 0; }

.toggle-wrap p {
  margin: -1px 0 0 8px;
  pointer-events: none;
  font-weight: 700; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: #9b9b9b;
  -webkit-transition: .3s;
  transition: .3s; }

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s; }

input:checked + .slider {
  background-color: #00a9a7; }

input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  transform: translateX(11px); }

.toggled-on p {
  color: #00a9a7; }

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  width: 100%; }
  .form-group.underline {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 8px; }
  .form-group.no-wrap {
    flex-wrap: nowrap;
    margin-bottom: 5px; }
    .form-group.no-wrap .form-group {
      margin: 0; }
      .form-group.no-wrap .form-group .toggle-wrap {
        margin-bottom: 0; }
        .form-group.no-wrap .form-group .toggle-wrap p {
          font-size: 0.75rem;
          line-height: 1.5rem; }
  .form-group label:not(.switch) {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 700; }
  .form-group label span {
    font-weight: normal;
    font-size: 0.75rem;
    margin: 0 5px; }
  .form-group input[type=text], .form-group input[type=email], .form-group input[type=number] {
    line-height: 40px;
    padding: 0 16px;
    margin: 8px 0 4px 0;
    font-size: 0.875rem;
    border: #dddddd 1px solid;
    border-radius: 2px; }
  .form-group textarea {
    padding: 16px;
    margin: 8px 0 4px 0;
    font-size: 0.875rem;
    border: #dddddd 1px solid;
    border-radius: 2px; }
  .form-group input[type=text]:active, .form-group input[type=email]:active, .form-group input[type=number]:active, .form-group textarea:active {
    border-color: #00a9a7; }
  .form-group input[type=text]:focus, .form-group input[type=email]:focus, .form-group input[type=number]:focus, .form-group textarea:focus {
    box-shadow: 0 0 10px 0 #65C7AB; }
  .form-group .error-val {
    width: 100%;
    line-height: 1;
    margin: 4px 0 0 0;
    font-size: 0.75rem;
    color: #f63d39;
    display: none; }

.fg-auto-width {
  width: auto; }

.error input[type=text], .error input[type=email], .error input[type=number], .error textarea {
  border-color: #f63d39; }

.error .error-val {
  display: block; }

.react-datepicker-popper {
  z-index: 11 !important; }

.color-picker-rows {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-bottom: 50px; }
  .color-picker-rows li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    padding: 16px 0;
    position: relative; }
    .color-picker-rows li:first-child {
      padding-top: 0; }

.color-picker-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40px;
  height: 22px;
  border-radius: 2px;
  border: solid 1px #dddddd;
  background-color: #FFF;
  color: #333; }
  .color-picker-container svg:last-child {
    align-self: flex-start; }

.sketch-picker {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000; }

.sidebar-nav {
  background-color: #4d5c74;
  width: 200px;
  min-height: 100vh;
  height: auto; }
  .sidebar-nav ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .sidebar-nav ul li a {
      font-size: 0.875rem;
      line-height: 50px;
      font-weight: normal;
      color: #d6d6d6;
      text-decoration: none;
      display: block;
      box-sizing: border-box;
      padding: 0 0 0 24px;
      font-weight: bold; }
      .sidebar-nav ul li a span {
        width: 32px;
        margin-right: 8px;
        display: inline-block;
        text-align: center; }
      .sidebar-nav ul li a svg {
        margin: 0 0 -2px 0;
        color: #d6d6d6;
        font-size: 1.125rem; }
        .sidebar-nav ul li a svg:last-child {
          display: none; }
    .sidebar-nav ul li a:hover, .sidebar-nav ul li a:focus, .sidebar-nav ul li a.active {
      border-left: 4px solid #00a9a7;
      background-color: #3e4e67;
      padding-left: 20px;
      color: #FFF; }
      .sidebar-nav ul li a:hover svg, .sidebar-nav ul li a:focus svg, .sidebar-nav ul li a.active svg {
        color: #2FC8AD; }
        .sidebar-nav ul li a:hover svg:first-child, .sidebar-nav ul li a:focus svg:first-child, .sidebar-nav ul li a.active svg:first-child {
          display: none; }
        .sidebar-nav ul li a:hover svg:last-child, .sidebar-nav ul li a:focus svg:last-child, .sidebar-nav ul li a.active svg:last-child {
          display: inline-block; }
    .sidebar-nav ul li.line-break {
      border-top: 1px solid #3e4e67;
      margin: 8px auto;
      width: 80%; }

.top-nav {
  min-width: calc(100vw - 200px);
  height: 60px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.1); }
  .top-nav .align-right {
    min-width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px; }
  .top-nav .search-field {
    width: 300px;
    border-radius: 18px;
    border: 1px solid #dddddd;
    margin-left: 45px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative; }
    .top-nav .search-field:hover, .top-nav .search-field:focus, .top-nav .search-field:active {
      border-color: #00a9a7; }
    .top-nav .search-field svg {
      margin-left: 16px; }
    .top-nav .search-field label {
      display: none; }
    .top-nav .search-field input[type='text'] {
      border: none;
      background: none;
      margin: 0; }
      .top-nav .search-field input[type='text'].inpt-med {
        width: 270px; }
      .top-nav .search-field input[type='text']:hover, .top-nav .search-field input[type='text']:focus, .top-nav .search-field input[type='text']:active {
        box-shadow: none !important; }
    .top-nav .search-field .scroll-wrap {
      position: absolute;
      width: calc(100%);
      max-height: 235px;
      padding: 0;
      background-color: #fff;
      box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.2);
      -webkit-animation: fadeIn .2s ease-out 0s 1 forwards;
              animation: fadeIn .2s ease-out 0s 1 forwards;
      z-index: 16;
      top: 34px; }
      .top-nav .search-field .scroll-wrap .list-container {
        overflow: auto;
        max-height: 235px; }
        .top-nav .search-field .scroll-wrap .list-container ul {
          margin: 0;
          padding: 0;
          list-style: none; }
          .top-nav .search-field .scroll-wrap .list-container ul li {
            padding: 13px 0 13px 16px; }
            .top-nav .search-field .scroll-wrap .list-container ul li span:first-child {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc(100% - 16px);
              display: block; }
            .top-nav .search-field .scroll-wrap .list-container ul li span:last-child {
              font-size: 0.75rem;
              color: #9b9b9b;
              text-transform: capitalize; }
            .top-nav .search-field .scroll-wrap .list-container ul li:hover {
              background-color: #f3f5f5;
              cursor: pointer; }
              .top-nav .search-field .scroll-wrap .list-container ul li:hover span:last-child {
                color: #777777; }
  .top-nav .account-container {
    height: 32px;
    border-left: 1px solid #dddddd;
    margin-left: 16px;
    padding-left: 10px;
    display: flex;
    align-items: stretch;
    position: relative; }
    .top-nav .account-container > span {
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 16px; }
      .top-nav .account-container > span:nth-child(2) {
        margin-left: 8px; }
      .top-nav .account-container > span:hover {
        cursor: pointer;
        background-color: #f3f5f5; }
      .top-nav .account-container > span span {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #f0242c;
        font-size: 0.563em;
        line-height: "";
        font-weight: bold;
        color: #FFF;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #FFF; }
    .top-nav .account-container .account-wrapper {
      width: 250px;
      min-height: 300px;
      height: auto;
      background-color: #FFF;
      position: absolute;
      top: 38px;
      right: 0;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
      -webkit-animation: fadeIn .2s ease-out 0s 1 forwards;
              animation: fadeIn .2s ease-out 0s 1 forwards;
      z-index: 16; }
      .top-nav .account-container .account-wrapper:after {
        bottom: 100%;
        right: 5px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px; }
      .top-nav .account-container .account-wrapper ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        .top-nav .account-container .account-wrapper ul li:first-child {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #dddddd;
          padding: 16px; }
          .top-nav .account-container .account-wrapper ul li:first-child div {
            display: flex;
            flex-direction: column;
            width: 170px; }
            .top-nav .account-container .account-wrapper ul li:first-child div span:first-child, .top-nav .account-container .account-wrapper ul li:first-child div span:nth-child(2n) {
              width: 170px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
            .top-nav .account-container .account-wrapper ul li:first-child div span:nth-child(2n) {
              font-size: 0.75rem;
              line-height: "";
              font-weight: normal;
              color: #9b9b9b; }
            .top-nav .account-container .account-wrapper ul li:first-child div span:last-child {
              font-size: 0.75rem;
              line-height: "";
              font-weight: normal;
              color: #00a9a7; }
        .top-nav .account-container .account-wrapper ul li:not(:first-child) a {
          font-size: 0.875rem;
          line-height: 40px;
          font-weight: normal;
          color: #333;
          width: 100%;
          display: block;
          padding: 0 16px; }
          .top-nav .account-container .account-wrapper ul li:not(:first-child) a:hover {
            background-color: #f3f5f5; }
        .top-nav .account-container .account-wrapper ul li:not(:first-child) svg {
          margin-right: 8px; }
        .top-nav .account-container .account-wrapper ul .account-section a {
          padding-left: 40px !important; }
        .top-nav .account-container .account-wrapper ul .stand-alone-section {
          border-top: 1px solid #dddddd; }
  .top-nav .notification-wrapper {
    width: 250px;
    min-height: 300px;
    height: auto;
    background-color: #FFF;
    position: absolute;
    top: 38px;
    right: 42px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    -webkit-animation: fadeIn .2s ease-out 0s 1 forwards;
            animation: fadeIn .2s ease-out 0s 1 forwards;
    z-index: 16; }
    .top-nav .notification-wrapper:after {
      bottom: 100%;
      right: 5px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-left: -10px; }
    .top-nav .notification-wrapper > div {
      max-height: 300px;
      overflow: auto; }
    .top-nav .notification-wrapper ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .top-nav .notification-wrapper ul li {
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 16px; }
        .top-nav .notification-wrapper ul li a {
          font-size: 0.875rem;
          line-height: "";
          font-weight: normal;
          color: #333;
          width: 100%;
          display: block;
          padding: 0px 16px 0 16px; }
          .top-nav .notification-wrapper ul li a:hover {
            color: #4274fd; }
        .top-nav .notification-wrapper ul li span {
          font-size: 0.75rem;
          line-height: "";
          font-weight: normal;
          color: #9b9b9b;
          width: 100%;
          padding: 0 16px 16px 16px; }
          .top-nav .notification-wrapper ul li span svg {
            margin: 0 5px 2px 0;
            color: #4274fd; }
      .top-nav .notification-wrapper ul li:not(:first-child) {
        border-top: 1px solid #dddddd; }

.secondary-nav {
  display: flex; }
  .secondary-nav .grey-txt {
    color: #333;
    margin-right: 40px;
    padding: 8px 0 16px 0;
    background: none !important; }
    .secondary-nav .grey-txt:last-child {
      margin-right: 0; }
  .secondary-nav .txt-btn:hover {
    color: #9b9b9b; }
  .secondary-nav .txt-btn:focus, .secondary-nav .txt-btn:active, .secondary-nav .active {
    border-bottom: 2px solid #333; }

.context-head {
  width: 100%; }
  .context-head > div {
    padding: 0 47px; }
  .context-head .title-row {
    display: flex;
    justify-content: space-between; }
    .context-head .title-row h3 {
      display: flex;
      align-items: center;
      max-width: 50%;
      margin: 0; }
      .context-head .title-row h3 svg {
        margin-right: 8px; }
      .context-head .title-row h3 span {
        width: 100%;
        display: flex;
        align-items: center; }
        .context-head .title-row h3 span svg:last-child {
          font-size: 0.875rem;
          color: #e2e2e2;
          margin-left: 8px; }
          .context-head .title-row h3 span svg:last-child:hover {
            cursor: pointer;
            color: #00a9a7; }
        .context-head .title-row h3 span .form-group {
          margin: 0;
          max-width: 350px; }
          .context-head .title-row h3 span .form-group label {
            display: none; }
          .context-head .title-row h3 span .form-group input {
            margin: 0; }
    .context-head .title-row ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
      max-width: 50%; }
      .context-head .title-row ul li {
        margin-left: 4px; }
        .context-head .title-row ul li:last-child {
          color: #9e9e9e; }
          .context-head .title-row ul li:last-child svg {
            -webkit-animation: spin 2s linear infinite;
                    animation: spin 2s linear infinite; }
  .context-head .meta-detail-row {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    margin-top: 0.5rem; }
    .context-head .meta-detail-row > :first-child {
      width: 60% !important; }
    .context-head .meta-detail-row > :last-child {
      width: 40% !important;
      display: flex;
      justify-content: flex-end; }
      .context-head .meta-detail-row > :last-child li:last-child {
        margin-right: 2px; }
  .context-head .report-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.5rem; }
    .context-head .report-nav > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 1.25rem;
      line-height: "";
      font-weight: normal;
      color: #333;
      display: flex;
      flex-direction: row-reverse;
      align-items: center; }
      .context-head .report-nav > ul li button {
        margin-left: 8px; }
      .context-head .report-nav > ul li > span {
        width: 46px;
        height: 46px;
        display: flex !important;
        justify-content: center;
        align-items: center; }
        .context-head .report-nav > ul li > span.show {
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
          background-color: #FFF !important; }
        .context-head .report-nav > ul li > span:hover {
          background-color: #e2e2e2;
          cursor: pointer; }
        .context-head .report-nav > ul li > span svg {
          pointer-events: none; }
    .context-head .report-nav main {
      position: relative;
      width: 100%;
      z-index: 1; }
      .context-head .report-nav main section {
        position: absolute;
        top: -4px;
        width: 100%;
        min-height: 500px;
        height: auto;
        background-color: #FFF;
        border-radius: 2px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2); }
        .context-head .report-nav main section > div {
          padding: 24px; }
          .context-head .report-nav main section > div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dddddd; }
            .context-head .report-nav main section > div:first-child > span {
              width: 18%;
              display: flex;
              justify-content: space-between; }
              .context-head .report-nav main section > div:first-child > span svg:hover {
                cursor: pointer;
                color: #00a9a7; }
            .context-head .report-nav main section > div:first-child h4 {
              margin: 0; }
            .context-head .report-nav main section > div:first-child .form-group {
              margin: 0; }
              .context-head .report-nav main section > div:first-child .form-group .toggle-wrap {
                margin: 0; }
          .context-head .report-nav main section > div:not(:first-child) h5 {
            margin-bottom: 0; }
          .context-head .report-nav main section > div:not(:first-child) > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            .context-head .report-nav main section > div:not(:first-child) > ul:not(:nth-last-child(2)) {
              margin-bottom: -20px; }
            .context-head .report-nav main section > div:not(:first-child) > ul > li {
              margin-right: 16px; }
              .context-head .report-nav main section > div:not(:first-child) > ul > li:nth-child(3) .form-group {
                margin-bottom: 8px; }
              .context-head .report-nav main section > div:not(:first-child) > ul > li:last-child svg {
                color: #00a9a7; }
                .context-head .report-nav main section > div:not(:first-child) > ul > li:last-child svg:hover {
                  cursor: pointer;
                  color: #333; }
          .context-head .report-nav main section > div:nth-child(3) {
            padding-top: 0; }
          .context-head .report-nav main section > div:nth-child(4) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 0; }
            .context-head .report-nav main section > div:nth-child(4) button {
              margin-top: 22px;
              margin-bottom: 22px; }
              .context-head .report-nav main section > div:nth-child(4) button.outline {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0; }
                .context-head .report-nav main section > div:nth-child(4) button.outline:focus, .context-head .report-nav main section > div:nth-child(4) button.outline:active {
                  background-color: #00a9a7 !important; }
            .context-head .report-nav main section > div:nth-child(4) .form-group {
              width: auto;
              margin-top: 12px;
              margin-right: -2px; }
              .context-head .report-nav main section > div:nth-child(4) .form-group input[type="text"] {
                line-height: 47px;
                border-color: #00a9a7;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0; }
              .context-head .report-nav main section > div:nth-child(4) .form-group.error .error-val {
                position: absolute;
                right: 528px;
                width: 350px;
                text-align: right;
                line-height: 4em;
                padding-top: 5px; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* Overflow UI Start */
.overflow-span {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #333; }
  .overflow-span:hover, .overflow-span.open {
    background-color: #e2e2e2;
    color: #00a9a7 !important; }
  .overflow-span .ellipsis {
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    font-size: 1rem; }
  .overflow-span .e24 {
    font-size: 1.5rem; }

.overflow-span + label {
  font-size: 0.75rem !important;
  font-weight: normal !important;
  width: 100%;
  display: block;
  text-align: center;
  /* The margin bottom ensure the dropdown is flush against the button*/
  margin-bottom: -18px; }

.large .overflow-span {
  width: 40px;
  height: 40px;
  color: #e2e2e2; }

.large .overflow-span svg {
  font-size: 1.5rem; }

.overflow-span ~ .scroll-wrap {
  width: 125px; }
  .overflow-span ~ .scroll-wrap .list-container ul li {
    padding-left: 16px; }
    .overflow-span ~ .scroll-wrap .list-container ul li:hover, .overflow-span ~ .scroll-wrap .list-container ul li.open {
      color: #00a9a7; }

.scroll-wrap.extraWidth {
  width: 215px; }

.dropdown-container .center-dropdown {
  left: -44px;
  margin-top: 10px; }

.large .center-dropdown {
  left: -42px;
  margin-top: 12px; }

.center-dropdown:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #ffffff;
  border-width: 10px;
  margin-left: -10px; }

.scroll-wrap.right-dropdown {
  right: 0; }

/* Overflow UI End */
/* Tooltip Start */
.tooltip {
  position: relative;
  margin: 0 !important; }
  .tooltip.lower {
    margin-top: 5px !important; }
  .tooltip span {
    margin: 0 !important; }

.tooltip-trigger {
  display: inline-block;
  border-bottom: 1px dashed rgba(51, 51, 51, 0.5);
  cursor: pointer; }
  .tooltip-trigger svg {
    font-size: 0.875rem; }

.hide-underline {
  border-bottom: none; }

.tooltip-trigger > svg:hover {
  color: #00a9a7; }

.tooltip-bubble {
  position: absolute;
  z-index: 16; }
  .tooltip-bubble::after {
    content: '';
    position: absolute; }

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .tooltip-top::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid white;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.tooltip-bottom {
  top: 100%;
  left: 50%;
  padding-top: 9px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .tooltip-bottom::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid white;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .tooltip-left::after {
    border-left: 9px solid white;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  .tooltip-right::after {
    border-right: 9px solid white;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.tooltip-message {
  background: white;
  border-radius: 3px;
  color: #333;
  font-size: .75rem;
  line-height: 1.4;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  word-break: break-word; }
  .tooltip-message a {
    text-decoration: none; }
    .tooltip-message a:hover, .tooltip-message a :active {
      text-decoration: underline;
      color: #00a9a7; }
    .tooltip-message a svg {
      margin-left: 2px; }
  .tooltip-message span {
    text-align: left !important; }
  .tooltip-message table {
    width: 100%;
    margin: 0; }
  .tooltip-message table tbody tr {
    border-bottom: none; }
  .tooltip-message table td:last-child {
    text-align: right; }

.tt-xs {
  width: 70px;
  padding: .5em; }

.tt-sml {
  width: 122px; }

.tt-med {
  width: 180px; }

.tt-lrg {
  width: 250px; }

.tt-xl {
  width: 350px; }

.tooltip-icon {
  margin: 0 4px; }

/* Tooltip End */
/* Tag Start */
.tag-list-container {
  width: 100%; }

.tag-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: unset; }

.study-url {
  font-size: 0.75rem;
  line-height: "";
  font-weight: normal;
  color: #9b9b9b; }

.tag-item {
  margin: 0 4px 4px 0;
  padding: 4px 10px;
  background-color: #e2e2e2;
  border-radius: 21px;
  -webkit-transition: width 1s;
  transition: width 1s;
  width: auto;
  line-height: normal;
  line-height: initial;
  text-transform: capitalize; }
  .tag-item:not(.sub-level):hover, .tag-item.sub-level.custom-tag:hover {
    cursor: pointer;
    background-color: #9b9b9b;
    color: #FFF; }
  .tag-item.add-tag {
    background-color: #00a9a7;
    color: #FFF; }
    .tag-item.add-tag svg {
      margin-right: 5px; }
    .tag-item.add-tag:hover {
      background-color: #148f8d; }
  .tag-item.sub-level {
    font-size: 0.75rem;
    line-height: 1;
    font-weight: bold;
    color: #9b9b9b;
    border: 1px solid #f0f0f0;
    padding: 2px 10px; }
    .tag-item.sub-level.status-tag {
      background-color: #f0f0f0; }
    .tag-item.sub-level.custom-tag {
      background: none !important;
      border: 1px solid #8d8d8d;
      font-weight: normal; }
      .tag-item.sub-level.custom-tag:hover {
        background-color: #f0f0f0 !important;
        color: #333;
        border-color: #f0f0f0; }
  .tag-item.sub-level.status-tag[data-type="LAUNCHED"] {
    background-color: #00a9a7;
    color: #FFF; }
    .tag-item.sub-level.status-tag[data-type="LAUNCHED"]:hover {
      cursor: default; }
  .tag-item.active-tag {
    background-color: #00a9a7;
    color: #FFF; }
    .tag-item.active-tag:hover {
      background-color: #148f8d; }

tr.tag-list-container td {
  padding: 6px 0 2px 0; }
  tr.tag-list-container td ul.tag-list {
    margin-left: 24px; }

.tag-item .tooltip {
  margin-left: 5px !important;
  display: inline-block;
  opacity: 0.5; }
  .tag-item .tooltip .tooltip-trigger {
    border-bottom: none; }

.tag-item .tooltip svg {
  font-size: 0.65rem;
  margin-bottom: -0.5px; }
  .tag-item .tooltip svg:hover {
    color: inherit; }

.tag-item:hover .tooltip {
  opacity: 1; }

/* Tag End */
/* Icons Start */
.icon-grey {
  color: #e2e2e2; }

.list-icons {
  font-size: 0.75rem; }
  .list-icons a {
    display: flex;
    width: 70px;
    flex-wrap: wrap;
    justify-content: center;
    color: #333; }
    .list-icons a:hover .icon-grey {
      color: #00a9a7; }
    .list-icons a svg {
      font-size: 1.5rem; }
    .list-icons a span {
      margin-top: 8px;
      min-width: 100%;
      text-align: center;
      color: #333 !important; }

/* Icons End */
/* Accordion Start  */
.accordion-wrapper {
  width: 100%;
  margin: 20px 0 -20px 0; }
  .accordion-wrapper.hide {
    display: none; }

.acc-trigger {
  text-align: center;
  border-top: 1px solid #dddddd; }
  .acc-trigger svg {
    pointer-events: none;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out; }
  .acc-trigger:hover {
    cursor: pointer;
    background-color: rgba(247, 247, 247, 0.2); }
  .acc-trigger.open svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.accordion {
  display: block;
  overflow: hidden;
  -webkit-transition: max-height 1s ease-in-out;
  transition: max-height 1s ease-in-out;
  height: auto;
  max-height: 0;
  background-color: rgba(247, 247, 247, 0.2);
  box-shadow: inset 0px 11px 10px -10px rgba(0, 0, 0, 0.2), inset 0px -10px 10px -10px rgba(0, 0, 0, 0.2); }

.open {
  max-height: 600px; }

/* Accordion End  */
#modal-container {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }
  #modal-container.show-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11; }
  #modal-container #modal-body {
    width: 400px;
    height: auto;
    min-height: 359px;
    background-color: #f7f7f7;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    opacity: 1;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s; }
    #modal-container #modal-body.flat-display {
      min-height: 239px; }
      #modal-container #modal-body.flat-display #modal-controls {
        background-color: #f7f7f7;
        border-top: none;
        box-shadow: none; }
    #modal-container #modal-body.extra-width {
      width: 650px; }
    #modal-container #modal-body h1 {
      font-size: 1.25rem;
      line-height: 67px;
      padding: 0 24px;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #FFF;
      width: 100%; }
      #modal-container #modal-body h1 svg {
        margin-left: auto;
        cursor: pointer;
        font-size: 1.25rem; }
        #modal-container #modal-body h1 svg:hover {
          color: #00a9a7; }
        #modal-container #modal-body h1 svg path {
          pointer-events: none; }
    #modal-container #modal-body #modal-content {
      width: 100%;
      height: auto;
      background-color: #FFF; }
      #modal-container #modal-body #modal-content.flat-display {
        padding: 16px 24px;
        background-color: #f7f7f7; }
      #modal-container #modal-body #modal-content .form-group {
        margin: 0; }
    #modal-container #modal-body #modal-controls {
      width: 100%;
      align-self: flex-end;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 24px;
      background-color: #FFF;
      border-top: 1px solid #dddddd;
      box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.1); }
      #modal-container #modal-body #modal-controls .form-group {
        width: auto;
        align-items: center;
        margin: 0;
        padding: 24px 0; }
      #modal-container #modal-body #modal-controls #left-links {
        flex-grow: 2; }

.tag-manage-container {
  position: relative;
  min-height: 40px;
  width: 100%;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 24px;
  box-sizing: border-box; }
  .tag-manage-container #tag-search-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 5px 0; }
    .tag-manage-container #tag-search-container .selected-tag {
      margin: 4px 4px 0px 0;
      border-radius: 21px;
      width: auto;
      line-height: 1rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: normal;
      color: #9b9b9b;
      border: 1px solid #f0f0f0;
      padding: 2px 5px 2px 10px;
      background-color: #f0f0f0 !important;
      color: #333;
      border-color: #f0f0f0; }
      .tag-manage-container #tag-search-container .selected-tag svg {
        margin-left: 5px;
        color: #8d8d8d;
        font-size: 0.75rem;
        margin-bottom: -0.5px; }
        .tag-manage-container #tag-search-container .selected-tag svg:hover {
          color: #333;
          cursor: pointer; }
  .tag-manage-container svg {
    margin: auto 5px auto 0;
    color: #666666; }
  .tag-manage-container input[type='text'] {
    height: 1.5rem;
    border-radius: 2px;
    border: none !important;
    box-sizing: border-box;
    padding: 17px 16px;
    font-family: Lato;
    font-size: 14px;
    line-height: 40px;
    margin: 3px 0 0 0;
    min-width: 100px;
    width: auto; }
    .tag-manage-container input[type='text']:active, .tag-manage-container input[type='text']:focus {
      border: none;
      box-shadow: none; }

.tag-scroll-wrap {
  width: 100%;
  max-height: 246px;
  padding: 0;
  -webkit-animation: fadeIn .2s ease-out 0s 1 forwards;
          animation: fadeIn .2s ease-out 0s 1 forwards;
  z-index: 16; }
  .tag-scroll-wrap .tag-list-container {
    overflow: auto;
    max-height: inherit; }
    .tag-scroll-wrap .tag-list-container ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .tag-scroll-wrap .tag-list-container ul li {
        padding: 13px 0 13px 26px; }
        .tag-scroll-wrap .tag-list-container ul li#create-new-tag {
          background-color: #f3f5f5;
          cursor: pointer;
          display: none; }
          .tag-scroll-wrap .tag-list-container ul li#create-new-tag svg {
            margin-right: 8px; }
        .tag-scroll-wrap .tag-list-container ul li:hover {
          background-color: #f3f5f5;
          cursor: pointer; }
        .tag-scroll-wrap .tag-list-container ul li.hide-option {
          display: none; }

#projects-list {
  width: 100%;
  max-width: unset;
  padding: 0;
  margin: 0;
  list-style: none; }
  #projects-list.dashboard > li {
    display: flex;
    flex-wrap: wrap;
    min-height: 116px;
    padding: 20px 0;
    margin-top: 16px;
    z-index: 4;
    background-color: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    justify-content: space-between; }
    #projects-list.dashboard > li.show-p {
      display: flex; }
    #projects-list.dashboard > li.hide-p {
      display: none; }
    #projects-list.dashboard > li > div:first-child {
      margin-left: 20px; }
      #projects-list.dashboard > li > div:first-child .cb-wrap {
        padding-right: 16px; }
    #projects-list.dashboard > li > div:nth-child(2) {
      width: 450px; }
      #projects-list.dashboard > li > div:nth-child(2) h4 {
        margin: 4px 0 8px 0;
        width: 445px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
    #projects-list.dashboard > li > div:nth-child(3) {
      display: flex;
      align-items: center;
      width: 540px;
      min-width: 540px; }
      #projects-list.dashboard > li > div:nth-child(3) > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        display: flex;
        justify-content: space-between; }
        #projects-list.dashboard > li > div:nth-child(3) > ul li {
          display: flex;
          flex-wrap: wrap;
          text-align: center; }
          #projects-list.dashboard > li > div:nth-child(3) > ul li span {
            width: 100%; }
          #projects-list.dashboard > li > div:nth-child(3) > ul li span:first-child {
            font-size: 1.5rem; }
          #projects-list.dashboard > li > div:nth-child(3) > ul li span:last-child {
            font-size: 0.75rem; }
    #projects-list.dashboard > li > div:nth-child(4) {
      display: flex;
      align-items: center;
      margin-right: 20px;
      /* reposition the overflow component for vertical alignment with other buttons */ }
      #projects-list.dashboard > li > div:nth-child(4) .form-group {
        margin-top: 11px;
        margin-left: 10px; }

/* Participant Tables Start */
table.participants {
  width: 100%;
  margin-bottom: 0; }
  table.participants th {
    color: #333;
    font-size: 0.875rem; }
    table.participants th:first-child {
      padding-left: 24px; }
  table.participants tbody tr {
    background-color: #FFF;
    font-size: 0.875rem; }
    table.participants tbody tr:not(.tag-list-container):not(.spacer):hover {
      background-color: #f3f5f5; }
      table.participants tbody tr:not(.tag-list-container):not(.spacer):hover .play-button {
        border: 1px solid #00a9a7; }
    table.participants tbody tr:last-child {
      border-bottom: none; }
  table.participants td .participant-name {
    text-decoration: underline;
    font-weight: bold;
    color: #00a9a7; }
    table.participants td .participant-name:hover {
      text-decoration: none; }
  table.participants td .location {
    color: #9b9b9b; }
  table.participants .spacer {
    background-color: transparent;
    border-bottom: none;
    box-shadow: inset 0px 10px 10px -6px rgba(0, 0, 0, 0.1); }

td .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 34px;
  border-radius: 2px;
  background-color: #f3f5f5;
  margin: 10px 20px 10px 24px; }
  td .play-button svg, td .play-button .fa-play {
    color: #00a9a7; }
  td .play-button:hover {
    background-color: #00a9a7; }
    td .play-button:hover svg, td .play-button:hover .fa-play {
      color: #FFF; }

.accordion-wrapper .spacer {
  display: none; }

.video-task-rows thead tr {
  border-bottom: none; }

.video-task-rows .fa-arrow-to-bottom {
  margin-right: 5px; }

.video-task-rows tbody tr a.media-download {
  display: inline-block;
  margin-right: 10px;
  text-decoration: underline; }
  .video-task-rows tbody tr a.media-download::first-letter {
    text-transform: capitalize; }
  .video-task-rows tbody tr a.media-download:hover {
    text-decoration: none; }

.video-task-rows tbody tr .dot {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: -2px; }
  .video-task-rows tbody tr .dot + span {
    text-transform: lowercase;
    display: inline-block; }
    .video-task-rows tbody tr .dot + span::first-letter {
      text-transform: capitalize; }
  .video-task-rows tbody tr .dot.SUCCESS {
    background-color: #00a9a7; }
  .video-task-rows tbody tr .dot.FAIL {
    background-color: #ff9a9a; }
  .video-task-rows tbody tr .dot.ABANDON {
    background-color: #e6f3f0; }

.tagManagement {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .tagManagement li {
    background-color: #FFF;
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 64px;
    font-weight: 700;
    color: #333;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
    .tagManagement li > span:first-child {
      margin-left: 24px;
      display: flex;
      align-items: center; }
      .tagManagement li > span:first-child label {
        display: none; }
      .tagManagement li > span:first-child svg {
        margin-left: 8px; }
    .tagManagement li > span:last-child {
      margin-right: 24px; }
    .tagManagement li svg {
      color: #e2e2e2; }
      .tagManagement li svg:hover {
        cursor: pointer;
        color: #00a9a7; }

.faqList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .faqList li {
    background-color: #FFF;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
    .faqList li a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      height: 90px;
      color: #333; }
      .faqList li a:hover {
        border-left: 2px solid #00a9a7;
        text-decoration: none; }
      .faqList li a img {
        margin: 0 24px; }
      .faqList li a span h4 {
        margin: 0 0 5px 0;
        padding: 0;
        line-height: 1rem;
        font-weight: 700; }
      .faqList li a span span {
        font-size: 0.875rem;
        line-height: "";
        font-weight: 400;
        color: #333; }

.taskQuestionList {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: 100%; }
  .taskQuestionList li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #FFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    -webkit-transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    height: auto;
    overflow: hidden; }
    .taskQuestionList li.hide {
      display: none; }
    .taskQuestionList li.MULTICHOICE1, .taskQuestionList li.NPS, .taskQuestionList li.NETPROMOTERSCORE .taskQuestionList li.OPEN_ENDED_SINGLE {
      max-height: 90px; }
    .taskQuestionList li.MULTICHOICEMULTI, .taskQuestionList li.SUS, .taskQuestionList li.SYSTEMUSABILITYSCORE, .taskQuestionList li.RATINGSCALEMULTI, .taskQuestionList li.RATINGSCALE1, .taskQuestionList li.RANKING, .taskQuestionList li.OPENENDEDMULTIPLE, .taskQuestionList li.OPENENDEDCOMMENTS {
      max-height: 185px; }
    .taskQuestionList li.accordionReq.QUESTION::after {
      content: "";
      position: absolute;
      bottom: 22px;
      left: 0;
      width: 100%;
      height: 40px;
      opacity: 1;
      -webkit-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(62%, white), color-stop(100%, white));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 62%, white 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(62%, white), to(white));
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 62%, white 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 ); }
    .taskQuestionList li.accordionReq .acc-trigger {
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: #FFF;
      border-top: 1px solid #dddddd; }
    .taskQuestionList li.accordionReq.open {
      max-height: 400px; }
      .taskQuestionList li.accordionReq.open > div:last-child {
        width: 55% !important;
        max-height: inherit !important;
        overflow-y: scroll !important; }
      .taskQuestionList li.accordionReq.open::after {
        opacity: 0; }
      .taskQuestionList li.accordionReq.open.OPEN_ENDED_TEXTAREA {
        padding-bottom: 20px; }
    .taskQuestionList li > div {
      padding: 24px 12px;
      width: 30%; }
      .taskQuestionList li > div:first-child {
        padding: 24px;
        min-width: 150px;
        width: 15%;
        font-size: 0.875rem;
        line-height: "";
        font-weight: 700;
        color: #333; }
        .taskQuestionList li > div:first-child svg {
          margin-right: 8px; }
        .taskQuestionList li > div:first-child.task-icon-SUCCESS svg {
          color: #00a9a7; }
        .taskQuestionList li > div:first-child.task-icon-FAIL svg {
          color: #ff9a9a; }
        .taskQuestionList li > div:first-child.task-icon-ABANDON svg {
          color: #59a2de; }
        .taskQuestionList li > div:first-child img {
          max-height: 14px;
          max-width: 14px;
          margin-right: 8px;
          margin-bottom: -2px; }
      .taskQuestionList li > div.question:nth-child(3) td {
        width: 60%; }
      .taskQuestionList li > div.question:nth-child(3) td:last-child {
        width: 40%; }
      .taskQuestionList li > div:nth-child(3) {
        width: 55%; }
        .taskQuestionList li > div:nth-child(3) table {
          max-width: 100%;
          width: 100%;
          margin-bottom: 15px; }
          .taskQuestionList li > div:nth-child(3) table tr:first-child td {
            padding-top: 0; }
          .taskQuestionList li > div:nth-child(3) table tr:last-child {
            border-bottom: none; }
          .taskQuestionList li > div:nth-child(3) table td {
            padding: 16px 0; }
          .taskQuestionList li > div:nth-child(3) table td:first-child {
            font-weight: 700; }
          .taskQuestionList li > div:nth-child(3) table td:last-child {
            padding-left: 16px; }

section.pageCellWrapper {
  width: 100%;
  height: auto;
  max-height: 0;
  background-color: rgba(247, 247, 247, 0.2);
  padding: 0 24px 24px 24px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: none;
  -webkit-transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out, box-shadow 0.5s ease-in-out; }
  section.pageCellWrapper.open {
    max-height: 600px;
    padding-top: 24px;
    box-shadow: inset 0px 11px 10px -10px rgba(0, 0, 0, 0.2), inset 0px -10px 10px -10px rgba(0, 0, 0, 0.2); }
  section.pageCellWrapper h5 {
    font-size: 0.875rem;
    margin: 0;
    width: 100%; }
  section.pageCellWrapper > div:nth-child(3) {
    width: 85%;
    position: relative; }
    section.pageCellWrapper > div:nth-child(3) .end-obscure {
      position: absolute;
      height: 78px;
      width: 100px;
      top: 15px;
      right: 0;
      display: none;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+50 */
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(50%, white), to(white));
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      /* IE6-9 */ }
    section.pageCellWrapper > div:nth-child(3) .boxShadowProxy {
      position: absolute;
      height: 58px;
      width: 10px;
      top: 17px;
      box-shadow: -1px 0px 2px 0 rgba(0, 0, 0, 0.2);
      display: none; }
      section.pageCellWrapper > div:nth-child(3) .boxShadowProxy.show {
        display: block; }
    section.pageCellWrapper > div:nth-child(3) .pathList {
      display: none;
      max-width: 100%;
      margin-top: 16px; }
      section.pageCellWrapper > div:nth-child(3) .pathList li {
        display: block;
        box-shadow: none;
        height: 60px;
        border-bottom: 1px solid #dddddd; }
        section.pageCellWrapper > div:nth-child(3) .pathList li:last-child {
          border: none;
          height: 30px; }
          section.pageCellWrapper > div:nth-child(3) .pathList li:last-child.SUCCESS {
            color: #00a9a7; }
          section.pageCellWrapper > div:nth-child(3) .pathList li:last-child.FAIL {
            color: #ff9a9a; }
          section.pageCellWrapper > div:nth-child(3) .pathList li:last-child.ABANDON {
            color: #59a2de; }
        section.pageCellWrapper > div:nth-child(3) .pathList li.SUCCESS_URL span {
          color: #00a9a7; }
      section.pageCellWrapper > div:nth-child(3) .pathList.show {
        display: block !important; }

.show-path {
  display: flex !important; }

.toggle-success-paths {
  background-color: #FFF !important;
  max-height: 600px !important;
  padding: 24px !important;
  align-items: self-start;
  margin-bottom: 1%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important; }
  .toggle-success-paths div.show-path {
    flex-wrap: wrap; }
  .toggle-success-paths .spacer, .toggle-success-paths .tag-list-container, .toggle-success-paths .success-path, .toggle-success-paths .fail-path {
    display: none; }
    .toggle-success-paths .spacer p, .toggle-success-paths .tag-list-container p, .toggle-success-paths .success-path p, .toggle-success-paths .fail-path p {
      max-width: 100%; }
  .toggle-success-paths .toggle-container span {
    margin-top: 0; }
  .toggle-success-paths div:nth-child(3) {
    width: 100% !important; }
    .toggle-success-paths div:nth-child(3) p {
      width: 100%; }
      .toggle-success-paths div:nth-child(3) p button {
        margin-left: 8px; }
    .toggle-success-paths div:nth-child(3) ul li {
      background-color: #FFF;
      position: relative; }
  .toggle-success-paths a.play-button {
    margin-left: 0; }

.task .acc-trigger {
  color: #9b9b9b;
  font-size: 0.75rem;
  padding: 2px 0; }
  .task .acc-trigger:hover {
    color: #00a9a7; }

ul.project-type-and-dates {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 0.75rem; }
  ul.project-type-and-dates li {
    margin: 0 16px 0 0;
    text-transform: capitalize; }
    ul.project-type-and-dates li svg {
      margin-right: 4px; }
      ul.project-type-and-dates li svg.moderated {
        color: #00a9a7; }
      ul.project-type-and-dates li svg.unmoderated {
        color: #146aff; }
    ul.project-type-and-dates li span {
      color: #9b9b9b;
      margin-left: 2px; }

.toggle-container {
  width: 15%;
  display: flex; }
  .toggle-container span {
    width: 40px;
    height: 40px;
    margin: 16px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CCCCCC;
    color: #FFF;
    cursor: pointer; }
    .toggle-container span.selected, .toggle-container span:hover, .toggle-container span:focus {
      background-color: #00a9a7; }
    .toggle-container span svg {
      pointer-events: none; }
  .toggle-container.text-fill {
    margin-right: auto;
    margin-bottom: 24px;
    width: auto; }
    .toggle-container.text-fill span {
      width: auto;
      padding: 0 16px;
      margin-right: 0;
      background-color: #FFF;
      border: 1px solid #dddddd;
      font-size: 0.875rem;
      line-height: "";
      font-weight: 400;
      color: #333; }
      .toggle-container.text-fill span:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px; }
      .toggle-container.text-fill span:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px; }
      .toggle-container.text-fill span:not(:first-child):not(:last-child) {
        border-left: 0;
        border-right: 0; }
      .toggle-container.text-fill span.selected {
        background-color: #e6f3f0;
        font-weight: 700; }
      .toggle-container.text-fill span:hover {
        background-color: #e6f3f0; }

.task-question-block {
  background-color: #FFF;
  max-width: 365px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 24px; }
  .task-question-block ul.task-data {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 0 16px;
    margin-bottom: 12px; }
    .task-question-block ul.task-data li {
      display: flex;
      justify-content: space-between;
      padding: 8px 0; }
      .task-question-block ul.task-data li:first-child {
        padding-bottom: 0; }
      .task-question-block ul.task-data li span:first-child {
        font-weight: 700; }
  .task-question-block ul.question-data {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px; }
    .task-question-block ul.question-data li {
      padding: 12px 16px; }
      .task-question-block ul.question-data li:hover {
        background-color: #f3f5f5;
        cursor: pointer; }
      .task-question-block ul.question-data li p {
        margin: 0; }
        .task-question-block ul.question-data li p:first-child {
          font-size: 1rem;
          line-height: "";
          font-weight: 400;
          margin-bottom: 16px; }
        .task-question-block ul.question-data li p:not(:first-child) {
          font-size: 0.875rem;
          line-height: "";
          font-weight: 700; }

.click-path-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  overflow-x: scroll;
  overflow-y: hidden; }
  .click-path-container .canvas-container {
    padding-left: 24px;
    padding-top: 16px;
    height: 600px; }
    .click-path-container .canvas-container canvas {
      margin-left: 180px; }
  .click-path-container .page-cell-container {
    width: auto;
    min-width: 100%;
    height: 600px;
    padding: 0 24px 24px 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: -600px; }
    .click-path-container .page-cell-container ul.pathAnalysis {
      display: block;
      height: auto;
      background: none;
      padding-left: 100px;
      overflow-x: unset; }
      .click-path-container .page-cell-container ul.pathAnalysis:first-child {
        padding-left: 24px; }
      .click-path-container .page-cell-container ul.pathAnalysis li {
        margin-bottom: 48px;
        margin-right: 0;
        position: relative; }
        .click-path-container .page-cell-container ul.pathAnalysis li span.tooltip:last-child {
          position: absolute;
          min-width: 150px;
          height: 60px;
          top: 0;
          left: 0; }
          .click-path-container .page-cell-container ul.pathAnalysis li span.tooltip:last-child .tooltip-trigger {
            width: 100%;
            height: 100%;
            border-bottom: none; }
          .click-path-container .page-cell-container ul.pathAnalysis li span.tooltip:last-child .tooltip-bubble:after {
            border-bottom-color: #333 !important; }
          .click-path-container .page-cell-container ul.pathAnalysis li span.tooltip:last-child .tooltip-message {
            background-color: #333;
            color: #FFF; }
            .click-path-container .page-cell-container ul.pathAnalysis li span.tooltip:last-child .tooltip-message table td {
              padding: 0; }

.pathAnalysis {
  margin: 0 0 15px 0;
  padding: 16px 0 0 0;
  list-style: none;
  justify-content: flex-start;
  background: #f3f5f5;
  /* Old browsers */
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff), color-stop(13%, #ffffff), color-stop(15%, #ffffff), color-stop(16%, #f3f5f5), color-stop(70%, #f3f5f5), color-stop(69%, #ffffff), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 13%, #ffffff 15%, #f3f5f5 16%, #f3f5f5 69%, #ffffff 69%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(13%, #ffffff), color-stop(15%, #ffffff), color-stop(16%, #f3f5f5), color-stop(69%, #f3f5f5), color-stop(70%, #ffffff), to(#ffffff));
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 13%, #ffffff 15%, #f3f5f5 16%, #f3f5f5 69%, #ffffff 70%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 13%, #ffffff 15%, #f3f5f5 16%, #f3f5f5 69%, #ffffff 70%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  display: none;
  max-width: 100%;
  width: auto;
  height: 110px;
  overflow-x: scroll; }
  .pathAnalysis.no-overflow {
    overflow-x: visible; }
    .pathAnalysis.no-overflow li:nth-child(n+5) {
      opacity: 0; }
  .pathAnalysis li {
    width: 180px;
    min-width: 180px;
    height: 60px;
    margin-right: 25px;
    margin-bottom: 0;
    overflow: visible;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    border-radius: 2px;
    border-top: 1px solid #f3f5f5; }
    .pathAnalysis li.result {
      text-transform: capitalize;
      text-align: center;
      padding: 0;
      font-size: 0.75rem;
      line-height: "";
      font-weight: 700;
      color: #FFF; }
      .pathAnalysis li.result.SUCCESS {
        background-color: #00a9a7; }
      .pathAnalysis li.result.ABANDON {
        background-color: #59a2de; }
      .pathAnalysis li.result.FAIL {
        background-color: #ff9a9a; }
      .pathAnalysis li.result.OTHER {
        color: #333; }
      .pathAnalysis li.result section {
        width: 100%;
        margin-top: 5px; }
    .pathAnalysis li.SUCCESS_URL {
      border: 1px solid #00a9a7; }
      .pathAnalysis li.SUCCESS_URL::before {
        content: 'Success URL';
        text-align: center;
        color: #FFF;
        width: 180px;
        height: 16px;
        background-color: #00a9a7;
        position: absolute;
        top: -16px;
        left: -1px; }
    .pathAnalysis li > section {
      margin-top: 10px; }
      .pathAnalysis li > section div.pathRow {
        width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: 1;
        margin-right: 10px; }
      .pathAnalysis li > section svg {
        color: #ababab;
        font-size: 0.875rem; }
        .pathAnalysis li > section svg:hover {
          color: #00a9a7;
          cursor: pointer; }

.tableContainer {
  width: 100%;
  height: auto;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  padding: 24px 0; }
  .tableContainer.half-width {
    width: 49.5%;
    padding: 24px;
    margin-bottom: 1%; }
  .tableContainer h5 {
    margin-top: 0;
    margin-bottom: 24px; }
    .tableContainer h5 button {
      margin: 0 0 0 8px; }
  .tableContainer table {
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: normal;
    font-weight: normal; }
    .tableContainer table th {
      color: #333;
      padding-bottom: 16px;
      line-height: unset; }
    .tableContainer table td {
      padding: 16px 0; }
    .tableContainer table.minAvgMax, .tableContainer table.twoCol, .tableContainer table.batch {
      width: 100%; }
      .tableContainer table.minAvgMax td span.tooltip, .tableContainer table.twoCol td span.tooltip, .tableContainer table.batch td span.tooltip {
        white-space: nowrap; }
    .tableContainer table.minAvgMax th {
      width: 15%; }
    .tableContainer table.minAvgMax th:first-child {
      width: 40%; }
    .tableContainer table.minAvgMax td:first-child {
      font-weight: 700; }
    .tableContainer table.twoCol {
      margin-top: -8px;
      border-top: 1px solid #dddddd; }
      .tableContainer table.twoCol th {
        width: 50%; }
      .tableContainer table.twoCol td:last-child {
        text-align: right; }
    .tableContainer table.batch th:first-child, .tableContainer table.batch td:first-child {
      width: 68%; }
    .tableContainer table.batch th:nth-child(2), .tableContainer table.batch td:nth-child(2) {
      width: 22%; }
    .tableContainer table.batch th:nth-child(2), .tableContainer table.batch > th:not(:first-child), .tableContainer table.batch td:not(:first-child) {
      text-align: right; }
    .tableContainer table.batch th:last-child {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 0; }
      .tableContainer table.batch th:last-child > div {
        margin: -7px 0 0 0; }
        .tableContainer table.batch th:last-child > div .dropdown-container {
          margin-bottom: 0;
          height: auto; }
          .tableContainer table.batch th:last-child > div .dropdown-container .scroll-wrap {
            width: 220px; }
    .tableContainer table.batch td:last-child > span {
      margin: 6px 0 0 0; }
      .tableContainer table.batch td:last-child > span label {
        display: none; }
  .tableContainer .simple-answers {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
    border-top: none !important; }
    .tableContainer .simple-answers td {
      padding: 16px 24px; }
      .tableContainer .simple-answers td:first-child {
        width: 25%; }
        .tableContainer .simple-answers td:first-child span {
          color: #9b9b9b;
          font-size: 0.75rem; }
  .tableContainer.base-margin {
    margin-bottom: 24px; }

.matrix-container {
  width: 100%; }

.matrix-wrapper {
  position: relative; }
  .matrix-wrapper .matrix-scroller {
    margin-left: 250px;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: 300px; }
  .matrix-wrapper .matrix-table {
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    width: inherit;
    min-width: inherit; }
    .matrix-wrapper .matrix-table thead th {
      border: none;
      padding: 12px;
      text-align: left;
      background-color: #f3f5f5;
      color: #333;
      line-height: 1.3rem;
      min-height: 67px;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd; }
      .matrix-wrapper .matrix-table thead th:first-child {
        min-height: auto; }
    .matrix-wrapper .matrix-table tbody td {
      border-bottom: solid 1px #dddddd;
      background-color: #FFF;
      padding: 12px;
      min-height: 67px;
      min-width: 94px; }
      .matrix-wrapper .matrix-table tbody td.sticky-col {
        background-color: transparent;
        display: flex;
        align-items: center; }
      .matrix-wrapper .matrix-table tbody td:last-child {
        text-align: left; }
    .matrix-wrapper .matrix-table .sticky-col {
      left: 0;
      position: absolute;
      top: auto;
      width: 254px;
      font-weight: 700; }

#alert-container {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

@-webkit-keyframes alertOpacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes alertOpacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes toastAnimation {
  from {
    margin-top: -10px; }
  to {
    margin-top: 0px; } }

@keyframes toastAnimation {
  from {
    margin-top: -10px; }
  to {
    margin-top: 0px; } }
  #alert-container > div {
    min-height: 60px;
    padding: 15px 0;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    -webkit-animation: alertOpacity  0.5s, toastAnimation  0.5s;
            animation: alertOpacity  0.5s, toastAnimation  0.5s; }
    #alert-container > div[class^='alert-']:not(.alert-BANNER) {
      width: calc(100% - 200px) !important; }
    #alert-container > div div {
      max-width: 50%; }
    #alert-container > div svg {
      margin-right: 5px; }
    #alert-container > div span {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center; }
      #alert-container > div span:first-child {
        margin-right: auto;
        visibility: hidden; }
      #alert-container > div span:last-child {
        margin-left: auto; }
      #alert-container > div span svg {
        margin-right: 0; }
        #alert-container > div span svg:first-child {
          display: block; }
        #alert-container > div span svg:last-child {
          display: none; }
      #alert-container > div span:hover {
        cursor: pointer; }
        #alert-container > div span:hover svg:first-child {
          display: none; }
        #alert-container > div span:hover svg:last-child {
          display: block; }
  #alert-container .alert-BANNER {
    width: 100%;
    background-color: #146aff;
    border-color: #146aff;
    color: #FFF;
    border-radius: unset; }
    #alert-container .alert-BANNER span {
      display: none; }
  #alert-container .alert-INFO {
    background-color: #eaf1ff;
    border-color: #146aff;
    color: #146aff; }
  #alert-container .alert-SUCCESS {
    background-color: #f5fffd;
    border-color: #00a9a7;
    color: #00a9a7; }
  #alert-container .alert-ERROR {
    background-color: #fff1f3;
    border-color: #f63d39;
    color: #f63d39; }
  #alert-container .alert-WARNING {
    background-color: #fffbe9;
    border-color: #f5a623;
    color: #f5a623; }

.report-summary {
  width: 100%;
  padding-bottom: 24px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 24px 0; }
  .report-summary.QUESTION {
    border-bottom: none;
    padding-bottom: 0; }
  .report-summary > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 24px; }
    .report-summary > div section {
      min-width: 50%;
      max-width: 50%; }
      .report-summary > div section h4 {
        display: flex;
        line-height: inherit; }
        .report-summary > div section h4 .form-group {
          width: 110px;
          margin-top: -10px;
          margin-left: 10px; }
        .report-summary > div section h4 .tooltip {
          height: 30px;
          margin-top: -2px !important; }
      .report-summary > div section p {
        font-size: 1rem; }
      .report-summary > div section button {
        margin-bottom: 24px;
        margin-left: 24px; }
      .report-summary > div section:nth-child(2) .form-group {
        float: right; }
    .report-summary > div.PARTICIPANT h4 {
      margin-bottom: 0;
      max-height: 30px; }
    .report-summary > div.PARTICIPANT p {
      font-size: 0.75rem;
      line-height: normal;
      font-weight: normal;
      color: #9b9b9b;
      margin-top: 0; }
    .report-summary > div.statistics-container.TASK, .report-summary > div.statistics-container.QUESTION, .report-summary > div:not(.PARTICIPANT) section:last-child.TASK, .report-summary > div:not(.PARTICIPANT) section:last-child.QUESTION {
      border-top: 1px solid #dddddd;
      padding-top: 24px; }
    .report-summary > div.statistics-container:not(.DASHBOARD) ul:last-child, .report-summary > div:not(.PARTICIPANT) section:last-child:not(.DASHBOARD) ul:last-child {
      min-width: 30%;
      max-width: 400px;
      justify-content: flex-end; }
    .report-summary > div.statistics-container ul, .report-summary > div:not(.PARTICIPANT) section:last-child ul {
      margin: 0;
      padding: 0;
      list-style: none;
      min-width: 70%;
      display: flex;
      justify-content: flex-start; }
      .report-summary > div.statistics-container ul li, .report-summary > div:not(.PARTICIPANT) section:last-child ul li {
        font-size: 0.875rem;
        min-width: 77px;
        margin-right: 10px; }
        .report-summary > div.statistics-container ul li:last-child, .report-summary > div:not(.PARTICIPANT) section:last-child ul li:last-child {
          margin-right: 0; }
        .report-summary > div.statistics-container ul li span, .report-summary > div:not(.PARTICIPANT) section:last-child ul li span {
          font-size: 1.5rem;
          padding-top: 16px;
          display: block; }
      .report-summary > div.statistics-container ul:first-child li:first-child span, .report-summary > div:not(.PARTICIPANT) section:last-child ul:first-child li:first-child span {
        color: #00a9a7; }
      .report-summary > div.statistics-container ul:first-child li:nth-child(2) span, .report-summary > div:not(.PARTICIPANT) section:last-child ul:first-child li:nth-child(2) span {
        color: #ff9a9a; }
    .report-summary > div.statistics-container.PARTICIPANT {
      border-top: 1px solid #dddddd;
      padding-top: 24px;
      margin-top: 8px; }
      .report-summary > div.statistics-container.PARTICIPANT ul {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between; }
        .report-summary > div.statistics-container.PARTICIPANT ul li {
          min-width: 150px; }
          .report-summary > div.statistics-container.PARTICIPANT ul li:nth-child(4) {
            margin-left: 200px; }
          .report-summary > div.statistics-container.PARTICIPANT ul li:last-child {
            margin-left: 20px; }
          .report-summary > div.statistics-container.PARTICIPANT ul li:nth-last-child(-n+2) {
            margin-right: 0; }
            .report-summary > div.statistics-container.PARTICIPANT ul li:nth-last-child(-n+2) span {
              font-size: 1.125rem; }
    .report-summary > div:not(.PARTICIPANT) section:last-child {
      display: flex;
      justify-content: flex-end; }
      .report-summary > div:not(.PARTICIPANT) section:last-child ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 18px; }
        .report-summary > div:not(.PARTICIPANT) section:last-child ul li:first-child span {
          color: #333; }
        .report-summary > div:not(.PARTICIPANT) section:last-child ul li:nth-child(2) span {
          color: #00a9a7; }
        .report-summary > div:not(.PARTICIPANT) section:last-child ul li:nth-child(3) span {
          color: #9b9b9b; }
        .report-summary > div:not(.PARTICIPANT) section:last-child ul li:last-child {
          width: 370px;
          background-color: #e2e2e2;
          height: 8px;
          border-radius: 4px;
          margin: 24px 0; }
          .report-summary > div:not(.PARTICIPANT) section:last-child ul li:last-child aside {
            background-color: #00a9a7;
            display: block;
            height: 100%;
            border-radius: 4px; }
    .report-summary > div.PARTICIPANT section:last-child {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin-top: 10px; }
      .report-summary > div.PARTICIPANT section:last-child ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 30%;
        margin-right: 6%; }
        .report-summary > div.PARTICIPANT section:last-child ul li {
          display: flex;
          margin-bottom: 16px; }
          .report-summary > div.PARTICIPANT section:last-child ul li span:first-child {
            width: 150px; }
            .report-summary > div.PARTICIPANT section:last-child ul li span:first-child svg {
              margin-right: 5px; }
          .report-summary > div.PARTICIPANT section:last-child ul li span:last-child {
            color: #9b9b9b;
            width: 170px; }
        .report-summary > div.PARTICIPANT section:last-child ul:last-child {
          width: 28%;
          margin-right: 0; }
          .report-summary > div.PARTICIPANT section:last-child ul:last-child li a {
            margin-left: 10px; }

.pie-chart {
  width: 365px;
  background-color: #FFF;
  min-width: 365px;
  min-height: 245px;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .pie-chart:hover {
    background-color: #f3f5f5;
    cursor: pointer; }
  .pie-chart h5 {
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    margin-top: 0;
    margin-bottom: 24px; }
  .pie-chart canvas {
    max-width: 170px; }
  .pie-chart .chart-legend li {
    min-width: auto;
    margin-right: 20px;
    margin-top: 24px;
    font-size: 0.75rem; }
    .pie-chart .chart-legend li:last-child {
      margin-right: 0; }

.gauge-chart {
  width: 368px;
  min-width: 368px;
  min-height: 150px;
  padding: 24px 60px 0 60px;
  position: relative; }
  .gauge-chart h2, .gauge-chart h5 {
    position: absolute;
    bottom: 0;
    width: 245px;
    text-align: center; }
  .gauge-chart h5 {
    font-size: 0.875rem;
    line-height: "";
    font-weight: 400; }
  .gauge-chart h2 {
    bottom: 24px;
    font-size: 2.5rem;
    line-height: "";
    font-weight: 700; }
  .gauge-chart span {
    position: absolute;
    bottom: 56px; }
    .gauge-chart span.low-range {
      left: 10px;
      text-align: right;
      width: 38px; }
    .gauge-chart span.high-range {
      right: 24px; }
  .gauge-chart canvas {
    max-width: 245px; }

.bar-chart {
  background: none;
  box-shadow: none; }
  .bar-chart table {
    min-width: 100%;
    width: auto; }
    .bar-chart table td {
      border-top: 1px solid #dddddd; }
      .bar-chart table td:first-child {
        font-weight: 700;
        width: 20%; }
      .bar-chart table td.span-container {
        width: 70% !important; }
        .bar-chart table td.span-container span {
          display: block;
          height: 32px;
          background-color: #00a9a7;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px; }
      .bar-chart table td:last-child {
        width: 10%;
        text-align: right; }
    .bar-chart table.SUS {
      display: none; }
      .bar-chart table.SUS .span-container span {
        display: inline-block; }
        .bar-chart table.SUS .span-container span:not(:last-child) {
          border-radius: 0; }
      .bar-chart table.SUS .span-container .tooltip-trigger {
        height: 100%;
        width: 100%; }
      .bar-chart table.SUS .span-container .tooltip-message table {
        min-width: 100%;
        max-width: 100%;
        width: auto;
        font-size: 0.75rem; }
        .bar-chart table.SUS .span-container .tooltip-message table td, .bar-chart table.SUS .span-container .tooltip-message table th {
          padding: 8px 0; }
      .bar-chart table.SUS tr td:first-child {
        padding-right: 8px; }
    .bar-chart table.RATING td.top-align {
      vertical-align: top;
      font-size: 1.25rem; }
    .bar-chart table.RATING td:nth-child(2) {
      width: 15% !important; }
    .bar-chart table.RATING td:last-child {
      width: 10% !important; }
    .bar-chart table.RATING td:nth-child(3) {
      width: 55%; }

a.view-all {
  font-size: 0.875rem;
  line-height: "";
  font-weight: 700;
  color: #333;
  display: inline-block;
  width: 100%;
  text-align: right;
  padding-top: 4px; }
  a.view-all svg {
    margin-left: 3px; }

.bar-chart-vertical {
  width: auto;
  min-width: 100%;
  background: none;
  box-shadow: none; }
  .bar-chart-vertical .chart-bg-lines, .bar-chart-vertical .chart-columns {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none; }
  .bar-chart-vertical .chart-bg-lines li {
    width: 100%;
    height: 62px;
    display: flex;
    justify-content: space-between; }
    .bar-chart-vertical .chart-bg-lines li span:first-child {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: flex-end; }
    .bar-chart-vertical .chart-bg-lines li span:last-child {
      width: 90%;
      border-bottom: 1px solid #dddddd;
      height: 100%; }
    .bar-chart-vertical .chart-bg-lines li:first-child {
      border-top: 1px solid #dddddd; }
  .bar-chart-vertical .chart-columns {
    display: flex;
    justify-content: space-around;
    padding-left: 10%;
    margin-bottom: 30px; }
    .bar-chart-vertical .chart-columns li {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center; }
      .bar-chart-vertical .chart-columns li.w-eighty {
        width: 80px; }
      .bar-chart-vertical .chart-columns li.w-forty {
        width: 40px; }
      .bar-chart-vertical .chart-columns li span:not(.tooltip) {
        width: 100%;
        height: 40px;
        max-height: 81%;
        position: relative; }
      .bar-chart-vertical .chart-columns li span.tooltip .tooltip-trigger {
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-bottom: none;
        position: absolute;
        left: 0; }
        .bar-chart-vertical .chart-columns li span.tooltip .tooltip-trigger span {
          height: 100% !important;
          max-height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          display: inline-block;
          background: none !important; }
      .bar-chart-vertical .chart-columns li span:not(.tooltip)::after, .bar-chart-vertical .chart-columns li span.tooltip span span::after {
        content: attr(data-top);
        font-size: 1rem;
        line-height: "";
        font-weight: 700;
        color: #333;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%; }
      .bar-chart-vertical .chart-columns li span:not(.tooltip)::before, .bar-chart-vertical .chart-columns li span.tooltip span span::before {
        content: attr(data-bottom);
        font-size: 0.75rem;
        line-height: "";
        font-weight: 400;
        color: #333;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: -40px;
        left: 0;
        width: 100%;
        height: 30px; }

.chart-header {
  display: flex;
  flex-wrap: wrap; }
  .chart-header .left-column {
    width: 60%; }
    .chart-header .left-column .toggle-container {
      width: 100%;
      min-width: 100%;
      margin-bottom: 24px; }
  .chart-header .gauge-chart {
    padding-top: 0px; }
    .chart-header .gauge-chart canvas {
      margin-top: -60px; }

.table-wrapper {
  width: 100%;
  display: none; }

.chart-legend {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }
  .chart-legend.show-legend {
    display: flex;
    opacity: 1; }
  .chart-legend li {
    min-width: 150px; }
    .chart-legend li:nth-child(3) {
      margin-right: auto; }
    .chart-legend li b {
      text-transform: capitalize; }
    .chart-legend li svg {
      margin-right: 5px; }
    .chart-legend li span {
      border-top: 1px solid #dddddd;
      display: inline-block;
      width: 100%;
      margin-top: 12px;
      padding-top: 12px; }

#create-edit-study {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  max-width: 100%;
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s; }
  #create-edit-study.shrink {
    max-width: 58%; }
  #create-edit-study > li {
    position: relative;
    width: inherit;
    border-radius: 2px;
    border: 1px solid #dddddd;
    margin-bottom: 8px;
    box-sizing: border-box;
    cursor: pointer;
    max-height: 56px;
    box-shadow: none;
    -webkit-transition: max-height 0.5s, box-shadow 0.5s;
    transition: max-height 0.5s, box-shadow 0.5s;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column; }
    #create-edit-study > li section.head-row {
      width: inherit;
      height: 56px;
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: "";
      font-weight: 700;
      color: #333;
      padding: 0 24px;
      background-color: #FFF;
      flex: 0 0 auto; }
      #create-edit-study > li section.head-row:hover {
        background-color: #e6e6e6; }
      #create-edit-study > li section.head-row span {
        pointer-events: none; }
        #create-edit-study > li section.head-row span:first-child {
          margin-right: 24px; }
        #create-edit-study > li section.head-row span:last-child {
          margin-left: auto; }
    #create-edit-study > li.active {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
      border-color: #00a9a7;
      max-height: 600px;
      cursor: default; }
      #create-edit-study > li.active.overflow-toggle {
        overflow: visible; }
      #create-edit-study > li.active .head-row {
        color: #FFF;
        background-color: #00a9a7; }
        #create-edit-study > li.active .head-row:hover {
          background-color: #00a9a7; }
        #create-edit-study > li.active .head-row button {
          cursor: pointer;
          pointer-events: auto; }
  #create-edit-study .note-takers-list, #create-edit-study .moderated-participants-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 600px; }
    #create-edit-study .note-takers-list li, #create-edit-study .moderated-participants-list li {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      #create-edit-study .note-takers-list li .form-group, #create-edit-study .moderated-participants-list li .form-group {
        width: auto !important;
        margin: 0 8px 0 0; }
      #create-edit-study .note-takers-list li svg, #create-edit-study .moderated-participants-list li svg {
        color: #00a9a7; }
        #create-edit-study .note-takers-list li svg:hover, #create-edit-study .note-takers-list li svg:active, #create-edit-study .moderated-participants-list li svg:hover, #create-edit-study .moderated-participants-list li svg:active {
          color: #333;
          cursor: pointer; }

.settings-container {
  width: 100%;
  min-height: 300px;
  max-height: 800px;
  padding: 24px 24px 150px 24px;
  height: auto;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap; }
  .settings-container.questions-dropdown-open {
    overflow-y: visible; }
  .settings-container > div {
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: center; }
  .settings-container .dropdown-container {
    width: 600px; }
    .settings-container .dropdown-container .scroll-wrap {
      width: inherit; }
  .settings-container.s-tasks-questions {
    justify-content: center; }
    .settings-container.s-tasks-questions p.explainer {
      max-width: 600px; }
    .settings-container.s-tasks-questions #t-q-list {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column; }
      .settings-container.s-tasks-questions #t-q-list li {
        font-size: 0.875rem;
        line-height: "";
        font-weight: 700;
        color: #333;
        border-radius: 2px;
        border: 1px solid #dddddd;
        margin-bottom: 8px;
        box-sizing: border-box;
        cursor: pointer;
        min-height: 56px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        position: relative; }
        .settings-container.s-tasks-questions #t-q-list li > div:not(:first-child) {
          pointer-events: none; }
        .settings-container.s-tasks-questions #t-q-list li.selected {
          background-color: #f5fffd;
          border: 1px solid #00a9a7; }
        .settings-container.s-tasks-questions #t-q-list li > .form-group {
          margin: 0; }
          .settings-container.s-tasks-questions #t-q-list li > .form-group .toggle-wrap {
            margin-bottom: 0; }
            .settings-container.s-tasks-questions #t-q-list li > .form-group .toggle-wrap label span {
              margin: 0 10px 0 0; }
        .settings-container.s-tasks-questions #t-q-list li.task, .settings-container.s-tasks-questions #t-q-list li.question {
          display: flex;
          align-items: flex-start;
          padding: 24px; }
          .settings-container.s-tasks-questions #t-q-list li.task > div:first-child, .settings-container.s-tasks-questions #t-q-list li.question > div:first-child {
            width: 30px; }
            .settings-container.s-tasks-questions #t-q-list li.task > div:first-child i, .settings-container.s-tasks-questions #t-q-list li.question > div:first-child i {
              color: #146aff; }
              .settings-container.s-tasks-questions #t-q-list li.task > div:first-child i.fa-file, .settings-container.s-tasks-questions #t-q-list li.question > div:first-child i.fa-file {
                color: #00a9a7; }
          .settings-container.s-tasks-questions #t-q-list li.task > div:nth-child(2) p, .settings-container.s-tasks-questions #t-q-list li.question > div:nth-child(2) p {
            margin: 0 0 12px 0;
            font-weight: 700;
            color: #9b9b9b; }
            .settings-container.s-tasks-questions #t-q-list li.task > div:nth-child(2) p:first-child, .settings-container.s-tasks-questions #t-q-list li.question > div:nth-child(2) p:first-child {
              color: #333; }
          .settings-container.s-tasks-questions #t-q-list li.task > div:nth-child(3), .settings-container.s-tasks-questions #t-q-list li.question > div:nth-child(3) {
            margin-left: 48px;
            max-width: 430px; }
            .settings-container.s-tasks-questions #t-q-list li.task > div:nth-child(3) p, .settings-container.s-tasks-questions #t-q-list li.question > div:nth-child(3) p {
              margin: 0 0 12px 0;
              color: #9b9b9b; }
              .settings-container.s-tasks-questions #t-q-list li.task > div:nth-child(3) p:first-child, .settings-container.s-tasks-questions #t-q-list li.question > div:nth-child(3) p:first-child {
                color: #333;
                margin-bottom: 0; }
              .settings-container.s-tasks-questions #t-q-list li.task > div:nth-child(3) p:nth-child(2), .settings-container.s-tasks-questions #t-q-list li.question > div:nth-child(3) p:nth-child(2) {
                font-size: 0.75rem; }
          .settings-container.s-tasks-questions #t-q-list li.task aside, .settings-container.s-tasks-questions #t-q-list li.question aside {
            display: none; }
          .settings-container.s-tasks-questions #t-q-list li.task:hover aside, .settings-container.s-tasks-questions #t-q-list li.question:hover aside {
            position: absolute;
            left: -41px;
            top: -1px;
            background-color: #FFF;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
            display: flex;
            max-width: 40px;
            flex-wrap: wrap; }
            .settings-container.s-tasks-questions #t-q-list li.task:hover aside span, .settings-container.s-tasks-questions #t-q-list li.question:hover aside span {
              min-width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #dddddd; }
              .settings-container.s-tasks-questions #t-q-list li.task:hover aside span:nth-child(2), .settings-container.s-tasks-questions #t-q-list li.question:hover aside span:nth-child(2) {
                border-top: none;
                border-bottom: none; }
              .settings-container.s-tasks-questions #t-q-list li.task:hover aside span svg, .settings-container.s-tasks-questions #t-q-list li.question:hover aside span svg {
                color: #9b9b9b;
                pointer-events: none; }
              .settings-container.s-tasks-questions #t-q-list li.task:hover aside span:hover svg, .settings-container.s-tasks-questions #t-q-list li.question:hover aside span:hover svg {
                color: #00a9a7; }
    .settings-container.s-tasks-questions aside#add-new {
      width: 100%;
      max-width: 600px;
      display: flex;
      justify-content: flex-end;
      margin-top: 16px; }
      .settings-container.s-tasks-questions aside#add-new span {
        margin-left: 32px;
        box-sizing: border-box;
        border-bottom: 1px solid #FFF;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 30px; }
        .settings-container.s-tasks-questions aside#add-new span:first-child {
          color: #00a9a7; }
          .settings-container.s-tasks-questions aside#add-new span:first-child:hover, .settings-container.s-tasks-questions aside#add-new span:first-child:focus {
            background-color: #00a9a7;
            color: #FFF; }
        .settings-container.s-tasks-questions aside#add-new span:nth-child(2) {
          color: #146aff;
          position: relative; }
          .settings-container.s-tasks-questions aside#add-new span:nth-child(2):hover, .settings-container.s-tasks-questions aside#add-new span:nth-child(2):focus {
            background-color: #146aff;
            color: #FFF; }
          .settings-container.s-tasks-questions aside#add-new span:nth-child(2) ul.question-types {
            margin: 0;
            padding: 0;
            list-style: none;
            position: absolute;
            width: 528px;
            display: flex;
            flex-wrap: wrap;
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            background-color: #FFF;
            bottom: 38px;
            right: 0;
            z-index: 1; }
            .settings-container.s-tasks-questions aside#add-new span:nth-child(2) ul.question-types li {
              width: 50%;
              padding: 8px 0px 8px 16px;
              color: #333;
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .settings-container.s-tasks-questions aside#add-new span:nth-child(2) ul.question-types li p {
                width: 90%;
                margin: 0;
                pointer-events: none; }
              .settings-container.s-tasks-questions aside#add-new span:nth-child(2) ul.question-types li svg, .settings-container.s-tasks-questions aside#add-new span:nth-child(2) ul.question-types li img {
                margin-right: 8px;
                pointer-events: none; }
              .settings-container.s-tasks-questions aside#add-new span:nth-child(2) ul.question-types li:hover {
                background-color: #f3f5f5;
                cursor: pointer; }
        .settings-container.s-tasks-questions aside#add-new span svg {
          margin-right: 5px; }
  .settings-container .format-settings {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap; }
    .settings-container .format-settings .ck-editor {
      width: 100%;
      margin-top: 10px; }
    .settings-container .format-settings .ck-editor__editable {
      min-height: 150px;
      max-height: 350px; }
      .settings-container .format-settings .ck-editor__editable h1 {
        font-size: 1.25rem;
        line-height: "32px";
        font-weight: 700;
        color: #333; }
      .settings-container .format-settings .ck-editor__editable h2 {
        font-size: 1.125rem;
        line-height: "28px";
        font-weight: 700;
        color: #333; }
      .settings-container .format-settings .ck-editor__editable h3 {
        font-size: 1rem;
        line-height: "25px";
        font-weight: 700;
        color: #333; }
      .settings-container .format-settings .ck-editor__editable h4 {
        font-size: 0.875rem;
        line-height: "22px";
        font-weight: 700;
        color: #333; }
    .settings-container .format-settings .form-group, .settings-container .format-settings .ip-ranges {
      width: 600px !important; }
    .settings-container .format-settings #ip-range-label {
      margin-bottom: 4px; }
    .settings-container .format-settings .ip-ranges {
      flex-wrap: wrap; }
      .settings-container .format-settings .ip-ranges ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 600px; }
        .settings-container .format-settings .ip-ranges ul li {
          display: flex;
          align-items: center; }
          .settings-container .format-settings .ip-ranges ul li .form-group {
            width: 170px !important;
            margin: 0; }
          .settings-container .format-settings .ip-ranges ul li svg {
            color: #00a9a7; }
            .settings-container .format-settings .ip-ranges ul li svg:hover, .settings-container .format-settings .ip-ranges ul li svg:active {
              color: #333;
              cursor: pointer; }
      .settings-container .format-settings .ip-ranges button {
        margin-top: 8px; }
    .settings-container .format-settings .full-width {
      width: 100%;
      margin: 20px 0  0 0; }
    .settings-container .format-settings pre {
      font-size: 0.75rem;
      width: 600px; }
  .settings-container.study-launch #launch-msg, .settings-container.study-launch #pop-up-js-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 640px;
    background-color: #f7f7f7;
    border: 1px solid #dddddd;
    padding: 24px 120px;
    margin-bottom: 24px; }
    .settings-container.study-launch #launch-msg.expand-padding, .settings-container.study-launch #pop-up-js-msg.expand-padding {
      padding: 10px 20px; }
    .settings-container.study-launch #launch-msg img, .settings-container.study-launch #pop-up-js-msg img {
      margin: 24px 0 0 0; }
    .settings-container.study-launch #launch-msg h4, .settings-container.study-launch #pop-up-js-msg h4 {
      margin-bottom: 0;
      font-weight: 700; }
  .settings-container.study-launch #launch-button {
    width: 600px;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 140px; }
    .settings-container.study-launch #launch-button img {
      margin-bottom: 16px; }

.image-buttons > div.selected-1 > span:nth-child(2) {
  border-left: none; }

.image-buttons > div.selected-2 > span:nth-child(1) {
  border-right: none; }

.image-buttons > div.selected-3 > span:nth-child(1) {
  border-right: none; }

.image-buttons > div span.selected-img-bt {
  background-color: #f5fffd;
  border: 1px solid #00a9a7 !important; }

.image-buttons > div > span {
  width: 300px;
  min-height: 160px;
  padding: 32px 0 24px 0;
  border: 1px solid #dddddd;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  cursor: pointer; }
  .image-buttons > div > span:hover {
    background-color: #f5fffd; }
  .image-buttons > div > span:nth-child(2), .image-buttons > div > span:nth-child(3) {
    border-left: none; }
  .image-buttons > div > span span {
    height: 62px; }
  .image-buttons > div > span p {
    width: 100%;
    text-align: center;
    margin: 0px; }
    .image-buttons > div > span p:nth-child(3) {
      color: #9b9b9b;
      margin: 16px 16px 0 16px; }
  .image-buttons > div > span p, .image-buttons > div > span img, .image-buttons > div > span span {
    pointer-events: none; }

.image-buttons > div.sml > span {
  width: 200px;
  min-height: 140px; }

.image-buttons > div.med > span {
  width: 233px;
  min-height: 140px; }

.image-buttons > div.img-file-upload {
  width: 100%;
  max-width: 418px;
  border: dashed 1px #dddddd;
  background-color: #FFF;
  display: flex;
  justify-content: center; }
  .image-buttons > div.img-file-upload span {
    border: none;
    background: none; }
  .image-buttons > div.img-file-upload button {
    margin-top: 10px; }

#sidebar-editor {
  height: auto;
  min-height: 0vh;
  width: 40%;
  max-width: 0%;
  -webkit-transition: max-width 0.5s, min-height 0.5s;
  transition: max-width 0.5s, min-height 0.5s;
  border: 1px solid #dddddd;
  position: relative; }
  #sidebar-editor.open {
    max-width: 40%;
    min-height: 100vh; }
  #sidebar-editor .sidebar-head {
    height: 56px;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    padding: 0 24px;
    font-size: 1.25rem;
    line-height: "";
    font-weight: 400;
    color: #333; }
    #sidebar-editor .sidebar-head #sidebar-title {
      width: 62%; }
    #sidebar-editor .sidebar-head .head-icons svg {
      font-size: 1.125rem; }
      #sidebar-editor .sidebar-head .head-icons svg:hover {
        color: #00a9a7;
        cursor: pointer; }
    #sidebar-editor .sidebar-head #sidebar-close:hover {
      color: #00a9a7;
      cursor: pointer; }
  #sidebar-editor .sidebar-body {
    padding: 24px 24px 70px 24px;
    overflow-y: auto;
    height: calc(100vh - 58px); }
    #sidebar-editor .sidebar-body .inpt-lrg {
      width: 100%; }
    #sidebar-editor .sidebar-body #question-type-string {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 4px; }
      #sidebar-editor .sidebar-body #question-type-string span {
        margin-left: 8px; }
    #sidebar-editor .sidebar-body .ck-editor {
      width: 100%;
      margin-top: 10px; }
    #sidebar-editor .sidebar-body .ck-editor__editable {
      min-height: 200px;
      max-height: 400px; }
      #sidebar-editor .sidebar-body .ck-editor__editable h1 {
        font-size: 1.25rem;
        line-height: "32px";
        font-weight: 700;
        color: #333; }
      #sidebar-editor .sidebar-body .ck-editor__editable h2 {
        font-size: 1.125rem;
        line-height: "28px";
        font-weight: 700;
        color: #333; }
      #sidebar-editor .sidebar-body .ck-editor__editable h3 {
        font-size: 1rem;
        line-height: "25px";
        font-weight: 700;
        color: #333; }
      #sidebar-editor .sidebar-body .ck-editor__editable h4 {
        font-size: 0.875rem;
        line-height: "22px";
        font-weight: 700;
        color: #333; }
    #sidebar-editor .sidebar-body .question-field-container {
      width: 100%; }
      #sidebar-editor .sidebar-body .question-field-container button {
        margin: 8px 0; }
    #sidebar-editor .sidebar-body .answer-lines {
      margin: 0;
      padding: 0;
      list-style: none; }
      #sidebar-editor .sidebar-body .answer-lines li {
        display: flex; }
        #sidebar-editor .sidebar-body .answer-lines li .inpt-lrg {
          width: 340px; }
        #sidebar-editor .sidebar-body .answer-lines li .form-group {
          margin: 0; }
      #sidebar-editor .sidebar-body .answer-lines.draggable li .inpt-lrg {
        width: 315px; }
      #sidebar-editor .sidebar-body .answer-lines .drag-handle {
        display: flex;
        justify-content: space-around;
        width: 20px;
        margin-right: 8px;
        cursor: -webkit-grabbing;
        cursor: grabbing; }
        #sidebar-editor .sidebar-body .answer-lines .drag-handle svg {
          font-size: 2.5rem;
          color: #dddddd !important; }
      #sidebar-editor .sidebar-body .answer-lines:not(.inherit-styles) li {
        align-items: center; }
        #sidebar-editor .sidebar-body .answer-lines:not(.inherit-styles) li:first-child {
          margin-top: -12px; }
      #sidebar-editor .sidebar-body .answer-lines:not(.inherit-styles) svg {
        color: #00a9a7; }
        #sidebar-editor .sidebar-body .answer-lines:not(.inherit-styles) svg:hover, #sidebar-editor .sidebar-body .answer-lines:not(.inherit-styles) svg:active {
          color: #333;
          cursor: pointer; }
      #sidebar-editor .sidebar-body .answer-lines.inherit-styles li:last-child input {
        margin: -10px 0 24px 0; }
  #sidebar-editor #sidebar-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    padding: 16px;
    border-top: 1px solid #e6e6e6;
    z-index: 16; }

#preview-carousel {
  width: 100%;
  height: 400px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  #preview-carousel .carousel-nav {
    position: absolute;
    height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    cursor: pointer; }
    #preview-carousel .carousel-nav svg {
      margin: 0 4px;
      color: rgba(51, 51, 51, 0.5); }
      #preview-carousel .carousel-nav svg:hover, #preview-carousel .carousel-nav svg.VISIBLE {
        color: #00a9a7; }
  #preview-carousel section.first {
    width: 80%; }
    #preview-carousel section.first .preview-modal {
      min-height: 340px !important;
      max-height: 340px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
      padding-top: 0; }
      #preview-carousel section.first .preview-modal .logo-exit-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        min-height: 40px; }
        #preview-carousel section.first .preview-modal .logo-exit-container span {
          font-size: 0.75rem;
          padding: 0 16px; }
        #preview-carousel section.first .preview-modal .logo-exit-container img {
          padding: 0 16px;
          margin: 8px 0; }
      #preview-carousel section.first .preview-modal h2 {
        font-size: 0.875rem;
        font-weight: 700; }
      #preview-carousel section.first .preview-modal .preview-warning {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin: 0 20%;
        height: 30px;
        bottom: 66px;
        border-radius: 20px;
        font-size: 0.75rem; }
        #preview-carousel section.first .preview-modal .preview-warning span:first-child {
          color: #FFF;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px; }
        #preview-carousel section.first .preview-modal .preview-warning span:nth-child(2) {
          padding-right: 20px; }
      #preview-carousel section.first .preview-modal .preview-buttons span {
        height: 36px; }
  #preview-carousel section.second .show-bubble {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2); }

.preview-modal {
  padding: 24px 0;
  min-height: 400px;
  position: relative; }
  .preview-modal H1, .preview-modal h2, .preview-modal h3, .preview-modal h4, .preview-modal h5, .preview-modal p, .preview-modal ul, .preview-modal ol {
    margin: 0 24px; }
  .preview-modal .question-container, .preview-modal .task-container {
    margin-bottom: 20px; }
    .preview-modal .question-container H1, .preview-modal .question-container h2, .preview-modal .question-container h3, .preview-modal .question-container h4, .preview-modal .question-container h5, .preview-modal .question-container p, .preview-modal .question-container ul, .preview-modal .question-container ol, .preview-modal .task-container H1, .preview-modal .task-container h2, .preview-modal .task-container h3, .preview-modal .task-container h4, .preview-modal .task-container h5, .preview-modal .task-container p, .preview-modal .task-container ul, .preview-modal .task-container ol {
      margin-top: 8px;
      margin-bottom: 8px; }
    .preview-modal .question-container H1, .preview-modal .question-container h2, .preview-modal .question-container h3, .preview-modal .question-container h4, .preview-modal .question-container h5, .preview-modal .task-container H1, .preview-modal .task-container h2, .preview-modal .task-container h3, .preview-modal .task-container h4, .preview-modal .task-container h5 {
      line-height: 1.5 !important;
      padding: 0 !important;
      max-width: 352px !important; }
  .preview-modal .preview-lists {
    margin: 12px 24px 0 24px;
    padding: 0;
    list-style: none;
    max-height: 400px;
    overflow: scroll;
    padding-bottom: 55px; }
    .preview-modal .preview-lists li {
      line-height: 1.85rem; }
      .preview-modal .preview-lists li svg {
        margin-right: 10px; }
      .preview-modal .preview-lists li p {
        margin: 0 0 5px 0; }
    .preview-modal .preview-lists .matrix-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between; }
      .preview-modal .preview-lists .matrix-list li {
        max-width: 20%;
        text-align: center;
        line-height: 1.3rem;
        margin: 5px 0 15px 0; }
        .preview-modal .preview-lists .matrix-list li svg {
          margin-right: 0px; }
    .preview-modal .preview-lists select {
      width: 100%;
      height: 30px;
      padding: 5px 8px;
      border-radius: 0;
      background: none;
      font-size: 0.875rem;
      box-sizing: border-box;
      margin-bottom: 15px; }
  .preview-modal .preview-input-text, .preview-modal .preview-textarea {
    margin: 12px 24px;
    width: 352px;
    font-size: 0.875rem;
    border: 1px solid #dddddd;
    padding: 3px 5px; }
  .preview-modal .grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    margin-bottom: 20px;
    text-align: right; }
    .preview-modal .grid:first-child div {
      padding-right: 14px; }
    .preview-modal .grid:not(:first-child) div {
      text-align: left; }
    .preview-modal .grid div {
      line-height: 1rem; }
  .preview-modal .preview-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    position: absolute;
    bottom: 24px;
    background-color: #FFF; }
    .preview-modal .preview-buttons span {
      width: 49%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      background-color: #e2e2e2;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.criteria-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .criteria-list > li {
    border: 1px solid #00a9a7;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap; }
    .criteria-list > li .accordion-control {
      width: 100%;
      max-height: 0px;
      -webkit-transition: max-height 0.5s, box-shadow 0.5s;
      transition: max-height 0.5s, box-shadow 0.5s;
      overflow-y: hidden;
      overflow-x: hidden; }
      .criteria-list > li .accordion-control.remove-overflow {
        overflow: visible; }
    .criteria-list > li .type-container {
      width: 100%;
      background-color: #f5fffd;
      cursor: pointer; }
      .criteria-list > li .type-container > .form-group {
        width: 568px !important;
        margin: 16px 16px 16px 15px; }
        .criteria-list > li .type-container > .form-group .inpt-xlrg {
          width: 568px; }
        .criteria-list > li .type-container > .form-group .scroll-wrap {
          width: 568px; }
    .criteria-list > li.active .accordion-control {
      min-height: 100px;
      max-height: 1000px; }
    .criteria-list > li.active .type-container {
      border-bottom: 1px solid #00a9a7;
      cursor: auto; }
    .criteria-list > li .options-container {
      width: 100%;
      padding: 24px 16px 16px 16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .criteria-list > li .options-container .cb-wrap {
        margin-right: 24px;
        margin-bottom: 0px; }
      .criteria-list > li .options-container .range-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%; }
        .criteria-list > li .options-container .range-wrapper .form-group {
          max-width: 100px;
          margin-top: -8px;
          margin-bottom: 0; }
          .criteria-list > li .options-container .range-wrapper .form-group:nth-child(2) {
            margin-left: 20px; }
          .criteria-list > li .options-container .range-wrapper .form-group .inpt-xsml {
            width: 80px; }
      .criteria-list > li .options-container .currency-option .form-group:first-child {
        width: 180px !important;
        margin-top: -8px; }
        .criteria-list > li .options-container .currency-option .form-group:first-child .scroll-wrap {
          width: 180px; }
      .criteria-list > li .options-container .currency-option .range-wrapper .form-group {
        max-width: 140px; }
        .criteria-list > li .options-container .currency-option .range-wrapper .form-group:first-child {
          margin-right: 20px; }
        .criteria-list > li .options-container .currency-option .range-wrapper .form-group:nth-child(2) {
          margin-left: 20px; }
      .criteria-list > li .options-container .select-rows {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .criteria-list > li .options-container .select-rows li {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap; }
          .criteria-list > li .options-container .select-rows li > .form-group {
            width: 520px !important;
            margin: 0px 16px 16px 0px; }
            .criteria-list > li .options-container .select-rows li > .form-group .inpt-xlrg {
              width: 520px; }
            .criteria-list > li .options-container .select-rows li > .form-group .scroll-wrap {
              width: 520px; }
          .criteria-list > li .options-container .select-rows li svg {
            cursor: pointer;
            color: #00a9a7; }
            .criteria-list > li .options-container .select-rows li svg:hover {
              color: #333; }
          .criteria-list > li .options-container .select-rows li .sub-option-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            min-height: 61px; }
            .criteria-list > li .options-container .select-rows li .sub-option-container > ul {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              max-width: 480px;
              margin: 0;
              padding: 0;
              list-style: none; }
              .criteria-list > li .options-container .select-rows li .sub-option-container > ul .form-group {
                width: 400px !important;
                margin: 0px 16px 16px 0px; }
                .criteria-list > li .options-container .select-rows li .sub-option-container > ul .form-group .inpt-lrg {
                  width: 400px; }
                .criteria-list > li .options-container .select-rows li .sub-option-container > ul .form-group .scroll-wrap {
                  width: 400px; }
              .criteria-list > li .options-container .select-rows li .sub-option-container > ul svg {
                margin: 0 2px; }
      .criteria-list > li .options-container .warning-msg {
        width: 600px;
        font-size: 0.75rem;
        color: #f63d39; }
  .criteria-list .criteria-controls {
    width: 100%;
    padding: 16px;
    border-top: 1px solid #dddddd;
    display: flex;
    justify-content: flex-start; }
    .criteria-list .criteria-controls .delete-bt {
      margin-left: auto;
      color: #f63d39; }

.stripe-form-container {
  width: 640px !important;
  flex-wrap: wrap;
  justify-content: flex-start !important; }
  .stripe-form-container .checkout {
    width: 100%;
    background-color: #f7f7f7;
    padding: 10px 20px; }
    .stripe-form-container .checkout button {
      margin: 16px 0; }
    .stripe-form-container .checkout .tableContainer {
      background: none !important;
      box-shadow: none;
      padding-bottom: 0; }
      .stripe-form-container .checkout .tableContainer table {
        border: none; }
        .stripe-form-container .checkout .tableContainer table tr {
          border: none; }
          .stripe-form-container .checkout .tableContainer table tr:last-child {
            font-weight: 700; }
          .stripe-form-container .checkout .tableContainer table tr td {
            padding: 8px 0; }

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }


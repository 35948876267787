.pie-chart {
  width: 365px;
  background-color: $white;
  min-width: 365px;
  min-height: 245px;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:hover {
    background-color: $dropdownSelectBd;
    cursor: pointer;
  }

  h5 {
    font-size: $fs16px;
    font-weight: 400;
    width: 100%;
    margin-top: 0;
    margin-bottom: 24px
  }

  canvas {
    max-width:  170px;
  }

  .chart-legend {
    li {
      min-width: auto;
      margin-right: 20px;
      margin-top: 24px;
      font-size: $fs12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.gauge-chart {
  width: 368px;
  min-width: 368px;
  min-height: 150px;
  padding: 24px 60px 0 60px;
  position: relative;

  h2, h5 {
    position: absolute;
    bottom: 0;
    width:245px;
    text-align: center;
  }

  h5 {
    @include text($fs14px,'',400);
  }

  h2 {
    bottom:24px;
    @include text($fs40px,'',700);
  }

  span {
    position: absolute;
    bottom: 56px;

    &.low-range {
      left: 10px;
      text-align: right;
      width:38px;
    }
    &.high-range {
      right: 24px;
    }
  }

  canvas {
    max-width:  245px;

  }
}

.bar-chart {
  // width: auto;
  background: none;
  box-shadow: none;

  table {
    min-width: 100%;
    width: auto;

    td {
      border-top: 1px solid $mainLine;

      &:first-child {
        font-weight: 700;
        width:20%;
      }

      &.span-container {
        width:70% !important;

        span {
          display: block;
          height: 32px;
          background-color: $mainGreen;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &:last-child {
        width:10%;
        text-align: right;
      }
    } // end td

    &.SUS {
      display: none;
      .span-container {

        span {
          display: inline-block;

          &:not(:last-child) {
            border-radius: 0;
          }
        } // end span

        .tooltip-trigger {
          height: 100%;
          width: 100%;
        }

        .tooltip-message {
          table {
            min-width: 100%;
            max-width: 100%;
            width: auto;
            font-size: $fs12px;
            td,th {
              padding:8px 0;
            }
          }
        } // end .tooltip-message
      } // end .span-container

      tr td:first-child {
        padding-right: 8px;
      }

    }

    &.RATING {
      td {
        &.top-align {
        vertical-align: top;
        font-size: $fs20px;
        }

        &:nth-child(2) {
          width:15% !important;
        }

        &:last-child {
          width:10% !important;
        }

        &:nth-child(3) {
          width:55%;
        }
      }
    }
  } // end table
} // end .bar-chart

a.view-all {
  @include text($fs14px,'',700, $mainText);
  display: inline-block;
  width:100%;
  text-align: right;
  padding-top: 4px;

  svg {
    margin-left: 3px;
  }
} //  end a.view-all

.bar-chart-vertical {
  width: auto;
  min-width: 100%;
  background: none;
  box-shadow: none;

  .chart-bg-lines, .chart-columns {
    width: 100%;
    max-width: 100%;
    height: 100%;
    @include ulStyleStrip(0,0,none);
  }

  .chart-bg-lines {
    li {
      width: 100%;
      height: 62px;
      display: flex;
      justify-content: space-between;

      span:first-child {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: flex-end;
      }

      span:last-child {
        width: 90%;
        border-bottom: 1px solid $mainLine;
        height: 100%;
      }

      &:first-child {
        border-top: 1px solid $mainLine;
      }
    }
  } // end .chart-bg-lines

  .chart-columns {
    display: flex;
    justify-content: space-around;
    padding-left: 10%;
    margin-bottom: 30px;

    &.NPS {}

    li {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;

      &.w-eighty {
        width:80px;
      }

      &.w-forty {
        width:40px;
      }

      span {
        &:not(.tooltip) {
          width: 100%;
          height: 40px;
          max-height: 81%;
          position: relative;
        }

        &.tooltip {
          .tooltip-trigger {
            width: 100%;
            height: 100%;
            max-height: 100%;
            border-bottom: none;
            position: absolute;
            left: 0;

            span {
              height: 100% !important;
              max-height: 100%;
              width: 100%;
              position: absolute;
              left: 0;
              display: inline-block;
              background: none !important;
            }
          }
        } // end &.tooltip

        &:not(.tooltip)::after, &.tooltip span span::after {
          content: attr(data-top);
          @include text($fs16px,'',700, $mainText);
          position: absolute;
          top: -24px;
          left:0;
          width: 100%;
        }

        &:not(.tooltip)::before, &.tooltip span span::before {
          content: attr(data-bottom);
          @include text($fs12px,'',400, $mainText);
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: -40px;
          left:0;
          width: 100%;
          height: 30px;
        }
      } // end span

    } // end li
  } // end .chart-columns
} // end .bar-chart-vertical

.chart-header {
  display: flex;
  flex-wrap:wrap;

  .left-column {
    width:60%;


    .toggle-container {
      // most of this style defined in _lists.scss
      width: 100%;
      min-width: 100%;
      margin-bottom: 24px;
    }
  } // .left-column

  .gauge-chart {
    padding-top: 0px;

    canvas {
      margin-top: -60px;
    }
  }
} // end .chart-header

.table-wrapper {
  width:100%;
  display: none;
} //  end .table-wrapper

.chart-legend {
  width: 100%;
  max-width: 100%;
  @include ulStyleStrip(0,0,none);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  opacity:0;
  transition: opacity 0.2s;

  &.show-legend {
    display: flex;
    opacity:1;
  }

  li {
    min-width: 150px;

    &:nth-child(3) {
      margin-right: auto;
    }

    b {
      text-transform: capitalize;
    }

    svg {
      margin-right: 5px;
    }

    span {
      border-top: 1px solid $mainLine;
      display: inline-block;
      width: 100%;
      margin-top: 12px;
      padding-top: 12px;
    }
  }
} // .chart-legend

#projects-list {

  width:100%;
  max-width: unset;
  padding: 0;
  margin: 0;
  list-style: none;

  &.dashboard {

    > li {
      display: flex;
      flex-wrap: wrap;
      min-height: 116px;
      padding: 20px 0;
      margin-top:16px;
      z-index: 4;
      background-color: $white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      justify-content: space-between;

      &.show-p {
        display: flex;
      }

      &.hide-p {
        display: none;
      }

      > div:first-child {
        margin-left:20px;
        .cb-wrap{
          padding-right: 16px;
        }
      }

      > div:nth-child(2) {

        width:450px;

        h4 {
          margin:4px 0 8px 0;
          width: 445px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > div:nth-child(3) {
        display: flex;
        align-items: center;
        width:540px;
        min-width: 540px;

        > ul {
          @include ulStyleStrip(0,0,none);
          width:100%;
          display: flex;
          justify-content: space-between;

            li {
              display: flex;
              flex-wrap: wrap;
              text-align: center;

              &:nth-child(-n+3){
                //min-width:94px;
              }

              &:last-child{

              }

              span {
                width: 100%;
              }

              span:first-child {
                font-size: $fs24px;
              }

              span:last-child {
                font-size: $fs12px;
              }
            }

        }
      }

      > div:nth-child(4) {
        display: flex;
        align-items: center;
        margin-right:20px;

          /* reposition the overflow component for vertical alignment with other buttons */
          .form-group {
            margin-top: 11px;
            margin-left: 10px;
          }
      }
    }

  }
}

/* Participant Tables Start */
table.participants {
  width:100%;
  margin-bottom: 0;

  th {
    color: $mainText;
    font-size: $fs14px;

    &:first-child {
      padding-left: 24px;
    }
  }

  tbody tr {
    background-color: $white;
    font-size: $fs14px;

    &:not(.tag-list-container):not(.spacer):hover {
      background-color: $playBtBg;

      .play-button {
        border: 1px solid $mainGreen;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  td {

    .participant-name {
      text-decoration: underline;
      font-weight: bold;
      color: $mainGreen;

      &:hover {
        text-decoration:none;
      }
    }

    .location {
      color: $text03;
    }
  }


  .spacer {
    background-color: transparent;
    border-bottom: none;
    -webkit-box-shadow: inset 0px 10px 10px -6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0px 10px 10px -6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 10px 10px -6px rgba(0, 0, 0, 0.1);
  }
}

td {
  .play-button {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 49px;
    height: 34px;
    border-radius: 2px;
    background-color: $playBtBg;
    margin: 10px 20px 10px 24px;

      svg, .fa-play {
        color:$mainGreen;
      }

      &:hover {
        background-color: $mainGreen;

        svg, .fa-play {
          color:$white;
        }
      }
  }
}

.accordion-wrapper .spacer {
  display: none;
}

.video-task-rows {

  thead tr {
    border-bottom: none;
  }

  .fa-arrow-to-bottom {
    margin-right: 5px;
  }

  tbody tr {

    a.media-download {
      display: inline-block;
      margin-right: 10px;
      text-decoration: underline;

      &::first-letter {
        text-transform: capitalize;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .dot {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 8px;
      margin-right: 5px;
      margin-bottom: -2px;

      + span {
        text-transform: lowercase;
        display: inline-block;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      &.SUCCESS {
        background-color: $mainGreen;
      }

      &.FAIL {
        background-color: $graph02;
      }

      &.ABANDON {
        background-color: $graph03;
      }
    }

  }

}

.tagManagement {
  margin:0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    background-color: $white;
    margin-bottom: 15px;
    @include text(1rem, 64px, 700, $color: $mainText);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    > span:first-child {
      margin-left: 24px;
      display: flex;
      align-items: center;

      label {
        display: none;
      }

      svg {
        margin-left: 8px;
      }
    }

    > span:last-child {
      margin-right: 24px;
    }

    svg{
      color:$iconGrey;
      &:hover {
        cursor: pointer;
        color: $mainGreen;
      }
    }
  }
}

.faqList {
  margin:0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;

  li {
    background-color: $white;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      height: 90px;
      color: $mainText;

      &:hover {
        border-left: 2px solid $mainGreen;
        text-decoration: none;
      }

      img {
        margin: 0 24px;
      }

      span {
        h4 {
          margin: 0 0 5px 0;
          padding: 0;
          line-height: 1rem;
          font-weight: 700;
        }
        span {
          @include text($fs14px, '', 400, $color: $mainText);
        }
      }
    }

  }

}

.taskQuestionList {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width:100%;

  li {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width:100%;
    justify-content:flex-start;
    align-items: flex-start;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    transition:max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    height: auto;
    overflow: hidden;

    &.hide {
      display: none;
    }

    &.MULTICHOICE1, &.NPS, &.NETPROMOTERSCORE &.OPEN_ENDED_SINGLE {max-height: 90px;}
    &.MULTICHOICEMULTI, &.SUS, &.SYSTEMUSABILITYSCORE, &.RATINGSCALEMULTI, &.RATINGSCALE1, &.RANKING, &.OPENENDEDMULTIPLE, &.OPENENDEDCOMMENTS {max-height: 185px;}

    &.accordionReq {
      &.QUESTION {
          &::after {
          content: "";
          position: absolute;
          bottom: 22px;
          left: 0;
          width: 100%;
          height: 40px;
          opacity: 1;
          transition:opacity 1s ease-in-out;
          background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(62%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
          background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 100%);
          background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 100%);
          background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
        }
      }
      .acc-trigger {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: $white;
        border-top: 1px solid $mainLine;
      }
      &.open {
        max-height:400px;

        > div:last-child {
          width: 55% !important;
          max-height: inherit !important;
          overflow-y: scroll !important;
        }

        &::after {
          opacity: 0;
        }

        &.OPEN_ENDED_TEXTAREA {
          padding-bottom: 20px;
        }
      }
    }

    > div {
      padding: 24px 12px;
      width: 30%;

      &:first-child {
        padding: 24px;
        min-width:150px;
        width: 15%;
        @include text($fs14px, '', 700, $color: $mainText);
        svg {
          margin-right: 8px;
        }

        &.task-icon-SUCCESS {
          svg {
            color: $mainGreen;
          }
        }

        &.task-icon-FAIL {
          svg {
            color: $failR;
          }
        }

        &.task-icon-ABANDON {
          svg {
            color: $abandonR;
          }
        }

        img {
          max-height: 14px;
          max-width:14px;
          margin-right: 8px;
          margin-bottom: -2px;
        }
      }

      &.question {
        &:nth-child(3) {
          td {
            width: 60%;
          }

          td:last-child {
            width: 40%;
          }
        }
      }

      &:nth-child(3) {
          width: 55%;

          table {
            max-width: 100%;
            width: 100%;
            margin-bottom: 15px;

            tr:first-child {
              td {
                padding-top: 0;
              }
            }

            tr:last-child {
              border-bottom: none;
            }

            td {
              padding: 16px 0;
            }

            td:first-child {
              font-weight: 700;
            }

            td:last-child {
              padding-left: 16px;
            }
          }
      }
      // End of nth-child 3

    }

  }
}

section.pageCellWrapper {
  width: 100%;
  height: auto;
  max-height: 0;
  background-color: $accordionBg;

  padding: 0 24px 24px 24px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: none;
  transition:
    max-height 0.5s ease-in-out,
    padding 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;

  &.open {
    max-height:600px;

    padding-top: 24px;
    box-shadow:
            inset 0px 11px 10px -10px rgba(0, 0, 0, 0.2),
            inset 0px -10px 10px -10px rgba(0, 0, 0, 0.2);
  }

  h5 {
    font-size: $fs14px;
    margin: 0;
    width: 100%;
  }

  > div {

    &:nth-child(3) {
      width: 85%;
      position: relative;;

      .end-obscure {
        position: absolute;
        height: 78px;
        width: 100px;
        top:15px;
        right: 0;
        display: none;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+50 */
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
      }

      .boxShadowProxy{
        position: absolute;
        height: 58px;
        width: 10px;
        top:17px;
        box-shadow: -1px 0px 2px 0 rgba(0, 0, 0, 0.2);
        display: none;

        &.show {
          display: block;
        }
      }



      .pathList {
        display: none;
        max-width: 100%;
        margin-top: 16px;

        li {
          display: block;
          box-shadow: none;
          height: 60px;
          border-bottom: 1px solid $mainLine;

          &:last-child {
            border: none;
            height: 30px;

            &.SUCCESS {
              color: $mainGreen;
            }

            &.FAIL {
              color: $failR;
            }

            &.ABANDON {
              color: $abandonR;
            }
          }

          &.SUCCESS_URL {
            span {
              color: $mainGreen;
            }
          }
        }

        &.show {
          display: block !important;
        }
      }

    }
  }
  // div end
}// .pageCellWrapper end

.show-path {
  display: flex !important;
}

.toggle-success-paths {
  background-color: $white !important;
  max-height: 600px !important;
  padding: 24px !important;
  align-items: self-start;
  margin-bottom: 1%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;

  div.show-path {
    flex-wrap: wrap;
  }

  .spacer, .tag-list-container, .success-path, .fail-path {
    display: none;

    p {
      max-width: 100%;
    }
  }

  .toggle-container {
    span {
      margin-top:0;
    }
  }

  div {
    &:nth-child(3) {
      width: 100% !important;

      p {
        width:100%;
        button {
          margin-left:8px;
        }
      }
      ul {
        li {
          background-color: $white;
          position: relative;
        }
      }
    }
  }
  a.play-button {
    margin-left: 0;
  }
} // end .toggle-success-paths

.task {
  .acc-trigger {
    color: $text03;
    font-size: $fs12px;
    padding: 2px 0;

    &:hover {
      color: $mainGreen;
    }
  }
}

ul.project-type-and-dates {
  @include ulStyleStrip(0,0,none);
  display: flex;
  font-size: $fs12px;

  li {
    margin: 0 16px 0 0;
    text-transform: capitalize;

    svg {
      margin-right: 4px;

      &.moderated {
        color: $mainGreen;
      }

      &.unmoderated {
        color: $mainBlue;
      }
    }

    span {
      color: $text03;
      margin-left: 2px;
    }
  }
}

.toggle-container {
  width: 15%;
  display: flex;

  .toggle-container {

  }

  span {
    width: 40px;
    height: 40px;
    margin: 16px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $outlineBgActive;
    color: $white;
    cursor: pointer;

    &.selected, &:hover, &:focus {
      background-color: $mainGreen;

    }

    svg {pointer-events: none;}
  }
  // span end

  &.text-fill {

    margin-right: auto;
    margin-bottom: 24px;
    width:auto;

    span {
      width:auto;
      padding: 0 16px;
      margin-right: 0;
      background-color: $white;
      border: 1px solid $mainLine;
      @include text($fs14px, '', 400, $color: $mainText);

      &:first-child{
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child{
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }

      &:not(:first-child):not(:last-child) {
        border-left: 0;
        border-right: 0;
      }

      &.selected {
        background-color: $graph03;
        font-weight: 700;
      }

      &:hover {
        background-color: $graph03;
      }
    }
  }
} // end .toggle-container

.task-question-block {
  background-color: $white;
  max-width: 365px;
  box-shadow: $floatingLayer;
  margin-bottom:24px;
  // margin-right: 24px;

  ul.task-data {
    @include ulStyleStrip(0,0,none);
    width: 100%;
    border-top: 1px solid $mainLine;
    border-bottom: 1px solid $mainLine;
    padding: 0 16px;
    margin-bottom: 12px;

    li {
     display: flex;
     justify-content: space-between;
     padding: 8px 0;

     &:first-child{
       padding-bottom: 0;
     }

     span:first-child{
       font-weight: 700;
     }
    }
  } // end ul.task-data

  ul.question-data {
    @include ulStyleStrip(0,0,none);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;

    li {
      padding: 12px 16px;

      &:hover {
        background-color: $dropdownSelectBd;
        cursor: pointer;
      }

      p {
        margin:0;

        &:first-child {
          @include text($fs16px, '', 400);
          margin-bottom: 16px;
        }
        &:not(:first-child) {
          @include text($fs14px, '', 700);
        }
      }
    }
  } // end ul.question-data
} // end .task-question-block

.click-path-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $white;
  overflow-x: scroll;
  overflow-y: hidden;

  .canvas-container {
    padding-left: 24px;
    padding-top: 16px;
    height: 600px;

    canvas {
      margin-left: 180px;
    }
  }
  .page-cell-container {
    width: auto;
    min-width: 100%;
    height:600px;
    padding: 0 24px 24px 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: -600px;

    ul.pathAnalysis {
      display: block;
      height: auto;
      background: none;
      padding-left:100px;
      overflow-x: unset;

      &:first-child {
        padding-left: 24px;
      }

      li {
        margin-bottom: 48px;
        margin-right: 0;
        position: relative;

        span.tooltip {
          &:last-child{
            position:absolute;
            min-width: 150px;
            height:60px;
            top: 0;
            left:0;

            .tooltip-trigger{
              width: 100%;
              height: 100%;
              border-bottom: none;
            }

            .tooltip-bubble {
              &:after {
                border-bottom-color: $mainText !important;
              }
            }

            .tooltip-message {
              background-color: $mainText;
              color: $white;

              table {
                td {
                  padding:0;
                }
              }
            }
          }
        } // end span.tooltip
      }
    }

  } // end .page-cell-container
} // end .click-path-container


.pathAnalysis {
  margin: 0 0 15px 0;
  padding: 16px 0 0 0;
  list-style: none;
  justify-content: flex-start;
  background: $dropdownSelectBd; /* Old browsers */

  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 13%, #ffffff 15%, $dropdownSelectBd 16%, $dropdownSelectBd 69%, #ffffff 70%, #ffffff 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffffff), color-stop(13%, #ffffff), color-stop(15%, #ffffff), color-stop(16%, $dropdownSelectBd), color-stop(70%, $dropdownSelectBd), color-stop(69%, #ffffff), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 13%, #ffffff 15%, $dropdownSelectBd 16%, $dropdownSelectBd 69%, #ffffff 69%, #ffffff 100%);
  background: -o-linear-gradient(top, #ffffff 0%, #ffffff 13%, #ffffff 15%, $dropdownSelectBd 16%, $dropdownSelectBd 69%, #ffffff 70%, #ffffff 100%);
  background: -ms-linear-gradient(top, #ffffff 0%, #ffffff 13%, #ffffff 15%, $dropdownSelectBd 16%, $dropdownSelectBd 69%, #ffffff 70%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 13%, #ffffff 15%, $dropdownSelectBd 16%, $dropdownSelectBd 69%, #ffffff 70%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  display: none;
  max-width: 100%;
  width: auto;
  height: 110px;
  overflow-x: scroll;

  &.no-overflow {
    overflow-x: visible;

    li:nth-child(n+5) {
      opacity: 0;
    }
  }

  li {
    width: 180px;
    min-width: 180px;
    height: 60px;
    margin-right: 25px;
    margin-bottom: 0;
    overflow: visible;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: $fs12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 10px;
    border-radius: 2px;
    border-top: 1px solid $dropdownSelectBd;

    &.result {
      text-transform: capitalize;
      text-align: center;
      padding: 0;
      @include text($fs12px, '', 700, $color: $white);

      &.SUCCESS {
        background-color: $mainGreen;
      }

      &.ABANDON {
        background-color: $abandonR;
      }

      &.FAIL {
        background-color: $failR;
      }

      &.OTHER {
        color: $mainText;
      }

      section {
        width:100%;
        margin-top: 5px;
      }
    }

    &.SUCCESS_URL {
      border: 1px solid $mainGreen;
      &::before {
        content: 'Success URL';
        text-align: center;
        color: $white;
        width: 180px;
        height: 16px;
        background-color: $mainGreen;
        position: absolute;
        top:-16px;
        left: -1px;
      }
    }

    > section {
      margin-top: 10px;



        div.pathRow {
          width: 135px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          line-height: 1;
          margin-right: 10px;
        }

        svg {

          color: $placeholder;
          font-size: $fs14px;

          &:hover {
            color: $mainGreen;
            cursor: pointer;
          }
        }
    }
    // section end
  }
  // li end
}
// pathAnalysis end

.stripe-form-container {
  width:640px !important;
  flex-wrap: wrap;
  justify-content: flex-start !important;

  .checkout {
    width: 100%;
    background-color: $bodyBg;
    padding: 10px 20px;

    button{
      margin: 16px 0;
    }

    .tableContainer {
      background: none !important;
      box-shadow: none;
      padding-bottom: 0;

      table {
        border: none;
        tr {
          border: none;

          &:last-child {
            font-weight: 700;
          }

          td {
            padding: 8px 0;
          }
        }
      }
    }// end .tableContainer
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

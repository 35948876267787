#alert-container {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @keyframes alertOpacity {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @keyframes toastAnimation {
    from {margin-top: -10px;}
    to {margin-top: 0px;}
  }


  > div {
    min-height: 60px;
    padding: 15px 0;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    animation: alertOpacity  0.5s, toastAnimation  0.5s;

    &[class^='alert-']:not(.alert-BANNER) {
      width:calc(100% - 200px) !important;
    }

    div {
      max-width: 50%;
    }

    svg {
      margin-right: 5px;
    }

    span {

      &:first-child{
        margin-right: auto;
        visibility: hidden;
      }
      &:last-child{
        margin-left: auto;
      }

      width: 24px;
      height: 24px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {

        margin-right: 0;

        &:first-child {
          display: block;
        }

        &:last-child {
          display: none;
        }
      }

      &:hover {

        cursor: pointer;

        svg:first-child {
          display: none;
        }
        svg:last-child {
          display: block;
        }
      }
    }

  }


  .alert-BANNER {
    width:100%;
    background-color: $infoBorder;
    border-color: $infoBorder;
    color: $white;
    border-radius: unset;

    span {
      display: none;
    }
  }

  .alert-INFO {
    background-color: $infoBg;
    border-color: $infoBorder;
    color: $infoBorder;
  }

  .alert-SUCCESS {
    background-color: $successBg;
    border-color: $successBorder;
    color: $successBorder;
  }

  .alert-ERROR {
    background-color: $negativeBg;
    border-color: $negativeBorder;
    color: $negativeBorder;
  }

  .alert-WARNING {
    background-color: $warningBg;
    border-color: $warningBorder;
    color: $warningBorder;
  }
}
